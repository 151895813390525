import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../../styles/klinik.css";
import { css } from "@emotion/react";
import { ScaleLoader, MoonLoader } from "react-spinners";

import RoundedSearch from "../../components/RoundedSearch";

import IcPanah from "../../asset/svg/panah";
import { useDispatch, useSelector } from "react-redux";
import {
  getClinicByName,
  uploadDocument,
  deleteDocument,
  updateClinicService,
} from "../../store/actions";
import formatDate from "../../helpers/formatDate";
import IcEye from "../../asset/svg/eye";
import IcTrash from "../../asset/svg/trash";
import ModalConfirmation from "../../components/ModalConfirmation";
import ModalPDFViewer from "../../components/ModalPDFViewer";

import { FiRefreshCcw } from "react-icons/fi";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: lime;
`;

function DetailKlinik() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const { klinikName } = useParams();
  const { klinikByName } = useSelector((state) => state.clinicState);
  const { loading } = useSelector(
    (state) => state.messageState
  );

  const [klinikId, setKlinikId] = useState(null);
  const [rawData, setRawData] = useState({})
  const [dataKlinik, setDataKlinik] = useState({});
  const [selectedTab, setSelectedTab] = useState("Dokter");

  const [deleteKey, setDeleteKey] = useState("");
  const [modalDelete, setModalDelete] = useState(false);
  const [modalPdf, setModalPdf] = useState(false);
  const [urlPdf, setUrlPdf] = useState("");
  const [titlePdf, setTitlePdf] = useState("");
  const [expandedId, setExpandedId] = useState([])

  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    if (state) {
      setKlinikId(state.dataKlinik._id);
      setRawData(state.dataKlinik)
      setDataKlinik(state.dataKlinik);
    } else if (klinikName) {
      dispatch(getClinicByName(klinikName));
    } else {
      navigate("/klinik");
    }
    // eslint-disable-next-line
  }, [klinikId]);

  useEffect(() => {
    if (klinikByName) {
      setKlinikId(klinikByName._id);
      setRawData(klinikByName)
      setDataKlinik(klinikByName);
    }
    if (klinikByName === null) {
      navigate("/klinik");
    }
    // eslint-disable-next-line
  }, [klinikByName]);

  useEffect(() => {
    if (searchName !== "") {
      if(selectedTab === "Dokter"){
        let regexp = new RegExp(searchName, "gi");
        let newDoctor = []
        rawData.doctors.forEach((el) => {
          if(el.name.match(regexp) !== null){
            newDoctor.push(el)
          }
        });
        setDataKlinik({
          ...dataKlinik,
          doctors: newDoctor
        })
      }
      if(selectedTab === "Fasilitas"){
        let regexp = new RegExp(searchName, "gi");
        let newServices = []
        rawData.services.forEach((el) => {
          if(el.name.match(regexp) !== null){
            newServices.push(el)
          }
        });
        setDataKlinik({
          ...dataKlinik,
          services: newServices
        })
      }
      if(selectedTab === "Lampiran"){
        let regexp = new RegExp(searchName, "gi");
        let newLampiran = []
        rawData.documents.forEach((el) => {
          if(el.name.match(regexp) !== null){
            newLampiran.push(el)
          }
        });
        setDataKlinik({
          ...dataKlinik,
          documents: newLampiran
        })
      }
    } else {
      setDataKlinik(rawData)
    }
    // eslint-disable-next-line
  }, [searchName]);

  useEffect(() => {
    setSearchName("")
    // eslint-disable-next-line
  },[selectedTab])

  function handleUpload(e) {
    const file = e.target.files[0];
    let newObject = new File(
      [file.slice(0, file.size, "application/pdf")],
      `${file.name.slice(0, -4)}${new Date().toISOString()}.pdf`,
      { type: "pdf" }
    );

    const formData = new FormData();
    formData.append("document", newObject);
    formData.append("clinicId", klinikId);

    dispatch(uploadDocument(formData, dataKlinik.name));
  }

  const deleteHandle = () => {
    dispatch(deleteDocument({ key: deleteKey, clinicId: klinikId }));
  };

  const checkExpanded = (id) => {
    if(expandedId.includes(id)){
      const index = expandedId.indexOf(id)
      const newData = expandedId
      newData.splice(index, index + 1)
      setExpandedId(newData)
    } else {
      setExpandedId([...expandedId, id])
    }
  }

  return (
    <div className="bgcontent">
      <div className="klinik">
        <div className="headerLink">
          <Link to={"/klinik"} className="link">
            Daftar Klinik
          </Link>
          <IcPanah />
          <div className="currentLink">Detail Klinik</div>
        </div>
        {dataKlinik && (
          <>
            <div className="headerDetail">
              <div className="row">
                <img
                  src={
                    dataKlinik.imageUrl
                      ? dataKlinik.imageUrl
                      : require("../../asset/png/klinik.png")
                  }
                  height={100}
                  width={110}
                  alt="klinik"
                />
                <div className="name">
                  <div className="nameklinik">{dataKlinik.name}</div>
                  <div className="address">{dataKlinik.address}</div>
                </div>
              </div>
              {/* <div className="detail">Lihat map</div> */}
            </div>
            <div className="bodyDetail">
              <div className="row">
                <RoundedSearch
                  placeholder={"Pencarian"}
                  searchName={searchName}
                  setSearchName={setSearchName}
                />
                <div
                  onClick={() => setSelectedTab("Dokter")}
                  className="pageTitle"
                  style={{
                    marginLeft: "20px",
                    color: selectedTab === "Dokter" ? "#1565D8" : "#555555",
                    borderBottom: selectedTab === "Dokter" ? "solid" : "none",
                    borderBottomWidth:
                      selectedTab === "Dokter" ? "0.8px" : "0px",
                    borderBottomColor:
                      selectedTab === "Dokter" ? "#1565D8" : "#fff",
                    fontWeight: selectedTab === "Dokter" ? "500" : "300",
                  }}
                >
                  Dokter
                </div>
                <div
                  onClick={() => setSelectedTab("Fasilitas")}
                  className="pageTitle"
                  style={{
                    color: selectedTab === "Fasilitas" ? "#1565D8" : "#555555",
                    borderBottom:
                      selectedTab === "Fasilitas" ? "solid" : "none",
                    borderBottomWidth:
                      selectedTab === "Fasilitas" ? "0.8px" : "0px",
                    borderBottomColor:
                      selectedTab === "Fasilitas" ? "#1565D8" : "#fff",
                    fontWeight: selectedTab === "Fasilitas" ? "500" : "300",
                  }}
                >
                  Layanan
                </div>
                <div
                  onClick={() => setSelectedTab("Lampiran")}
                  className="pageTitle"
                  style={{
                    color: selectedTab === "Lampiran" ? "#1565D8" : "#555555",
                    borderBottom: selectedTab === "Lampiran" ? "solid" : "none",
                    borderBottomWidth:
                      selectedTab === "Lampiran" ? "0.8px" : "0px",
                    borderBottomColor:
                      selectedTab === "Lampiran" ? "#1565D8" : "#fff",
                    fontWeight: selectedTab === "Lampiran" ? "500" : "300",
                  }}
                >
                  Lampiran
                </div>
              </div>
              {selectedTab === "Dokter" && (
                <>
                  <div className="grid2">
                    {dataKlinik?.doctors?.map((el, idx) => {
                      return (
                        <div className="griditem2" key={idx}>
                          <div className="borderImage">
                            <img
                              src={
                                el.image
                                  ? el.image
                                  : require("../../asset/png/dokter.png")
                              }
                              height={80}
                              width={80}
                              style={{ borderRadius: 80 }}
                              alt="dokter"
                            />
                          </div>
                          <div
                            style={{ marginLeft: "30px", alignSelf: "center" }}
                          >
                            <div className="dokterName">{el.name}</div>
                            <div className="dokterSpesialis">
                              Spesialis {el.specialist}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {dataKlinik?.doctors?.length % 2 === 1 && (
                      <div className="griditem2"></div>
                    )}
                  </div>
                </>
              )}
              {selectedTab === "Fasilitas" && (
                <div style={{ marginTop: "30px" }}>
                  <div className="row jbetween" style={{ width: "53%" }}>
                    <div style={{ color: "#707C8B", fontSize: "14px" }}>
                      SEMUA LAYANAN
                    </div>
                    <div
                      onClick={() => {
                        dispatch(updateClinicService(dataKlinik._id));
                      }}
                    >
                      {loading ? (
                        <MoonLoader color="#36D7B7" css={override} size={15} />
                      ) : (
                        <div style={{ cursor: "pointer" }}>
                          <FiRefreshCcw />
                        </div>
                      )}
                    </div>
                  </div>
                  {dataKlinik?.services?.map((el, idx) => {
                    return (
                      <div className="layanan" key={idx}>
                        <div className="jbetween row">
                          <div style={{fontSize: "18px"}}>{el.name}</div>
                          <div onClick={() => checkExpanded(el._id)} style={{cursor: "pointer", fontSize: "14px"}}>detail</div>
                        </div>
                        {
                          expandedId.includes(el._id) && (<div>

                            {/* <div style={{marginTop: "15px", color:"#707C8B"}}>Paket ini termasuk :</div> */}
                            {
                              el.itemCheck.map(item => {
                                return (
                                  <div key={item.id} id={item.id} style={{marginLeft: "10px", marginTop: "15px"}}>- {item.name}</div>
                                )
                              })
                            }
                          </div>)
                        }
                      </div>
                    );
                  })}
                </div>
              )}
              {selectedTab === "Lampiran" && (
                <div style={{ marginTop: "30px" }}>
                  <div >
                    <label
                      className="buttonUpload"
                      htmlFor="document"
                      style={{ cursor: "pointer", textAlign: "center" }}
                    >
                      {loading ? (
                        <ScaleLoader
                          color="white"
                          height="10px"
                          width="2px"
                        />
                      ) : (
                        "Upload Dokumen"
                      )}
                    </label>
                    <input
                      id="document"
                      type="file"
                      onChange={(e) => handleUpload(e)}
                      accept="application/pdf"
                      className="hidden"
                    />
                  </div>
                  {dataKlinik?.documents?.length > 0 && (
                    <table style={{ marginTop: "20px" }}>
                      <thead>
                        <tr style={{ lineHeight: "50px", width: "100%" }}>
                          <td
                            style={{
                              color: "#707C8B",
                              fontSize: "14px",
                              width: "50%",
                              borderBottom: "solid",
                              borderBottomWidth: "2px",
                              borderBottomColor: "#E5E5E5",
                            }}
                          >
                            Nama
                          </td>
                          <td
                            style={{
                              color: "#707C8B",
                              fontSize: "14px",
                              width: "30%",
                              borderBottom: "solid",
                              borderBottomWidth: "2px",
                              borderBottomColor: "#E5E5E5",
                            }}
                          >
                            Tanggal dibagikan
                          </td>
                          <td
                            style={{
                              color: "#707C8B",
                              fontSize: "14px",
                              width: "20%",
                              borderBottom: "solid",
                              borderBottomWidth: "2px",
                              borderBottomColor: "#E5E5E5",
                            }}
                          >
                            Aksi
                          </td>
                        </tr>
                      </thead>
                      {dataKlinik?.documents?.map((el, idx) => {
                        return (
                          <tbody key={idx}>
                            <tr style={{ lineHeight: "50px" }}>
                              <td
                                style={{ color: "#707C8B", fontSize: "14px" }}
                              >
                                {el.name.length < 55
                                  ? el.name
                                  : el.name.slice(0, 55) + "  ..."}
                              </td>
                              <td
                                style={{ color: "#707C8B", fontSize: "14px" }}
                              >
                                {formatDate(el.createdAt)}
                              </td>
                              <td
                                style={{
                                  color: "#707C8B",
                                  fontSize: "14px",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setTitlePdf(el.name);
                                    setUrlPdf(el.url);
                                    setModalPdf(true);
                                  }}
                                >
                                  <IcEye />
                                </div>
                                {
                                  el.createdBy === "Company" &&
                                  <div
                                  style={{
                                    marginLeft: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setDeleteKey(el.key);
                                    setModalDelete(true);
                                  }}
                                >
                                  <IcTrash />
                                </div>
                                }
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {modalDelete && (
        <ModalConfirmation
          action={() => deleteHandle()}
          modal={setModalDelete}
          title={`Yakin ingin menghapus dokumen ini ?`}
        />
      )}
      {modalPdf && (
        <ModalPDFViewer title={titlePdf} modal={setModalPdf} url={urlPdf} />
      )}
    </div>
  );
}

export default DetailKlinik;
