import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/klinik.css";

import Search from "../../components/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClinic,
  getAllClinic,
} from "../../store/actions";
import ModalConfirmation from "../../components/ModalConfirmation";

import IcTrash from "../../asset/svg/trash";
import Medqcare from "../../asset/svg/medqcare"
import formatDate from "../../helpers/formatDate";

function ListKlinik(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataKlinik } = useSelector((state) => state.clinicState);

  const [data, setData] = useState([]); //all data

  const [searchName, setSearchName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [modalDelete, setModalDelete] = useState(false);

  // setData
  useEffect(() => {
    dispatch(getAllClinic());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setData(dataKlinik);
    // eslint-disable-next-line
  }, [dataKlinik]);

  //search by name
  useEffect(() => {
    if (searchName === "") {
      setData(dataKlinik);
    } else if (dataKlinik.length) {
      let regexp = new RegExp(searchName, "gi");
      const newData = dataKlinik.filter((el) => {
        return el.name.match(regexp) !== null;
      });
      setData(newData);
    }
    // eslint-disable-next-line
  }, [searchName]);

  const showTrashIcon = (id) => {
    document.getElementById(id).style.display = "block";
  };

  const hideTrashIcon = (id) => {
    document.getElementById(id).style.display = "none";
  };

  const deleteHandle = (clinicId) => {
    dispatch(deleteClinic(clinicId));
  };

  return (
    <div className="bgcontent">
      <div className="klinik">
        <div className="row jbetween">
          <div className="row">
            <div className="header">Semua Faskes</div>
            <div className="ml">
              <Search
                searchName={searchName}
                setSearchName={setSearchName}
                placeholder={"Pencarian"}
              />
            </div>
          </div>
          <div
            className="button"
            onClick={(e) => {
              e.preventDefault();
              navigate("/klinik/add");
            }}
          >
            + Tambah Faskes
          </div>
        </div>
        {data.length ? (
          <>
            {data?.map((el) => {
              return (
                <div className="carditem" key={el._id}>
                  <div className="row">
                    <img
                      src={
                        el.imageUrl
                          ? el.imageUrl
                          : require("../../asset/png/klinik.png")
                      }
                      height={130}
                      width={135}
                      alt="klinik"
                    />
                    <div className="name">
                      <div className="title">{el.name}</div>
                      <div className="report">
                        <div className="detailreport mr">
                          Pemeriksaan Terakhir
                        </div>
                        {el.lastEvent && (
                          <div
                            className="status"
                            style={{
                              borderColor:
                                el.lastEvent.status === "Selesai"
                                  ? "#2F8B08"
                                  : "#D22828",
                              color:
                                el.lastEvent.status === "Selesai"
                                  ? "#2F8B08"
                                  : "#D22828",
                            }}
                          >
                            {el.lastEvent.status}
                          </div>
                        )}
                      </div>
                      {el.lastEvent ? (
                        <>
                          <div className="detailreport">
                            {formatDate(el.lastEvent.date)}
                          </div>
                          <div className="eventName">{el.lastEvent.name}</div>
                          <div className="price">
                            {el.lastEvent.totalParticipant} x{" "}
                            {"Rp " +
                              el.lastEvent.services.price.toLocaleString(
                                "id-ID"
                              )}
                          </div>
                        </>
                      ) : (
                        <div
                          className="detailreport"
                          style={{ fontWeight: "bold" }}
                        >
                          Belum ada event
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "65%",
                      justifyContent: "space-between",
                    }}
                    onMouseEnter={() => showTrashIcon(`iconTrash${el._id}`)}
                    onMouseLeave={() => hideTrashIcon(`iconTrash${el._id}`)}
                  >
                    <div style={{ alignSelf: "flex-end" }}>
                      <div
                        style={{ display: "none", cursor: "pointer" }}
                        id={`iconTrash${el._id}`}
                        onClick={() => {
                          setDeleteId(el._id);
                          setModalDelete(true);
                        }}
                      >
                        <IcTrash />
                      </div>
                    </div>
                    <div
                      className="detail"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/klinik/detail/${el.name}`, {
                          replace: true,
                          state: { dataKlinik: el },
                        });
                      }}
                    >
                      Lihat Detail Klinik
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div
            style={{ width: "100%", justifyContent: "center", display: "flex" }}
          >
            <div style={{ padding: "200px" }}>
              <Medqcare />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#c6cbcf",
                  marginTop: "20px"
                }}
              >
                Tidak ada data tersedia
              </div>
            </div>
          </div>
        )}
      </div>
      {modalDelete && (
        <ModalConfirmation
          action={() => deleteHandle(deleteId)}
          modal={setModalDelete}
          title={`Klinik dan semua event terkait akan dihapus, anda yakin ingin menghapusnya ?`}
        />
      )}
    </div>
  );
}

export default ListKlinik;
