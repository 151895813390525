import React from "react";

const IcEdit = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.10838 11.9302C3.05149 11.9871 3.01321 12.0595 2.99698 12.1375L2.42198 15.0135C2.39517 15.1468 2.43747 15.2843 2.53337 15.3811C2.61067 15.4584 2.71477 15.4998 2.8214 15.4998C2.84731 15.4998 2.87427 15.4974 2.90107 15.4917L5.77623 14.9167C5.8559 14.9004 5.92828 14.8622 5.98443 14.8052L12.4195 8.37012L9.54434 5.49512L3.10838 11.9302Z" fill="#355EAD" />
      <path d="M14.407 3.50664C13.6141 2.71361 12.3241 2.71361 11.5318 3.50664L10.4062 4.63222L13.2814 7.50737L14.407 6.38164C14.7909 5.99861 15.0024 5.48779 15.0024 4.94451C15.0024 4.40123 14.7909 3.89042 14.407 3.50664Z" fill="#355EAD" />
    </svg>

  );
};
export default IcEdit;
