import React from "react";

const PolygonUp = ({ active = false }) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16583 6.00246L0.144531 0.97583H8.18714L4.16583 6.00246Z"
        fill={active ? "#DC650F" : "#7D90B2"}
      />
    </svg>
  );
};
export default PolygonUp;
