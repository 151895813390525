import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Dashboard from "../screen/Dashboard/Dashboard";
import Logout from "../screen/Login/Logout";
import TodayActivity from "../screen/Activity/TodayActivity";
import Profile from "../screen/Dashboard/Profile";

import EventNav from "./EventNav";
import KlinikNav from "./klinikNav";
import KaryawanNav from "./karyawanNav";
import ResultNav from "./resultNav";
import "../styles/app.css";
import { setCloseAllModal } from "../store/actions";

function SidebarNav() {
  const dispatch = useDispatch()
  const { modalClicked, closeAllModal } = useSelector(state => state.messageState)
  const [clickAllArea, setClickAllArea] = useState(false)

  useEffect(() => {
    setClickAllArea(false)
    if(clickAllArea){
      if(!modalClicked){
        dispatch(setCloseAllModal(true))
      } 
    }
    // eslint-disable-next-line
  },[clickAllArea])

  useEffect(() => {
    if(closeAllModal){
      dispatch(setCloseAllModal(false))
    }
    // eslint-disable-next-line
  },[closeAllModal])

  return (
    <div className="container" id="container" onClick={() => setClickAllArea(true)}>
      <Sidebar />
      <Navbar />
      <div className="body">
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/event/*" element={<EventNav />} />
          <Route path="/klinik/*" element={<KlinikNav />} />
          <Route path="/karyawan/*" element={<KaryawanNav />} />
          <Route path="/activity" element={<TodayActivity />} />
          <Route path="/result/*" element={<ResultNav />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/*" element={<Navigate replace to="/dashboard" />} />
        </Routes>
      </div>
    </div>
  );
}

export default SidebarNav;
