import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import CreateEvent from "../screen/Kesehatan/CreateEvent";
import ListTagihan from "../screen/Tagihan/ListTagihan";
import DetailTagihan from "../screen/Tagihan/DetailTagihan";

function EventNav() {
  return (
        <Routes>
          <Route path="/" element={<ListTagihan />} />
          <Route path="/detail" element={<DetailTagihan />} />
          <Route path='/detail/:klinikId' element={<DetailTagihan />}/>
          <Route path='/create' element={<CreateEvent />}/>
          <Route path='/create/:eventGroup' element={<CreateEvent />}/>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
  );
}

export default EventNav;
