import React from "react";

const IcActivity = ({active}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1625 2.50049H20.8363C25.0875 2.50049 27.4875 4.91174 27.5 9.16299V20.838C27.5 25.088 25.0875 27.5005 20.8363 27.5005H9.1625C4.91125 27.5005 2.5 25.088 2.5 20.838V9.16299C2.5 4.91174 4.91125 2.50049 9.1625 2.50049ZM15.0615 22.3255C15.6003 22.3255 16.049 21.9255 16.099 21.388V8.65054C16.149 8.26304 15.9628 7.87429 15.6252 7.66304C15.274 7.45054 14.849 7.45054 14.5127 7.66304C14.174 7.87429 13.9877 8.26304 14.024 8.65054V21.388C14.0877 21.9255 14.5365 22.3255 15.0615 22.3255ZM20.8112 22.3255C21.3362 22.3255 21.7849 21.9255 21.8487 21.388V17.288C21.8849 16.8867 21.6987 16.513 21.3599 16.3005C21.0237 16.088 20.5987 16.088 20.2487 16.3005C19.9099 16.513 19.7237 16.8867 19.7737 17.288V21.388C19.8237 21.9255 20.2724 22.3255 20.8112 22.3255ZM10.2738 21.388C10.2238 21.9255 9.775 22.3255 9.23625 22.3255C8.69875 22.3255 8.24875 21.9255 8.2 21.388V12.7505C8.1625 12.3617 8.34875 11.9755 8.6875 11.763C9.02375 11.5505 9.45 11.5505 9.7875 11.763C10.1238 11.9755 10.3125 12.3617 10.2738 12.7505V21.388Z"
        fill={ active ? "#558EFF" : "#8094AE"}
      />
    </svg>
  );
};
export default IcActivity;
