import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../styles/tagihan.css";

import IcPanah from "../../asset/svg/panah";
import Search from "../../components/Search";
import Filter from "../../components/Filter";
import ModalDetail from "./ModalDetail";
import ModalResume from "./ModalResume";
import { useDispatch, useSelector } from "react-redux";
import { getBackgroundColor, getTextColor } from "../../components/getColor";
import FilterCalender from "../../components/FilterCalender";
import formatDate from "../../helpers/formatDate";
import Medqcare from "../../asset/svg/medqcare";
import { getClinicById, getDetailEvents, setDataDetailEvents, setErrorMessage } from "../../store/actions";
import getLastOneMonth from "../../helpers/getLastOneMonth";
import formatSimpleDate from "../../helpers/formatSimpleDate";

function DetailTagihan() {
  const { klinikId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { dataKlinikById } = useSelector(state => state.clinicState)
  const { groupEvents } = useSelector((state) => state.eventState);
  const { errorMessage } = useSelector(state => state.messageState)

  const [data, setData] = useState([]); //all data

  const [searchName, setSearchName] = useState("");
  const [event, setEvent] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");

  const [statusBayarModal, showStatusBayarModal] = useState(false);
  const [statusBayar, setStatusBayar] = useState([]);
  const [selectedStatusBayar, setSelectedStatusBayar] = useState("");

  const [statusPeriksaModal, showStatusPeriksaModal] = useState(false);
  const [statusPeriksa, setStatusPeriksa] = useState([]);
  const [selectedStatusPeriksa, setSelectedStatusPeriksa] = useState("");

  const [tanggalModal, showTanggalModal] = useState(false);
  const [selectedTanggal, setSelectedTanggal] = useState("");
  const [selectedRange, setSelectedRange] = useState({});

  const [modalDetail, setModalDetail] = useState(false);
  const [modalResume, setModalResume] = useState(false);
  const [reservasiId, setReservasiId] = useState("")

  useEffect(() => {
    const lastMonth = getLastOneMonth();
    setSelectedTanggal(formatSimpleDate(lastMonth.startDate) + " - " + formatSimpleDate(lastMonth.endDate))
    setSelectedRange(getLastOneMonth());
    setStatusBayar(["Semua Status Bayar", "Sudah Dibayar", "Belum Dibayar"]);
    setStatusPeriksa([
      "Semua Status Event",
      "Menunggu Konfirmasi",
      "Sedang Berlangsung",
      "Selesai",
      "Dibatalkan",
    ]);
    setEvent(["Semua"]);
    setSelectedStatusBayar("Semua Status Bayar");
    setSelectedStatusPeriksa("Semua Status Event");
    setSelectedEvent("Semua");
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if(reservasiId !== ""){
      dispatch(getDetailEvents(reservasiId))
    }
    // eslint-disable-next-line
  },[reservasiId])

  useEffect(() => {
    if(!modalDetail){
      dispatch(setDataDetailEvents({}))
      setReservasiId("")
    }
    // eslint-disable-next-line
  },[modalDetail])

  useEffect(() => {
    if(dataKlinikById._id){
      setData(dataKlinikById);
    } 
    // eslint-disable-next-line
  },[dataKlinikById])

  useEffect(() => {
    if(errorMessage){
      let regexp = new RegExp("Cast to ObjectId failed", "gi");
      if(typeof errorMessage === "string"  && errorMessage.match(regexp) !== null){
        dispatch(setErrorMessage(""))
        navigate('/event')
      }
    }
    // eslint-disable-next-line
  },[errorMessage])


  useEffect(() => {
    if (klinikId && selectedRange && selectedStatusPeriksa && selectedStatusBayar && selectedEvent){
      dispatch(getClinicById(klinikId, queryFormatter(selectedRange), selectedStatusPeriksa, selectedStatusBayar, selectedEvent))
    }
    // eslint-disable-next-line
  }, [
    selectedStatusBayar,
    selectedStatusPeriksa,
    selectedEvent,
    searchName,
    selectedRange,
  ]);

  const checkDigit = (num) => {
    if (num < 10) {
      return `0${num}`
    } else {
      return num
    }
  }
  const queryFormatter = (date) => {
    let startDate = new Date(date.startDate).getFullYear() + "-" + checkDigit(new Date(date.startDate).getMonth() + 1) + "-" + checkDigit(new Date(date.startDate).getDate())
    let endDate = date.endDate ? date.endDate : date.startDate
    endDate =  new Date(date.endDate).getFullYear() + "-" + checkDigit(new Date(date.endDate).getMonth() + 1) + "-" + checkDigit(new Date(date.endDate).getDate())
    return startDate + " " + endDate
  }

  useEffect(() => {
    if (groupEvents.length > 0) {
      setEvent(["Semua"].concat(groupEvents));
    }
  }, [groupEvents]);

  const selectFilter = (filtername, filter) => {
    switch (filtername) {
      case "statusBayar":
        showStatusBayarModal(false);
        setSelectedStatusBayar(filter);
        break;

      case "statusPeriksa":
        showStatusPeriksaModal(false);
        setSelectedStatusPeriksa(filter);
        break;

      case "tanggal":
        showTanggalModal(false);
        setSelectedTanggal(filter);
        break;

      default:
        break;
    }
  };

  const closeModal = () => {
    showStatusBayarModal(false)
    showStatusPeriksaModal(false)
    showTanggalModal(false)
  }

  return (
    <div className="bgcontent">
      <div className="headerLink">
        <Link to={"/event"} className="link">
          Event & Tagihan
        </Link>
        <IcPanah />
        <div className="currentLink">Detail Transaksi</div>
      </div>
      <div className="tagihan">
        {data && (
          <>
            <div className="cardDetail">
              <div className="cardHeader">
                <div className="row">
                  <img
                    src={
                      data.klinikImage
                        ? data.klinikImage
                        : require("../../asset/png/klinik.png")
                    }
                    height={80}
                    width={85}
                    alt="klinik"
                  />
                  <div className="name">
                    <div className="title mr">{data?.name}</div>
                    <div className="detailreport mt" style={{ width: "350px"}}>
                      {data.address}
                    </div>
                  </div>
                </div>
                <div className="row mt20">
                  <div style={{ width: "130px" }} className="grayText">
                    <div className="font13">Total Tagihan</div>
                    <div className="mt font16 darkBlueText">
                      {data.totalTagihan
                        ? "Rp " + data.totalTagihan.toLocaleString("id-ID")
                        : "-"}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "1px",
                      height: "50px",
                      backgroundColor: "#D6D6D6",
                      marginRight: "15px",
                    }}
                  />
                  <div style={{ width: "100px" }} className="redText">
                    <div className="font13">Jatuh Tempo</div>
                    <div className="mt font16">{data.jatuhTempo || "-"}</div>
                  </div>
                </div>
              </div>
              <div className="cardBody">
                <div style={{width: "200px"}}>
                  <Search
                    searchName={searchName}
                    setSearchName={setSearchName}
                    placeholder={"Pencarian"}
                  />
                </div>
                <div className="event">
                  {event?.map((el, idx) => {
                    return (
                      <div
                        key={idx}
                        className="eventname mt20"
                        style={{
                          color: selectedEvent === el ? "#109CF1" : "#8891A0",
                          borderWidth: selectedEvent === el ? "1.5px" : "1px",
                          borderColor:
                            selectedEvent === el ? "#109CF1" : "#8891A0",
                        }}
                        onClick={() => setSelectedEvent(el)}
                      >
                        {el}
                      </div>
                    );
                  })}
                </div>
                <div className="filter mt20">
                  <div className="row">
                    <Filter
                      filterName={"statusBayar"}
                      modal={statusBayarModal}
                      showModal={() => {
                        showStatusPeriksaModal(false);
                        showTanggalModal(false);
                        statusBayarModal
                          ? showStatusBayarModal(false)
                          : showStatusBayarModal(true);
                      }}
                      item={statusBayar}
                      selectedItem={selectedStatusBayar}
                      selectFilter={selectFilter}
                      first={true}
                      closeModal={closeModal}
                    />
                    <Filter
                      filterName={"statusPeriksa"}
                      modal={statusPeriksaModal}
                      showModal={() => {
                        showStatusBayarModal(false);
                        showTanggalModal(false);
                        statusPeriksaModal
                          ? showStatusPeriksaModal(false)
                          : showStatusPeriksaModal(true);
                      }}
                      item={statusPeriksa}
                      selectedItem={selectedStatusPeriksa}
                      selectFilter={selectFilter}
                      closeModal={closeModal}
                    />
                    <FilterCalender
                      modal={tanggalModal}
                      selectedItem={selectedTanggal}
                      setSelectedTanggal={setSelectedTanggal}
                      setSelectedRange={setSelectedRange}
                      showModal={() => {
                        showStatusBayarModal(false);
                        showStatusPeriksaModal(false);
                        tanggalModal
                          ? showTanggalModal(false)
                          : showTanggalModal(true);
                      }}
                      closeModal={closeModal}
                    />
                    <div
                      className="reset"
                      onClick={() => {
                        setSearchName("");
                        setSelectedStatusBayar("Semua Status Bayar");
                        setSelectedStatusPeriksa("Semua Status Event");
                        const lastMonth = getLastOneMonth();
                        setSelectedTanggal(formatSimpleDate(lastMonth.startDate) + " - " + formatSimpleDate(lastMonth.endDate))
                        setSelectedRange(getLastOneMonth());
                        showStatusBayarModal(false);
                        showStatusPeriksaModal(false);
                        showTanggalModal(false);
                      }}
                    >
                      RESET FILTER
                    </div>
                  </div>
                </div>
                <div className="contentPemeriksaan">
                  {data?.events?.length > 0 ? (
                    <>
                      {data.events?.map((el) => {
                        return (
                          <div key={el._id} className="cardPemeriksaan">
                            <div>
                              <div className="detailPemeriksaan">
                                <div className="pricePemeriksaan">
                                  {formatDate(el.date) + " / " + el.time + " WIB"}
                                </div>
                                <div
                                  className="statusDetail"
                                  style={{
                                    backgroundColor: getBackgroundColor(
                                      el.status
                                    ),
                                    color: getTextColor(el.status),
                                  }}
                                >
                                  {el.status}
                                </div>
                                <div
                                  className="statusDetail"
                                  style={{
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    borderColor:
                                      el.statusBayar === "Sudah Dibayar"
                                        ? "#2F8B08"
                                        : "#D22828",
                                    color:
                                      el.statusBayar === "Sudah Dibayar"
                                        ? "#2F8B08"
                                        : "#D22828",
                                  }}
                                >
                                  {el.statusBayar}
                                </div>
                              </div>
                              <div className="namePemeriksaan">{el.name}</div>
                              <div className="notePemeriksaan"><b>Deskripsi Event :</b> {el.description}</div>
                              <div className="pricePemeriksaan">
                                {el.totalParticipant} x{" "}
                                {"Rp " +
                                  el.services.price.toLocaleString("id-ID")}
                              </div>
                            </div>
                            <div className="priceDetail">
                              <div>Total Biaya</div>
                              <div className="namePemeriksaan">
                                {"Rp " +
                                  (
                                    el.tagihan ? el.tagihan.totalPrice : el.totalParticipant * el.services.price 
                                  ).toLocaleString("id-ID")}
                              </div>
                              <div
                                className="lihatDetail"
                                onClick={() => {
                                  setModalDetail(true);
                                  setReservasiId(el.reservasiEventWeb)
                                }}
                              >
                                Lihat Detail
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <div style={{ padding: "50px" }}>
                        <Medqcare />
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            color: "#c6cbcf",
                            marginTop: "20px"
                          }}
                        >
                          Tidak ada data tersedia
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {modalDetail && (
        <ModalDetail
          setModal={setModalDetail}
          changeModal={() => setModalResume(true)}
        />
      )}
      {modalResume && (
        <ModalResume
          setModal={setModalResume}
        />
      )}
    </div>
  );
}

export default DetailTagihan;
