import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import SidebarNav from './navigation/SidebarNav';
import Login from './screen/Login/Login'
import Register from './screen/Login/Register'
import LupaPassword from './screen/Login/LupaPassword';
import CreatePassword from './screen/Login/CreatePassword';
import { setIsLogin } from './store/actions';
import { toast, ToastContainer } from "react-toastify";
import { setErrorMessage, setSuccessMessage } from "../src/store/actions";
import './styles/app.css'

function App() {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()
  const { isLogin } = useSelector(state => state.userState)
  const { errorMessage, successMessage } = useSelector(state => state.messageState)


  useEffect(() => {
    const path = location.pathname.split('/')[1]
    if(!localStorage.getItem('access_token') && path !== 'login' && path !== 'register' && path !== '/reset-password' && location.pathname.split('/')[1] !== 'reset-password' ){
      navigate('/login')
    }
    if((location.pathname === '/login' || location.pathname === '/register') && localStorage.getItem('access_token')){
      dispatch(setIsLogin(true))
      navigate('/')
    }
    // eslint-disable-next-line
  }, [isLogin])

  useEffect(() => {
    if(errorMessage){
      toast(errorMessage)
      dispatch(setErrorMessage(''))
    }
    if(successMessage){
      toast(successMessage)
      dispatch(setSuccessMessage(""))
    }
    // eslint-disable-next-line
  },[errorMessage, successMessage])

  return (
    <div className='App'>
      <ToastContainer />
      <Routes>
        <Route path="/*" element={<SidebarNav />} />
        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<LupaPassword />} />
        <Route path="reset-password/:token" element={<CreatePassword />} />
        <Route path="register" element={<Register />} />
      </Routes>
    </div>
  )
}

export default App;
