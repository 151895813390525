import React, { useEffect, useState } from "react";
import "../../styles/navbar.css";
// import IcNotif from "../../asset/svg/notif";
import IcUser from "../../asset/svg/user";
// import IcMessage from "../../asset/svg/message-circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterCalender from "../FilterCalender"

import formatDay from "../../helpers/formatday";
import ModalUser from "../ModalUser";

import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";
import { useDispatch, useSelector } from "react-redux";
import { setRangeDashboardData, getUserData } from "../../store/actions";
import formatSimpleDate from "../../helpers/formatSimpleDate";
import getLastOneMonth from "../../helpers/getLastOneMonth";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: lime;
`;

function Navbar() {
  const dispatch = useDispatch()
  const { loading, closeAllModal } = useSelector(state => state.messageState)
  const [modalUser, setModalUser] = useState(false)
  const [tanggalModal, showTanggalModal] = useState(false)
  const [selectedTanggal, setSelectedTanggal] = useState("");
  const [selectedRange, setSelectedRange] = useState({});
  const { userData } = useSelector(state => state.userState)

  useEffect(() => {
    dispatch(getUserData())
    const lastMonth = getLastOneMonth()
    setSelectedTanggal(formatSimpleDate(lastMonth.startDate) + " - " + formatSimpleDate(lastMonth.endDate))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (closeAllModal) {
      showTanggalModal(false)
    }
  }, [closeAllModal])

  useEffect(() => {
    if (selectedRange.startDate) {
      dispatch(setRangeDashboardData({
        startDate: selectedRange.startDate,
        endDate: selectedRange.endDate
      }))
    } else {
      dispatch(setRangeDashboardData(getLastOneMonth()))
    }
    // eslint-disable-next-line
  }, [selectedRange]);

  return (
    <div className="navbarContainer">
      <div className="navbar" style={{ minWidth: "1050px" }}>
        <div className="row jbetween" style={{ minWidth: "740px" }}>
          <div className="today">Hari ini ({formatDay()})</div>
          {
            window.location.pathname === "/dashboard" &&
            <div>
              <FilterCalender
                modal={tanggalModal}
                selectedItem={selectedTanggal}
                setSelectedTanggal={setSelectedTanggal}
                setSelectedRange={setSelectedRange}
                showModal={() => {
                  tanggalModal ? showTanggalModal(false) : showTanggalModal(true);
                }}
              />
            </div>
          }
        </div>
        <div className="row right">
          {/* <div className="mr">
            <IcMessage />
          </div> */}
          {/* <div className="mr" style={{ cursor: "pointer" }}>
            <IcNotif />
          </div> */}
          <div className="mr">
            {
              userData.image?.url ? (
                <img src={userData.image.url}
                  height={30}
                  width={30}
                  style={{ borderRadius: 100 }}
                  alt="profile"
                />
              ) : (
                <IcUser />
              )
            }
          </div>
          <div className="name" style={{ cursor: "pointer" }} onClick={() => modalUser ? setModalUser(false) : setModalUser(true)} >
            <div className="blueText">{userData.role}</div>
            <div className="row">
              <div className="mr grayText">{userData.name}</div>
              <div>
                <KeyboardArrowDownIcon fontSize="small" color="#8094AE" />
              </div>
            </div>
          </div>
          {
            modalUser && <ModalUser setModal={setModalUser} />
          }
        </div>
      </div>
      {
        loading && (
          <BarLoader
            color={"#36D7B7"}
            css={override}
            height="1px"
            width="100%"
          />
        )
      }
    </div>
  );
}

export default Navbar;
