import React from "react";

const AntigenNegatif = ({size}) => {
  return (
    <svg
      width={size || "20"}
      height={size || "20"}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2188 2.4375C6.81689 2.4375 2.4375 6.81689 2.4375 12.2188C2.4375 17.6206 6.81689 22 12.2188 22C17.6206 22 22 17.6206 22 12.2188C22 6.81689 17.6206 2.4375 12.2188 2.4375Z"
        fill="#28AD60"
      />
      <path
        d="M6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12Z"
        fill="white"
      />
    </svg>
  );
};
export default AntigenNegatif;
