import React, { useEffect, useState } from "react";
import "../styles/app.css";

import IcClose from "../asset/svg/ic_close2";
import IcEye from "../asset/svg/eyeLarge"

export default function ModalGantiPassword({ action, modal }) {

    const [payload, setPayload] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    })
    const [isSamePassword, setIsSamePassword] = useState(false)
    const [isConfirmPassword, setIsConfirmPassword] = useState(false)
    const [isShowPasword, setIsShowPassword] = useState({
        newPassword: false,
        confirmPassword: false
    })

    const changeHandle = (key, value) => {
        setPayload({
            ...payload,
            [key]: value
        })
    }

    useEffect(() => {
        validated()
        // eslint-disable-next-line
    },[payload])

    const validated = () => {
        if(payload.oldPassword === payload.newPassword){
            setIsSamePassword(true)
        } else {
            setIsSamePassword(false)
        }
        if(payload.newPassword === payload.confirmPassword && payload.newPassword.length >= 5 && payload.oldPassword !== payload.newPassword && payload.oldPassword !== ""){
            setIsConfirmPassword(true)
        } else {
            setIsConfirmPassword(false)
        }
    }

    const showPassword = (id) => {
        const element = document.getElementById(id)
        if(element.type === "password"){
            element.type = "text"
            setIsShowPassword({
                ...isShowPasword,
                [id]: true
            })
        } else {
            element.type = "password"
            setIsShowPassword({
                ...isShowPasword,
                [id]: false
            })
        }
    }

    const actionHandle = () => {
        action({
            oldPassword: payload.oldPassword,
            newPassword: payload.newPassword
        })
    }

    return (
        <div className="modalGantiPassword">
            <div className="modalGantiPassword-content">
                <div className="modalGantiPassword-title">
                    <div>Ganti Password</div>
                    <div className="modalGantiPassword-close" onClick={() => modal(false)}>
                        <IcClose />
                    </div>
                </div>
                <div style={{ padding: "35px" }}>
                    <div className="textInput">
                        <div className="inputTitle">Password Lama</div>
                        <div className="boxInput">
                            <input
                                type={"password"}
                                placeholder="Old Password"
                                value={payload.oldPassword}
                                onChange={(e) => changeHandle("oldPassword", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="textInput">
                        <div className="inputTitle">Password Baru</div>
                        <div className="boxInput row">
                            <input
                                id="newPassword"
                                style={{color: isConfirmPassword ? "green" : isSamePassword ? "red" : ""}}
                                type={"password"}
                                placeholder="New Password"
                                value={payload.newPassword}
                                onChange={(e) => changeHandle("newPassword", e.target.value)}
                            />
                            <div style={{cursor: "pointer", justifyContent: "center", display: "flex", flexDirection: "column"}} onClick={() => showPassword("newPassword")}>
                                <IcEye active={isShowPasword.newPassword}/>
                            </div>
                        </div>
                    </div>
                    <div className="textInput">
                        <div className="inputTitle">Konfirmasi Password Baru</div>
                        <div className="boxInput row">
                            <input
                                id={"confirmPassword"}
                                style={{color: isConfirmPassword ? "green" : isSamePassword ? "red" : ""}}
                                type={"password"}
                                placeholder="Confirm New Password"
                                value={payload.confirmPassword}
                                onChange={(e) => changeHandle("confirmPassword", e.target.value)}
                            />
                            <div style={{cursor: "pointer", justifyContent: "center", display: "flex", flexDirection: "column"}} onClick={() => showPassword("confirmPassword")}>
                                <IcEye active={isShowPasword.confirmPassword}/>
                            </div>
                        </div>
                    </div>
                    <div className="buttongroup">
                        <button className="button" style={{ fontSize: "16px", backgroundColor: isConfirmPassword ? "" : "#558eff9c" }} onClick={() => actionHandle()} disabled={!isConfirmPassword}>Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    )
}