const getColor = (status) => {
  switch (status) {
    case "Menunggu Konfirmasi":
      return "#BF9A16";

    case "Sedang Berlangsung":
      return "#2870FE";

    case "Selesai":
      return "#2F8B08";

    default:
      return "#D22828";
  }
};

const getBackgroundColor = (status) => {
  switch (status) {
    case "Menunggu Konfirmasi":
      return "#ecdda7";

    case "Sedang Berlangsung":
      return "#DFEAFF";

    case "Selesai":
      return "#CEF3BD";

    default:
      return "#FEE0E0";
  }
};

const getTextColor = (status) => {
  switch (status) {
    case "Menunggu Konfirmasi":
      return "#ab890f";

    case "Sedang Berlangsung":
      return "#2870FE";

    case "Selesai":
      return "#2F8B08";

    default:
      return "#FF4444";
  }
};

export { getColor, getBackgroundColor, getTextColor };
