import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import HasilPemeriksaan from "../screen/Pemeriksaan/HasilPemeriksaan";
import DetailPemeriksaan from "../screen/Pemeriksaan/DetailPemeriksaan";

function ResultNav() {
  return (
        <Routes>
          <Route path='/' element={<HasilPemeriksaan />}/>
          <Route path='/:employeeId' element={<DetailPemeriksaan />}/>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
  );
}

export default ResultNav;
