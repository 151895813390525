import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/event.css";

import IcClose from "../../asset/svg/ic_close";
import IcCheckup from "../../asset/svg/checkup";
import { toast, ToastContainer } from "react-toastify";


function ModalChooseEvent({ setModal, groupEvents }) {
  const navigate = useNavigate();
  const [addMode, setAddMode] = useState(false);
  const [types, setTypes] = useState(["Medical Check Up", "Swab PCR", "Swab Antigen"])

  useEffect(() => {
    const newGroups = ["Medical Check Up", "Swab PCR", "Swab Antigen"]
    groupEvents.forEach(el => {
      if(!newGroups.includes(el)){
        newGroups.push(el)
      }
    })
    setTypes(newGroups)
    // eslint-disable-next-line
  },[])

  const setMode = (e) => {
    if (!e.target.value){
      setAddMode(false)
    } else {
      if(e.target.value.toLowerCase() === "umum"){
        toast("'Umum' tidak boleh digunakan")
      } else {
        setTypes(types.concat([e.target.value]))
        setAddMode(false)
        e.target.value = ""
      }
    }
  };

  const enterHandle = (e) => {
    if (e.keyCode === 13) {
      setMode(e)
    }
  }

  return (
    <div className="modalChooseEvent">
      <ToastContainer />
      <div className="modalChooseEvent-content">
        <div className="modalChooseEvent-header">
          <div>Pilih Jenis Event</div>
          <div onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            <IcClose />
          </div>
        </div>
        {
          types.length > 0 && 
          types.map((el, idx) => {
            return (
              <div
              key={idx}
              className="modalChooseEvent-Item"
              onClick={() => navigate(`/event/create/${el}`, {replace: true, state: {eventGroup: el}})}
            >
              <IcCheckup />
              <div className="modalChooseEvent-text">{el}</div>
            </div>
            )
          })
        }

        <div
          className="modalChooseEvent-Tambah"
          style={{ display: addMode ? "none" : "flex" }}
          onClick={() => setAddMode(true)}
        >
          {"+"}
        </div>

        {addMode && (
          <div className="modalChooseEvent-Input">
            <input
              onKeyUp={(e) => enterHandle(e)}
              onBlur={(e) => setMode(e)}
              autoFocus={true}
              type={"text"}
              placeholder="Tulis Jenis Event"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalChooseEvent;
