import React from "react";

const Gembok = () => {
  return (
    <svg width="174" height="174" viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M103.121 72.5383V53.6481C103.121 44.8104 95.8395 37.5286 87.0018 37.5286C78.1641 37.5286 70.8824 44.8104 70.8824 53.6481V72.5383H56.6816V53.6481C56.6816 36.972 70.3255 23.3279 87.0018 23.3279C103.678 23.3279 117.322 36.972 117.322 53.6481V72.5383H103.121Z" fill="#8C9BA6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M83.0211 38.0335C76.0789 39.8261 70.8812 46.1817 70.8812 53.6483V72.5385H62.9219V53.6483C62.9219 44.8106 70.2036 37.5289 79.0413 37.5289C80.4124 37.5286 81.746 37.7043 83.0211 38.0335ZM87.0006 23.3279C85.6521 23.3279 84.324 23.4183 83.0211 23.5913C97.8344 25.5556 109.362 38.3206 109.362 53.6483V72.5385H117.321V53.6483C117.321 36.972 103.677 23.3279 87.0006 23.3279Z" fill="#7C8B96" />
      <path fillRule="evenodd" clipRule="evenodd" d="M50.7954 68.8881H123.205C129.692 68.8881 134.999 74.1951 134.999 80.6818V138.879C134.999 145.366 129.692 150.672 123.205 150.672H50.7954C44.3088 150.672 39.002 145.366 39.002 138.879V80.6815C39.0017 74.1951 44.3088 68.8881 50.7954 68.8881Z" fill="#BED8FB" />
      <path fillRule="evenodd" clipRule="evenodd" d="M123.746 68.9011C129.983 69.1866 134.998 74.3767 134.998 80.6815V138.879C134.998 145.365 129.691 150.672 123.204 150.672H50.7944C45.6436 150.672 41.2372 147.326 39.6406 142.7C39.8202 142.708 40.0006 142.713 40.1821 142.713H112.592C119.079 142.713 124.385 137.406 124.385 130.919V72.7223C124.385 71.3865 124.16 70.1008 123.746 68.9011Z" fill="#9BC9FF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M87.0004 90.318C92.6476 90.318 97.2258 94.896 97.2258 100.543C97.2258 104.104 95.4059 107.238 92.646 109.069L97.0194 127.611C97.1158 128.019 97.0287 128.41 96.7685 128.739C96.5082 129.068 96.1477 129.242 95.7285 129.242H78.2727C77.8536 129.242 77.493 129.068 77.2328 128.739C76.9728 128.41 76.8855 128.019 76.9818 127.611L81.3553 109.069C78.5954 107.238 76.7754 104.104 76.7754 100.543C76.7749 94.896 81.3532 90.318 87.0004 90.318Z" fill="#F4F8F8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M87.0009 90.318C87.6477 90.318 88.2804 90.3787 88.8938 90.4936C84.151 91.3813 80.5612 95.5428 80.5612 100.543C80.5612 104.104 82.3812 107.238 85.1411 109.069L80.7676 127.611C80.6713 128.019 80.7584 128.41 81.0186 128.739C81.2789 129.068 81.6394 129.242 82.0586 129.242H78.2727C77.8536 129.242 77.493 129.068 77.2328 128.739C76.9728 128.41 76.8855 128.019 76.9818 127.611L81.3553 109.069C78.5954 107.238 76.7754 104.104 76.7754 100.543C76.7754 94.896 81.3537 90.318 87.0009 90.318Z" fill="#E5ECF1" />
    </svg>
  );
};
export default Gembok;
