import React, { useEffect, useState } from "react";
import "../styles/app.css";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import IcCalender from "../asset/svg/calender";
import IcDropdown from "../asset/svg/dropdown";
import { DateRangePicker } from "react-date-range";
import formatSimpleDate from "../helpers/formatSimpleDate"
import { useDispatch, useSelector } from "react-redux";
import { setModalClicked } from "../store/actions";
import getLastOneMonth from "../helpers/getLastOneMonth";

export default function FilterCalender({
  modal,
  showModal,
  selectedItem,
  setSelectedTanggal,
  setSelectedRange
}) {
  const dispatch = useDispatch()
  const { modalClicked } = useSelector(state => state.messageState)
  const [selectionRange, setSelectionRange] = useState([{
    startDate: new Date(getLastOneMonth().startDate),
    endDate: new Date(getLastOneMonth().endDate),
    key: "selection",
  }]);

  const handleSelect = (ranges) => {
    setSelectionRange([ranges.selection])
    setSelectedRange({
      startDate: new Date(ranges.selection.startDate),
      endDate: new Date(ranges.selection.endDate),
    })
    if ((Math.floor(new Date(ranges.selection.endDate).getTime() /1000) - Math.floor(new Date(ranges.selection.startDate).getTime() / 1000) === 86399)){
      setSelectedTanggal(`${formatSimpleDate(ranges.selection.startDate)}`)
      setTimeout(() => {
        showModal(false)
      },500)
    } else if (new Date(ranges.selection.startDate).getDate() === new Date(ranges.selection.endDate).getDate()){
      setSelectedTanggal(`${formatSimpleDate(ranges.selection.startDate)}`)
    }
    else {
      setSelectedTanggal(`${formatSimpleDate(ranges.selection.startDate)} - ${formatSimpleDate(ranges.selection.endDate)}`)
      setTimeout(() => {
        showModal(false)
      },500)
    }
  };

  useEffect(() => {
    if(modalClicked){
      dispatch(setModalClicked(false))
    } 
    // eslint-disable-next-line
  },[modalClicked])

  const areaClicked = () => {
    dispatch(setModalClicked(true))
  }

  return (
    <div className="boxFilterCalender">
      <div
        className="filterArea"
        onClick={() => {
          areaClicked()
          showModal()
        }}
      >
        <div className="row" style={{ marginTop: "2px" }}>
          <IcCalender />
          <div className="text">{selectedItem}</div>
        </div>
        <div>
          <IcDropdown />
        </div>
      </div>
      
    {modal && (
      <div className="modalFilterCalender" onClick={areaClicked}>
        <div className="modalFilterCalender-container">
        <DateRangePicker
          ranges={selectionRange}
          onChange={handleSelect}
          showDateDisplay={false}
        />
        </div>
      </div>
    )}
    </div>
  );
}
