import React from "react";

const Calender = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.054 3.75716H17.1374V2.84049C17.1374 2.29049 16.7707 1.92383 16.2207 1.92383C15.6707 1.92383 15.304 2.29049 15.304 2.84049V3.75716H7.9707V2.84049C7.9707 2.29049 7.60404 1.92383 7.05404 1.92383C6.50404 1.92383 6.13737 2.29049 6.13737 2.84049V3.75716H5.2207C3.66237 3.75716 2.4707 4.94883 2.4707 6.50716V7.42383H20.804V6.50716C20.804 4.94883 19.6124 3.75716 18.054 3.75716ZM2.4707 17.5072C2.4707 19.0655 3.66237 20.2572 5.2207 20.2572H18.054C19.6124 20.2572 20.804 19.0655 20.804 17.5072V9.25716H2.4707V17.5072ZM16.2207 11.0905C16.7707 11.0905 17.1374 11.4572 17.1374 12.0072C17.1374 12.5572 16.7707 12.9238 16.2207 12.9238C15.6707 12.9238 15.304 12.5572 15.304 12.0072C15.304 11.4572 15.6707 11.0905 16.2207 11.0905ZM16.2207 14.7572C16.7707 14.7572 17.1374 15.1238 17.1374 15.6738C17.1374 16.2238 16.7707 16.5905 16.2207 16.5905C15.6707 16.5905 15.304 16.2238 15.304 15.6738C15.304 15.1238 15.6707 14.7572 16.2207 14.7572ZM11.6374 11.0905C12.1874 11.0905 12.554 11.4572 12.554 12.0072C12.554 12.5572 12.1874 12.9238 11.6374 12.9238C11.0874 12.9238 10.7207 12.5572 10.7207 12.0072C10.7207 11.4572 11.0874 11.0905 11.6374 11.0905ZM11.6374 14.7572C12.1874 14.7572 12.554 15.1238 12.554 15.6738C12.554 16.2238 12.1874 16.5905 11.6374 16.5905C11.0874 16.5905 10.7207 16.2238 10.7207 15.6738C10.7207 15.1238 11.0874 14.7572 11.6374 14.7572ZM7.05404 11.0905C7.60404 11.0905 7.9707 11.4572 7.9707 12.0072C7.9707 12.5572 7.60404 12.9238 7.05404 12.9238C6.50404 12.9238 6.13737 12.5572 6.13737 12.0072C6.13737 11.4572 6.50404 11.0905 7.05404 11.0905ZM7.05404 14.7572C7.60404 14.7572 7.9707 15.1238 7.9707 15.6738C7.9707 16.2238 7.60404 16.5905 7.05404 16.5905C6.50404 16.5905 6.13737 16.2238 6.13737 15.6738C6.13737 15.1238 6.50404 14.7572 7.05404 14.7572Z"
        fill="#8094AE"
      />
    </svg>
  );
};
export default Calender;
