import React, { useEffect, useState } from "react";
import "../styles/app.css";

import { ToastContainer, toast } from "react-toastify";
import * as Papa from "papaparse";

import IcUnduh from "../asset/svg/unduh";
import IcExcel from "../asset/svg/ic_excel";
import { useDispatch, useSelector } from "react-redux";
import { createBulkEmployee } from "../store/actions";
import { setModalClicked } from "../store/actions";

export default function ModalAdd({
  modal,
  showModal,
  closeModal,
  selectedItem,
  tambahManual,
}) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { modalClicked, closeAllModal } = useSelector(state => state.messageState)

  const handleFileUpload = async (e) => {
    const myFile = e.target.files[0];

    Papa.parse(myFile, {
      complete: (result) => setData(result.data),
    });
  };

  useEffect(() => {
    if (data.length) {
      let dataComputed = [];
      let objectData = [];

      if(data[0].length === 12){
        objectData = data[0].map(el => {
          let key = el
          if(key !== "NIP" && key !== "NIK"){
            key = key[0].toLowerCase() + key.slice(1)
          }
          if(key === "birthOfDate"){
            key = "dob"
          }
          return key
        })
      } else {
        data[0][0].split(",").forEach((el) => {
          let key = el
          if(key !== "NIP" && key !== "NIK"){
            key = key[0].toLowerCase() + key.slice(1)
          }
          if(key === "birthOfDate"){
            key = "dob"
          }
          objectData.push(key);
        });
      }

      if (objectData.length < 12) {
        toast("Wrong Document Format");
      } else {
        for (let i = 1; i < data.length; i++) {
          let person = []
          if(data[i].length === 1){
            person = data[i][0].split(",");
          } else {
            person = data[i]
          }
  
          if (person.length < 12) {
            continue;
          }
  
          if (person.length > 12) {
            person[12] = person.slice(12).join(",");
          }
  
          let objectPerson = {};
          let isNull = false
  
          person.forEach((el, idx) => {
            if(objectData[idx] === 'name' && person[idx] === ''){
              isNull = true
            }
            objectPerson[objectData[idx]] = person[idx];
          });
          
          if(!isNull){
            dataComputed.push(objectPerson);
          }
        }
        dispatch(createBulkEmployee(dataComputed));
        showModal();
      }

    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if(modalClicked){
      dispatch(setModalClicked(false))
    } 
    // eslint-disable-next-line
  },[modalClicked])

  useEffect(() => {
    if(closeAllModal){
      closeModal()
    }
    // eslint-disable-next-line
  },[closeAllModal])

  const areaClicked = () => {
    dispatch(setModalClicked(true))
  }

  return (
    <div className="boxModal">
      <ToastContainer />
      <div
        className="filterArea"
        style={{ transform: modal ? `translateX(${"68px"})` : null }}
        onClick={() => {
          areaClicked()
          showModal()
        }}
      >
        <div className="textButton">{selectedItem}</div>
      </div>
      {modal && (
        <ul className="modal" style={{marginTop: "2px"}} onClick={areaClicked}>
          <li className="modal-option" onClick={tambahManual}>
            {"Tambah Manual"}
          </li>
          <li className="line" />
          <label className="modal-option" htmlFor="importExcel">
            {"Import dari Excel"}
            <IcExcel />
          </label>
          <input
            id="importExcel"
            name="mainImage"
            type="file"
            accept=".csv,.xlsx,.xls"
            className="hidden"
            onChange={(e) => handleFileUpload(e)}
          />
          <a
            href={require("../asset/data/formatUploadKaryawan.csv")}
            download="Format Employee Example"
            style={{ textDecoration: "none" }}
          >
            <li className="modal-option" onClick={() => showModal()}>
              {"Download Contoh Format"}
              <IcUnduh />
            </li>
          </a>
        </ul>
      )}
    </div>
  );
}
