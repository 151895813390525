import {combineReducers} from 'redux'
import userReducer from './users'
import employeeReducer from './employee'
import clinicReducer from './clinic'
import messageReducer from './message'
import eventReducer from './event'
import resumeMedisReducer from './resumeMedis'


export const reducer = combineReducers({
    messageState: messageReducer,
    userState: userReducer,
    clinicState: clinicReducer,
    employeeState: employeeReducer,
    eventState: eventReducer,
    resumeMedisState: resumeMedisReducer
})
