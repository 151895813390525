import React from "react";
import "../styles/app.css";
import LogOut from "../asset/svg/log_out"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ModalUser({ setModal }) {
  const navigate = useNavigate()
  const { userData } = useSelector(state => state.userState)
  const getInitial = () => {
    const arrayName = userData.name.split(" ");
    const initial = arrayName.map((el) => {
      return el[0].toUpperCase();
    });
    return initial[0] + (initial[1] ? initial[1] : "")
  };

  return (
    <div className="boxModalUser" onMouseLeave={() => setModal(false)}>
      <div className="boxModalUser-style" />
      <div className="boxModalUser-header" onClick={() => {
        navigate('/profile')
        setModal(false)
      }}>
        <div className="boxModalUser-initial">{getInitial()}</div>
        <div className="ml">
          <div className="boxModalUser-name">
            {userData.name}
          </div>
          <div className="boxModalUser-email">
            {userData.email}
          </div>
        </div>
      </div>
      <div className="boxModalUser-item" onClick={() => navigate('/logout')}>
        <LogOut />
        <div className="boxModalUser-itemName">Keluar</div>
      </div>
    </div>
  );
}
