import React from "react";

const Dropdown = ({ active }) => {
  return (
    <svg
      width="10"
      height="15"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_110_3843)">
        <path
          d="M7.5449 10.9091C7.29399 10.9091 7.04311 10.8174 6.85182 10.6344L0.83212 4.8742C0.449189 4.50778 0.449189 3.91369 0.83212 3.54741C1.2149 3.18114 1.83563 3.18114 2.21859 3.54741L7.5449 8.64439L12.8712 3.54759C13.2542 3.18131 13.8748 3.18131 14.2576 3.54759C14.6407 3.91386 14.6407 4.50795 14.2576 4.87438L8.23798 10.6346C8.04659 10.8176 7.79571 10.9091 7.5449 10.9091Z"
          fill="#4A4A4A"
        />
      </g>
      <defs>
        <clipPath id="clip0_110_3843">
          <rect
            width="15"
            height="20"
            fill="white"
            transform="translate(0.544922 0.0908203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Dropdown;
