import axios from "axios";
import { baseURL } from "../../config";

export function setIsLogin(payload) {
  return {
    type: "SET_ISLOGIN",
    payload,
  };
}

export function setCloseAllModal(payload){
  return {
    type: "SET_CLOSEALLMODAL",
    payload
  }
}

export function setModalClicked(payload){
  return {
    type: "SET_MODALCLICKED",
    payload
  }
}

export function setDataDashboard(payload){
  return {
    type: "SET_DATADASHBOARD",
    payload
  }
}

export function setRangeDashboardData(payload){
  return {
    type: "SET_RANGEDASHBOARDDATA",
    payload
  }
}

export function setUserData(payload){
  return {
    type: "SET_USERDATA",
    payload
  }
}

export function setLoading(payload) {
  return {
    type: "SET_LOADING",
    payload,
  };
}

export function setErrorMessage(payload) {
  return {
    type: "SET_ERRORMESSAGE",
    payload,
  };
}

export function setSuccessMessage(payload) {
  return {
    type: "SET_SUCCESSMESSAGE",
    payload
  }
}

export function setDataKlinik(payload) {
  return {
    type: "SET_DATAKLINIK",
    payload,
  };
}

export function setDataKlinikById(payload){
  return {
    type: "SET_DATAKLINIK_BYID",
    payload
  }
}

export function setDataKlinikByName(payload) {
  return {
    type: "SET_DATAKLINIK_BYNAME",
    payload,
  };
}

export function setDataKlinikWeb(payload) {
  return {
    type: "SET_DATAKLINIKWEB",
    payload,
  };
}

export function setDataServices(payload) {
  return {
    type: "SET_DATASERVICES",
    payload,
  };
}

export function setDataGroupEvents(payload) {
  return {
    type: "SET_DATAGROUPEVENTS",
    payload,
  };
}

export function setDataReservasi(payload){
  return {
    type: "SET_DATARESERVASI",
    payload
  }
}

export function setSuccessCreatePassword(payload){
  return {
    type: "SET_ISSUCCESSCREATEPASSWORD",
    payload
  }
}

export function setDataDetailEvents(payload){
  return {
    type: "SET_DETAILEVENTS",
    payload
  }
}

export function login(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/user/login`, {
      method: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        const { access_token, message } = data.data;
        if (access_token) {
          localStorage.setItem("access_token", access_token);
          dispatch(setIsLogin(true));
        } else {
          dispatch(setErrorMessage(message));
        }
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
}

export function register(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/profile/create`, {
      method: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        dispatch(setSuccessMessage("Profile Perusahaan Berhasil dibuat"));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}


export function getDataDashboard(startDate, endDate) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/profile/dashboard/${startDate}/${endDate}`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setDataDashboard(data.data.data));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getDetailEvents(reservasiId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/event/getDetailEvent/${reservasiId}`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setDataDetailEvents(data.data.data));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function createClinic(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/create`, {
      method: "POST",
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        dispatch(setSuccessMessage(data.data.message));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getClinicByName(klinikName) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/getClinicByName/${klinikName}`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setDataKlinikByName(data.data.result));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function updateClinicService(klinikId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/updateServices/${klinikId}`, {
      method: "PUT",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setDataKlinikByName(data.data.result));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getAllClinic() {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/getAll`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setDataKlinik(data.data.result));
        dispatch(setDataGroupEvents(data.data.allgroup))
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getClinicById(clinicId, range_date, status_event, status_bayar, group_event) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/getClinicById/${clinicId}?range_date=${range_date}&&status_event=${status_event}&&status_bayar=${status_bayar}&&group_event=${group_event}`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setDataKlinikById(data.data.result));
        dispatch(setDataGroupEvents(data.data.allgroup))
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getAllClinicWeb() {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/getAllClinicWeb`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((res) => {
        dispatch(setDataKlinikWeb(res.data.data))
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getServices(clinicId){
  return (dispatch) => {
    dispatch(setLoading(true))
    axios(`${baseURL}/clinic/services/${clinicId}`, {
      method: 'GET',
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      dispatch(setDataServices(res.data.data))
    })
    .catch(err => {
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}

export function deleteClinic(clinicId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/delete/${clinicId}`, {
      method: "DELETE",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        dispatch(getAllClinic());
        dispatch(setSuccessMessage(data.data.message));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function uploadDocument(payload, nameKlinik) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/uploadDokumen`, {
      method: "PUT",
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        dispatch(setSuccessMessage(data.data.message));
        dispatch(getClinicByName(nameKlinik))
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function deleteDocument(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/clinic/deleteDokumen`, {
      method: "DELETE",
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        dispatch(setSuccessMessage("Dokumen Berhasil dihapus"))
        dispatch(setDataKlinikByName(res.data.data))
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function uploadBuktiTransfer(payload, reservasiId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/event/uploadBuktiTranfer`, {
      method: "PUT",
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        dispatch(setSuccessMessage(data.data.message));
        dispatch(getDetailEvents(reservasiId))
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }
}

export function getReservasiData(startDate, endDate){
  return (dispatch) => {
    dispatch(setLoading(true))
    axios(`${baseURL}/reservasi/getAllReservasi/${startDate}/${endDate}`, {
      method: 'GET',
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      dispatch(setDataReservasi(res.data.data))
    })
    .catch(err => {
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}

export function getUserData() {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/user/userData`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        dispatch(setUserData(response.data));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function editUserData(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/user/editData`, {
      method: "PUT",
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(setSuccessMessage(response.data.message));
        localStorage.setItem("access_token", response.data.newToken);
        dispatch(getUserData())
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }
}

export function changePassword(payload) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/user/changePassword`, {
      method: "POST",
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(setSuccessMessage(response.data.message));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }
}


export function getResetPassword(email) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/user/getResetPassword/${email}`, {
      method: "GET",
      headers: {
        "x-secret": "123456"
      },
    })
      .then((response) => {
        dispatch(setSuccessMessage(response.data.message));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function verifyToken(token) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/user/checkToken`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: token
      },
    })
      .then((response) => {
        dispatch(setSuccessMessage(response.data.message));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}


export function createPassword(token, password) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/user/createPassword`, {
      method: "POST",
      data: {password},
      headers: {
        "x-secret": "123456",
        authorization: token
      },
    })
      .then((response) => {
        dispatch(setSuccessMessage(response.data.message));
        dispatch(setSuccessCreatePassword(true))
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}