import React from "react";
import IcDashboard from "../../asset/svg/dashboard";
import IcClinic from "../../asset/svg/clinic";
import IcKesehatan from "../../asset/svg/kesehatan";
import IcUsers from "../../asset/svg/users";
import IcActivity from "../../asset/svg/ic_activity";
import IcTagihan from "../../asset/svg/ic_tagihan";
// import IcSetting from "../../asset/svg/setting";

export const SidebarData = [
  {
    id: 1,
    title: "Dashboard",
    icon: <IcDashboard />,
    iconActive: <IcDashboard active={true} />,
    link: "/dashboard"
  },
  {
    id: 2,
    title: "Aktifitas Hari ini",
    icon: <IcActivity />,
    iconActive: <IcActivity active={true} />,
    link: "/activity"
  },
  {
    id: 3,
    title: "Daftar Faskes",
    icon: <IcClinic />,
    iconActive: <IcClinic active={true} />,
    link: "/klinik"
  },
  {
    id: 4,
    title: "Karyawan",
    icon: <IcUsers />,
    iconActive: <IcUsers active={true} />,
    link: "/karyawan"
  },
  {
    id: 5,
    title: "Event & Tagihan",
    icon: <IcTagihan />,
    iconActive: <IcTagihan active={true} />,
    link: "/event",
  },
  {
    id: 6,
    title: "Hasil Pemeriksaan",
    icon: <IcKesehatan />,
    iconActive: <IcKesehatan active={true} />,
    link: "/result"
  },
  // {
  //   id: 7,
  //   title: "Pengaturan",
  //   icon: <IcSetting />,
  //   iconActive: <IcSetting active={true} />,
  //   link: "/setting"
    // child: [
    //   {
    //     title: "Tambah Admin",
    //     link: "/add-admin",
    //   },
    //   {
    //     title: "Logout",
    //     link: "/logout",
    //   }
    // ],
  // },
];
