import React, { useEffect, useState } from "react";
import { SidebarData } from "./SidebarData";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../../styles/sidebar.css";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(location.pathname.split('/')[1]);
  const [show, setShow] = useState(true);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [activeId, setActiveId] = useState('')

  useEffect(() => {
    navigate(activeLink);
    // eslint-disable-next-line
  }, [activeLink]);

  useEffect(() => {
    setActiveLink(window.location.pathname)
    // eslint-disable-next-line
  },[window.location.pathname])

  const selectMenu = (e, val) => {
      navigate(val.link);
      e.preventDefault();
      setActive(val.title);
      active === val.title
        ? setShow(() => (show ? false : true))
        : setShow(true);
      setActiveLink(() => {
        if (active === val.title) {
          return activeLink;
        } else if (val.child) {
          return val.child[0].link;
        } else {
          return val.link;
        }
      });
      setActiveId(`content${val.id}`)
      
      const allcontent = document.getElementsByClassName("accordian-content")
      const selectedContent = document.getElementById(`content${val.id}`)
      
      var arr = Array.prototype.slice.call( allcontent )
      arr.map(el => el.style.maxHeight = 0)
      if (selectedContent && activeId !== `content${val.id}`){
        selectedContent.style.maxHeight = '300px'
      } else {
        setActiveId('')
      }
  }

  return (
    <div className="Sidebar">
      <div className="logo">
        <img
          src={require("../../asset/png/logoMedQCare.png")}
          alt="logo medqcare"
          height={32}
        />
      </div>
      <ul className="SidebarList">
        {SidebarData.map((val) => {
          return (
            <React.Fragment key={val.id}>
              <li
                className="accordion"
                id={ activeLink.includes(val.link) ? "active" : ""}
                onClick={(e) => selectMenu(e, val)}
              >
                <div id="icon">
                  { activeLink.includes(val.link) ? val.iconActive : val.icon}
                </div>
                <div id="title" style={{color: activeLink.includes(val.link) ? '#558EFF' : '#4A4A4A'}}>{val.title}</div>
                <div id="toogle">
                  {val.child && (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      style={{
                        transform:
                          active === val.title && show
                            ? "rotate(180deg)"
                            : "rotate(270deg)",
                      }}
                    />
                  )}
                </div>
              </li>
              {val.child &&
                <div className="accordian-content" id={`content${val.id}`}>
                  {
                    val.child.map((el, idx) => {
                    return (
                      <div
                        className="panel"
                        key={idx}
                        id={`/${activeLink.split('/')[1]}` === el.link ? "activechild" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveLink(el.link);
                        }}
                      >
                        <div id="title">{el.title}</div>
                      </div>
                    );
                  })
                  }
                </div>
                }
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
}

export default Sidebar;
