const initialState = {
    dataResumeMedis: [],
    resumeMedisPatient: {},
    todayActivity: []
}

export default function reducer(state = initialState, action){
    switch(action.type){
        case "SET_DATARESUMEMEDIS":
            return {...state, dataResumeMedis: action.payload}
        case "SET_RESUMEMEDIS_PATIENT":
            return {...state, resumeMedisPatient: action.payload}
        case "SET_TODAYACTIVITY":
            return {...state, todayActivity: action.payload}
        default: 
            return state
    }
}