import React from "react";

const TrendingDown = ({ active }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_178_3980)">
        <path
          d="M0.666341 12.5L6.99967 6.16667L10.333 9.5L15.333 4.5"
          stroke="#28AD60"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66699 12.5L0.666992 12.5L0.666992 8.5"
          stroke="#28AD60"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_178_3980">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16 16.5) rotate(180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TrendingDown;
