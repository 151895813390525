import React from "react";

const IcClose2 = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="13" fill="#E5E5E5" />
      <path d="M13.0026 11.7964L17.2158 7.58325L18.4193 8.78677L14.2061 12.9999L18.4193 17.2131L17.2158 18.4166L13.0026 14.2034L8.78945 18.4166L7.58594 17.2131L11.7991 12.9999L7.58594 8.78677L8.78945 7.58325L13.0026 11.7964Z" fill="#4F4F4F" />
    </svg>

  );
};
export default IcClose2;
