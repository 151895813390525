import React from "react";
import "../styles/app.css";

import IcClose from "../asset/svg/ic_close";

export default function modalPDFViewer({modal, title, url, type}){

    return (
        <div className="modalPdf">
            <div className="modalPdf-content" style={{width: type === "image" ? "50%" : ""}}>
                <div className="modalPdf-header">
                    <div style={{width: "98%", fontSize: "18px", color:"#4A4A4A", fontWeight: "bold"}}>{title}</div>
                    <div onClick={() => modal(false)} style={{cursor: "pointer"}}>
                        <IcClose />
                    </div>
                </div>
                {
                    type === "image" ? (
                        <div style={{width:"100%", height:"90%"}}>
                            <img src={url} height={"400px"} alt="bukti transfer"/>
                        </div>
                    ) : (
                        <iframe src={url} style={{width:"100%", height:"90%"}} frameBorder="0" title={title}></iframe>
                    )
                }
            </div>
        </div>
    )
}