import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/login.css";

import { register, setErrorMessage } from '../../store/actions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";


function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errorMessage, successMessage } = useSelector(state => state.messageState)

  const [payload, setPayload] = useState({
    nameProfile: "",
    nameUser: "",
    emailUser: "",
    password: ""
  })

  const registerHandle = () => {
    if(payload.nameProfile === '' || payload.nameUser === '' || payload.emailUser === '' || payload.password === ''){
      return toast('Semua Field Harus diisi')
    } 
    if(payload.password.length < 5){
      return toast('Password Minimal 5 Karakter')
    }
    if (payload.emailUser.match(/^.+@(?:[\w-]+\.)+\w+$/) === null){
      return toast('Format Email Salah')
    }
    dispatch(register(payload))
  }

  useEffect(() => {
    if(errorMessage){
      if (typeof errorMessage === "object"){
        toast(errorMessage[0])
      } else {
        toast(errorMessage)
      }
      dispatch(setErrorMessage(''))
    }
    if(successMessage){
      navigate('/login')
    }
    // eslint-disable-next-line
  },[errorMessage, successMessage])

  return (
    <div className="background">
      <ToastContainer  />
      <div className="card">
        <div className="content">
          <div className="inputBox">
            <input type="text" placeholder="Nama Perusahaan" 
              onChange={(e) => {
                setPayload({
                  ...payload,
                  nameProfile: e.target.value
                })
              }}
            />
          </div>
          <div className="inputBox">
            <input type="text" placeholder="Nama HR/PIC"
              onChange={(e) => {
                setPayload({
                  ...payload,
                  nameUser: e.target.value
                })
              }}
            />
          </div>
          <div className="inputBox">
            <input type="text" placeholder="Email HR/PIC" style={{textTransform: payload.emailUser !== "" ? "lowercase" : ""}}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  emailUser: e.target.value
                })
              }}
            />
          </div>
          <div className="inputBox">
            <input type="password" placeholder="Buat Password" 
              onKeyUp={(e) => {
                if(e.keyCode === 13){
                  registerHandle()
                }
              }}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  password: e.target.value
                })
              }}
            />
          </div>
          <div className="buttonSubmit" onClick={() => registerHandle()}>Daftar</div>
          <div className="login">
            <div>Sudah memiliki akun? 
            <a href="/login">Masuk</a>
            </div>
          </div>
          <div className="login">
            <div>By clicking "Register" you acknowledge that you have read and accept the
            <a href="/register">Terms</a> and
            <a href="/register">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
