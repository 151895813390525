import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import ListKlinik from "../screen/Klinik/ListKlinik";
import AddKlinik from "../screen/Klinik/AddKlinik";
import DetailKlinik from "../screen/Klinik/DetailKlinik";

function KlinikNav() {
  return (
        <Routes>
          <Route path='/' element={<ListKlinik />}/>
          <Route path='/add' element={<AddKlinik />}/>
          <Route path='/detail' element={<DetailKlinik />}/>
          <Route path='/detail/:klinikName' element={<DetailKlinik />}/>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
  );
}

export default KlinikNav;
