import axios from "axios";
import { baseURL } from "../../config";


export function setLoading(payload) {
  return {
    type: "SET_LOADING",
    payload,
  };
}

export function setErrorMessage(payload) {
  return {
    type: "SET_ERRORMESSAGE",
    payload,
  };
}

export function setSuccessMessage(payload) {
  return {
    type: "SET_SUCCESSMESSAGE",
    payload
  }
}

export function setDataResumeMedis(payload) {
  return {
    type: "SET_DATARESUMEMEDIS",
    payload
  }
}

export function setResumeMedisPatient(payload) {
  return {
    type: "SET_RESUMEMEDIS_PATIENT",
    payload
  }
}

export function setDataTodayActivity(payload){
  return {
    type: "SET_TODAYACTIVITY",
    payload
  }
}

export function getResumeMedisByDate(startDate, endDate) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/resumeMedis/getByDate/${startDate}/${endDate}`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setDataResumeMedis(data.data.result));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getResumeMedisPatient(employeeId) {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/resumeMedis/getByPatient/${employeeId}`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setResumeMedisPatient(data.data.result));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}


export function getTodayActivity() {
  return (dispatch) => {
    dispatch(setLoading(true));
    axios(`${baseURL}/resumeMedis/getSickPeople`, {
      method: "GET",
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem("access_token"),
      },
    })
      .then((data) => {
        dispatch(setDataTodayActivity(data.data.data));
      })
      .catch((err) => {
        dispatch(
          setErrorMessage(
            err.response ? err.response.data.message : err.message
          )
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

