import React, { useState, useEffect } from "react";
import "../../styles/event.css";
// import IcAdd from "../../asset/svg/add";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ModalPeserta from "./ModalPeserta";
import {
  createEvent,
  getAllClinic,
  getServices,
} from "../../store/actions";

import SearchInput from "../../components/SearchInput";
import IcDropdown from "../../asset/svg/dropdown";
import { toast, ToastContainer } from "react-toastify";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import formatDay from "../../helpers/formatday";
import ModalSelect from "../../components/modalSelect";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: lime;
`;

function CreateEvent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventGroup } = useParams();

  const { dataKlinik, dataServices } = useSelector((state) => state.clinicState);
  const { loading, successMessage, errorMessage } = useSelector((state) => state.messageState);
  const [modalKlinik, setModalKlinik] = useState(false)
  const [modalService, setModalService] = useState(false)
  const [modalPembayaran, setModalPembayaran] = useState(false)
  const [modalPeserta, setModalPeserta] = useState(false);

  const [payload, setPayload] = useState({
    groupEvent: "",
    clinicId: "",
    services: {},
    eventDate: "",
    paymentMethod: "",
    eventTime: "",
    participant: [],
    description: "",
  });

  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedKlinik, setSelectedKlinik] = useState({});
  const [selectedServices, setSelectedServices] = useState({});

  useEffect(() => {
    if (eventGroup) {
      dispatch(getAllClinic());
      setPayload({
        ...payload,
        groupEvent: eventGroup,
      });
    } else {
      navigate("/event");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getServices(selectedKlinik.clinicIdWeb));
    setSelectedServices({});
    if (selectedKlinik._id) {
      setPayload({
        ...payload,
        clinicId: selectedKlinik._id,
      });
    }
    // eslint-disable-next-line
  }, [selectedKlinik]);

  useEffect(() => {
    if (selectedServices._id) {
      setPayload({
        ...payload,
        services: selectedServices,
      });
    }
    // eslint-disable-next-line
  }, [selectedServices]);

  useEffect(() => {
    if (selectedEmployee.length > 0) {
      setPayload({
        ...payload,
        participant: selectedEmployee,
      });
    }
    // eslint-disable-next-line
  }, [selectedEmployee]);

  useEffect(() => {
    if (successMessage) {
      navigate("/event");
    }
    // eslint-disable-next-line
  }, [successMessage, errorMessage]);

  const saveHandle = () => {
    if (
      payload.eventDate === "" ||
      payload.eventTime === "" ||
      payload.description === ""
    ) {
      toast("Silahkan isi semua field");
    } else if (payload.clinicId === "") {
      toast("Silahkan pilih klinik");
    } else if (!payload.services._id) {
      toast("silahkan pilih layanan");
    } else if (payload.participant.length === 0) {
      toast("Pilih minimal 1 peserta");
    } else {
      dispatch(createEvent(payload));
    }
  };

  const closeModal = () => {
    setModalPembayaran(false)
    setModalKlinik(false)
    setModalService(false)
  }

  return (
    <div className="bgcontent">
      <ToastContainer />
      <div className="header">Tambah Event {payload.groupEvent}</div>
      <div className="event">
        <div className="row jbetween">
          <div className="forminput">
            <div style={{ marginBottom: "20px", height: "80px" }}>
              <SearchInput
                modal={modalKlinik}
                setModal={() => {
                  setModalService(false)
                  setModalPembayaran(false)
                  modalKlinik ? setModalKlinik(false) : setModalKlinik(true)
                }}
                addFeature={true}
                data={dataKlinik}
                value={selectedKlinik.name}
                loading={loading}
                setSelectedData={(item) => {
                  setSelectedKlinik(item);
                }}
              />
            </div>
            <div style={{ marginBottom: "20px", height: "80px" }}>
              <SearchInput
                modal={modalService}
                setModal={() => {
                  setModalKlinik(false)
                  setModalPembayaran(false)
                  modalService ? setModalService(false) : setModalService(true)
                }}
                title={"Pilih Layanan"}
                placeholder={"Pilih Layanan Yang Tersedia"}
                data={dataServices}
                value={selectedServices.name}
                loading={loading}
                setSelectedData={(item) => {
                  setSelectedServices(item);
                }}
              />
            </div>
            <div className="row jbetween">
              <fieldset className="boxArea">
                <legend className="fieldsetText">
                  Tanggal Event{" "}
                  <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
                </legend>
                <input
                  type="date"
                  placeholder="KLlinik Meranti"
                  className="inputStyle"
                  onChange={(e) =>
                    setPayload({ ...payload, eventDate: e.target.value })
                  }
                />
              </fieldset>
              <fieldset className="boxArea">
                <legend className="fieldsetText">
                  Waktu Event{" "}
                  <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
                </legend>
                <input
                  type="time"
                  placeholder="KLlinik Meranti"
                  className="inputStyle"
                  onChange={(e) =>
                    setPayload({ ...payload, eventTime: e.target.value })
                  }
                />
              </fieldset>
            </div>
            <fieldset
              className="boxArea"
              onClick={() => {
                if(!selectedKlinik.name || !payload.eventDate || !payload.eventTime){
                  toast("Silahkan pilih klinik dan jadwal terlebih dahulu")
                } else {
                  setModalPeserta(true);
                }
              }}
            >
              <legend className="fieldsetText">
                Pilih Peserta{" "}
                <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
              </legend>
              <div className="row jbetween" style={{ cursor: "pointer" }}>
                <div>
                  {selectedEmployee.length > 0
                    ? `Peserta terpilih : ${selectedEmployee.length} orang`
                    : "Peserta Event"}
                </div>
                <IcDropdown />
              </div>
            </fieldset>
            <div style={{height: "100px"}}>
              <ModalSelect
                item={["Umum", "Asuransi"]}
                modal={modalPembayaran}
                closeModal={closeModal}
                setModal={() => {
                  setModalKlinik(false)
                  setModalService(false)
                  modalPembayaran ? setModalPembayaran(false) : setModalPembayaran(true)
                }}
                legend={"Pembayaran"}
                defaultValue={"Pilih Metode Pembayaran"}
                value={payload.paymentMethod}
                selectValue={(item) =>
                  setPayload({ ...payload, paymentMethod: item })
                }
                addAbility={true}
              />
            </div>
            <fieldset className="boxArea">
              <legend className="fieldsetText">
                Deskripsi Event{" "}
                <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
              </legend>
              <textarea
                type="text"
                placeholder="Tulis Deskripsi Event"
                className="textArea"
                value={payload.description}
                onChange={(e) => {
                  setPayload({ ...payload, description: e.target.value });
                }}
              />
            </fieldset>
            {/* <div className="notif">
              <div style={{ paddingTop: "3px" }}>
                <IcAdd />
              </div>
              <div className="text">Syarat dan ketentuan</div>
            </div> */}
            <div className="buttongroup row">
              <div
                className="redbutton"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/event");
                }}
              >
                Batalkan
              </div>
              <div className="button" onClick={() => saveHandle()}>
                {loading ? (
                  <ScaleLoader
                    color="white"
                    loading={loading}
                    css={override}
                    height="13px"
                    width="2px"
                  />
                ) : (
                  <div>Buat Event</div>
                )}
              </div>
            </div>
          </div>
          <div style={{width: "350px"}}>
            {
              selectedServices.itemCheck && (
                <div className="detailPaket">
                  <div className="headerDetail">Detail Paket</div>
                  <div className="row jbetween">
                    <div style={{maxWidth: "70%"}}>{selectedServices.name}</div>
                    <div>Rp {(selectedServices.basePrice).toLocaleString("id-ID")}</div>
                  </div>
                  {
                    selectedServices.itemCheck?.map(el => {
                      return (
                        <div className="itemPaket" key={el._id}>- {el.name}</div>
                      )
                    })
                  }
                  <div className="row jbetween" style={{marginTop: "10px"}}>
                    <div>Total Peserta (orang) :</div>
                    <div>{selectedEmployee.length || "0"}</div>  
                  </div>
                  <div className="row jbetween" style={{marginTop: "10px"}}>
                    <div>Discount :</div>
                    <div>{selectedServices.discount ? selectedServices.discount + "%" : "-"}</div>  
                  </div>
                  <div className="row jbetween totalHarga" >
                    <div>Total Harga :</div>
                    <div>Rp {((selectedEmployee.length || "0") * selectedServices.price).toLocaleString("id-ID") }</div>  
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      {modalPeserta && (
        <ModalPeserta
          setModalPeserta={setModalPeserta}
          setSelectedEmployee={setSelectedEmployee}
          selectedEmployee={selectedEmployee}
          eventName={payload.services.name}
          klinikName={selectedKlinik.name}
          eventDate={formatDay(payload.eventDate)}
          eventTime={payload.eventTime}
        />
      )}
    </div>
  );
}

export default CreateEvent;
