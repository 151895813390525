import React, { useEffect, useState } from "react";
import "../../styles/home.css";

import { useNavigate } from "react-router-dom";

import ModalGantiPassword from "../../components/ModalGantiPassword";
import IcBack from "../../asset/svg/ic_back"
import IcEdit from "../../asset/svg/ic_edit"
import Camera from "../../asset/svg/camera"
import IcUser from "../../asset/svg/userLarge";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, editUserData, getUserData, setErrorMessage } from "../../store/actions";


function Profile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [gantiPasswordModal, setGantiPasswordModal] = useState(false)
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: ""
  })
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [isUploadImage, setIsUploadImage] = useState(false)
  const [fileTemp, setFileTemp] = useState(null)
  const [imageObject, setImageObject] = useState(null)

  const { userData } = useSelector(state => state.userState)
  const { successMessage } = useSelector(state => state.messageState)

  useEffect(() => {
    dispatch(getUserData())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userData.email) {
      setPayload({
        ...payload,
        name: userData.name,
        email: userData.email,
        phoneNumber: userData.phoneNumber
      })
    }
    // eslint-disable-next-line
  }, [userData])

  useEffect(() => {
    if (successMessage) {
      if (gantiPasswordModal) {
        setGantiPasswordModal(false)
      } else {
        navigate("/dashboard")
      }
    }
    // eslint-disable-next-line
  }, [successMessage])

  const editPayload = (key, value) => {
    if (key === "name" && value.length === 30) {
      return
    }
    if (key === "phoneNumber" && value.length === 15) {
      return
    }
    setPayload({
      ...payload,
      [key]: value
    })
  }

  useEffect(() => {
    checkIsDisabled()
    // eslint-disable-next-line
  }, [payload])

  const saveEdit = () => {
    if (payload.email === userData.email && payload.name === userData.name && payload.phoneNumber === userData.phoneNumber && !isUploadImage) {
      dispatch(setErrorMessage("Tidak Ada perubahan data"))
      return navigate("/dashboard")
    }
    if (payload.email.match(/^.+@(?:[\w-]+\.)+\w+$/) === null) {
      return dispatch(setErrorMessage("Format Email Salah"))
    }
    if (!payload.name.length) {
      return dispatch(setErrorMessage("Nama tidak boleh kosong"))
    }
    if (!payload.phoneNumber.length) {
      return dispatch(setErrorMessage("Nomor HP tidak boleh kosong"))
    }
    if (payload.password === "") {
      return dispatch(setErrorMessage("Masukkan password untuk konfirmasi perubahan"))
    }
    if (isUploadImage) {
      const formData = new FormData();
      formData.append("document", imageObject);
      formData.append("name", payload.name);
      formData.append("email", payload.email);
      formData.append("phoneNumber", payload.phoneNumber);
      formData.append("password", payload.password);

      return dispatch(editUserData(formData))
    } else {
      return dispatch(editUserData(payload))
    }
  }

  const checkIsDisabled = () => {
    if (!payload.name || !payload.email || !payload.phoneNumber || !payload.password) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
  }

  const changePasswordHandle = (payload) => {
    dispatch(changePassword(payload))
  }

  function handleUpload(e) {
    const file = e.target.files[0];
    setFileTemp(file)

    let newObject = new File(
      [file.slice(0, file.size, "image")],
      `${userData._id}${new Date().toISOString()}`,
      { type: "image" }
    );

    setIsUploadImage(true)
    setImageObject(newObject)

  }

  useEffect(() => {
    if(fileTemp){
      const target = document.getElementById('profileImage')
      target.src = window.URL.createObjectURL(fileTemp)
    }
  },[fileTemp])

  return (
    <div className="bgcontent">
      <div className="home">
        <div className="contentArea">
          <div className="header">Edit Profile</div>
          <div className="photoArea">
            {
              userData.image?.url || fileTemp ? (
                <img src={userData.image.url}
                  id={"profileImage"}
                  height={98}
                  width={98}
                  style={{ borderRadius: 100 }}
                  alt="profile"
                />
              ) : (
                <IcUser />
              )
            }

          </div>
          <label className="camera" htmlFor="uploadImage" style={{ cursor: "pointer" }}>
            <Camera />
          </label>
          <input
            id="uploadImage"
            type="file"
            onChange={(e) => handleUpload(e)}
            accept="image/png, image/gif, image/jpeg"
            className="hidden"
          />
          <div className="inputArea" >
            <div className="textInput">
              <div className="inputTitle">Nama Lengkap</div>
              <div className="boxInput">
                <input
                  type={"text"}
                  placeholder="Masukkan Nama Lengkap"
                  value={payload.name}
                  onChange={(e) => editPayload("name", e.target.value)}
                />
              </div>
            </div>
            <div className="textInput">
              <div className="inputTitle">Email</div>
              <div className="boxInput">
                <input
                  type={"text"}
                  placeholder="Masukkan Email"
                  value={payload.email}
                  onChange={(e) => editPayload("email", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="inputArea">
            <div className="textInput">
              <div className="inputTitle">Nomor Hp</div>
              <div className="boxInput">
                <input
                  className="inputNumberStyle"
                  type={"number"}
                  placeholder="Masukkan Nomor Hp"
                  value={payload.phoneNumber}
                  onChange={(e) => editPayload("phoneNumber", e.target.value)}
                />
              </div>
            </div>
            <div className="textInput">
              <div className="inputTitle">Password</div>
              <div className="boxInput row jbetween">
                <input
                  style={{ width: "60%" }}
                  type={"password"}
                  placeholder="Masukkan password"
                  value={payload.password}
                  onChange={(e) => editPayload("password", e.target.value)}
                />
                <div className="row" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => setGantiPasswordModal(true)}>
                  <IcEdit />
                  <div style={{ color: "#355EAD", marginLeft: "5px" }}>Ubah</div>
                </div>
              </div>
            </div>
          </div>
          <div className="submitArea">
            <div className="backButton" onClick={() => navigate('/dashboard')}>
              <IcBack />
              <span style={{ fontSize: "16px", marginLeft: "10px" }}>Batalkan, kembali ke Dashboard</span>
            </div>
            <button className="button" style={{ fontSize: "16px", width: "180px", backgroundColor: buttonDisabled ? "#558eff9c" : "" }} disabled={buttonDisabled} onClick={() => saveEdit()}>Simpan Perubahan</button>
          </div>
        </div>
      </div>
      {
        gantiPasswordModal && <ModalGantiPassword modal={setGantiPasswordModal} action={changePasswordHandle} />
      }
    </div>
  );
}

export default Profile;
