import React, { useState, useEffect } from "react";
import "../styles/app.css";

import { useDispatch, useSelector } from "react-redux";
import { setModalClicked } from "../store/actions";

import IcDropdown from "../asset/svg/dropdown";

export default function ModalSelect({
  item,
  legend,
  defaultValue,
  value,
  selectValue,
  addAbility,
  modal,
  setModal,
  closeModal
}) {
  const dispatch = useDispatch()
  const { modalClicked, closeAllModal } = useSelector(state => state.messageState)

  const [listItem, setListItem] = useState(item)
  const [editMode, setEditMode] = useState(false);

  const enterHandle = (e) => {
    if (e.keyCode === 13) {
      saveNewItem(e.target.value)
    }
  }

  const saveNewItem = (value) => {
    setModal()
    setEditMode(false)
    setListItem(listItem.concat([value]))
    selectValue(value)
  }

  useEffect(() => {
    if(!modal){
      setEditMode(false)
    }
  },[modal])

  useEffect(() => {
    if(modalClicked){
      dispatch(setModalClicked(false))
    } 
    // eslint-disable-next-line
  },[modalClicked])

  useEffect(() => {
    if(closeAllModal){
      closeModal()
    }
    // eslint-disable-next-line
  },[closeAllModal])

  const areaClicked = () => {
    dispatch(setModalClicked(true))
  }

  return (
    <div style={{ maxheight: "50px" }}>
      <fieldset className="modalSelect" onClick={() => {
        areaClicked()
        setModal()
      }}>
        <legend className="fieldsetText">
          {legend}
          <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
        </legend>
        <div style={{color: value ? "#3b4b66" : "#949495"}}>{value ? value : defaultValue}</div>
        <div>
          <IcDropdown />
        </div>
      </fieldset>
      {modal && (
        <ul className="optionModalSelect" onClick={areaClicked}>
          {listItem.map((el, idx) => {
            return (
              <React.Fragment key={idx}>
                <li
                  className="optionModal"
                  onClick={() => {
                    selectValue(el);
                    setModal();
                  }}
                >
                  {el}
                </li>
              </React.Fragment>
            );
          })}
          {addAbility && (
            <li
              className="optionModal"
              onClick={() => setEditMode(true)}
              style={{ display: editMode ? "none" : "flex" }}
            >
              {"+"}
            </li>
          )}
          {editMode && (
            <li className="optionEditModal" >
              <input
                id="newItem"
                type={"text"}
                autoFocus={true}
                style={{ color: "#4A4A4A" }}
                onKeyUp={(e) => enterHandle(e)}
              />
              <button 
                className="button-custom"
                onClick={(e) => {
                  e.preventDefault()
                  const value = document.getElementById('newItem').value
                  saveNewItem(value)
                }}>save</button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
