import React from "react";

const IcBack = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1671_4310)">
        <path d="M0.274969 11.3364C0.275249 11.3361 0.275484 11.3358 0.275812 11.3355L5.17444 6.4605C5.54142 6.09529 6.135 6.09665 6.5003 6.46368C6.86555 6.83067 6.86414 7.42424 6.49716 7.78949L3.20822 11.0625H23.0625C23.5803 11.0625 24 11.4822 24 12C24 12.5178 23.5803 12.9375 23.0625 12.9375H3.20827L6.49711 16.2105C6.86409 16.5757 6.8655 17.1693 6.50025 17.5363C6.13495 17.9034 5.54133 17.9046 5.17439 17.5395L0.275764 12.6645C0.275484 12.6642 0.275249 12.6639 0.274921 12.6636C-0.0922508 12.2971 -0.0910778 11.7016 0.274969 11.3364Z" fill="#EE443C" />
      </g>
      <defs>
        <clipPath id="clip0_1671_4310">
          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IcBack;
