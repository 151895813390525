import axios from "axios"
import { getAllClinic } from "."
import { baseURL } from '../../config'

export function setLoading(payload) {
  return {
    type: "SET_LOADING",
    payload
  }
}

export function setErrorMessage(payload) {
  return {
    type: "SET_ERRORMESSAGE",
    payload
  }
}

export function setSuccessMessage(payload) {
  return {
    type: "SET_SUCCESSMESSAGE",
    payload
  }
}

export function setDataEmployee(payload) {
  return {
    type: "SET_EMPLOYEES",
    payload
  }
}

export function setDataVerifiedEmployee(payload) {
  return {
    type: "SET_VERIFIED_EMPLOYEES",
    payload
  }
}

export function setDataDivision(payload) {
  return {
    type: "SET_DIVISIONS",
    payload
  }
}

export function setDataPositions(payload) {
  return {
    type: "SET_POSITIONS",
    payload
  }
}

export function setDataInsurance(payload) {
  return {
    type: "SET_INSURANCE",
    payload
  }
}

export function getEmployees(){
  return (dispatch) => {
    dispatch(setLoading(true))
    axios(`${baseURL}/employee/getAll`, {
      method: 'GET',
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      const { data, allDivision, allPosition, allInsurance } = res.data
      dispatch(setDataEmployee(data))
      dispatch(setDataDivision(allDivision))
      dispatch(setDataPositions(allPosition))
      dispatch(setDataInsurance(allInsurance))
    })
    .catch(err => {
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}


export function getEmployeesVerified(){
  return (dispatch) => {
    dispatch(setLoading(true))
    // axios(`${baseURL}/employee/getAllVerified`, {
    axios(`${baseURL}/employee/getAll`, {
      method: 'GET',
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      const { data, allDivision, allPosition, allInsurance } = res.data
      dispatch(setDataVerifiedEmployee(data))
      dispatch(setDataDivision(allDivision))
      dispatch(setDataPositions(allPosition))
      dispatch(setDataInsurance(allInsurance))
    })
    .catch(err => {
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}

export function createEmployee(payload){
  return (dispatch) => {
    dispatch(setLoading(true))
    axios(`${baseURL}/employee/create`, {
      method: 'POST',
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      dispatch(getEmployees())
      dispatch(setSuccessMessage("Karyawan Berhasil ditambahkan"))
    })
    .catch(err => {
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}

export function updateEmployee(payload){
  return (dispatch) => {
    dispatch(setLoading(true))
    axios(`${baseURL}/employee/update`, {
      method: 'POST',
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      dispatch(getEmployees())
      dispatch(setSuccessMessage("Data Karyawan Berhasil diubah"))
    })
    .catch(err => {
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}

export function createBulkEmployee(payload){
  return (dispatch) => {
    dispatch(setLoading(true))
    axios(`${baseURL}/employee/createBulk`, {
      method: 'POST',
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      dispatch(getEmployees())
      dispatch(setSuccessMessage('Upload File Berhasil'))
    })
    .catch(err => {
      dispatch(getEmployees())
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}

export function deleteEmployee(id){
  return (dispatch) => {
    dispatch(setLoading(true))
    axios(`${baseURL}/employee/delete/${id}`, {
      method: 'DELETE',
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      dispatch(setSuccessMessage("Data Karyawan berhasil dihapus"))
      dispatch(getEmployees())
    })
    .catch(err => {
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}


export function createEvent(payload){
  return (dispatch) => {
    dispatch(setLoading(true))
    axios(`${baseURL}/event/create`, {
      method: 'POST',
      data: payload,
      headers: {
        "x-secret": "123456",
        authorization: localStorage.getItem('access_token')
      },
    })
    .then(res => {
      dispatch(setSuccessMessage("Event Berhasil ditambahkan"))
      dispatch(getAllClinic())
    })
    .catch(err => {
      dispatch(setErrorMessage(err.response ? err.response.data.message : err.message))
    })
    .finally(() => dispatch(setLoading(false)))
  }
}
