import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import EmployeeList from "../screen/Employee/EmployeeList";
import AddEmployee from "../screen/Employee/AddEmployee"
import EditEmployee from "../screen/Employee/EditEmployee"

function KaryawanNav() {
  return (
        <Routes>
          <Route path='/' element={<EmployeeList />}/>
          <Route path='/add' element={<AddEmployee />}/>
          <Route path='/edit' element={<EditEmployee />}/>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
  );
}

export default KaryawanNav;
