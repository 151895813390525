import React from "react";

const Edit = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.43916 11.7549C2.37549 11.8186 2.33266 11.8996 2.31449 11.9869L1.67097 15.2057C1.64097 15.3549 1.6883 15.5087 1.79564 15.617C1.88214 15.7035 1.99865 15.7499 2.11799 15.7499C2.14699 15.7499 2.17715 15.7472 2.20715 15.7409L5.42494 15.0974C5.51411 15.079 5.59511 15.0364 5.65795 14.9725L12.8599 7.77059L9.64209 4.55298L2.43916 11.7549Z"
        fill="#918B8B"
      />
      <path
        d="M15.0849 2.32751C14.1976 1.43998 12.7538 1.43998 11.8671 2.32751L10.6074 3.58723L13.8252 6.80501L15.0849 5.54513C15.5146 5.11645 15.7513 4.54476 15.7513 3.93674C15.7513 3.32872 15.5146 2.75703 15.0849 2.32751Z"
        fill="#918B8B"
      />
    </svg>
  );
};
export default Edit;
