import React, { useEffect } from "react";
import '../styles/app.css'

import { useDispatch, useSelector } from "react-redux";
import { setModalClicked } from "../store/actions";

import IcUserSearch from "../asset/svg/userSearch";
import IcDompet from "../asset/svg/dompet";
import IcClinic from "../asset/svg/smallclinic";
import IcDropdown from "../asset/svg/dropdown";
import IcTagihan from "../asset/svg/tagihanSmall"
import IcCalender from "../asset/svg/calender"
import IcTestTube from "../asset/svg/testTube.js"

export default function Filter({
  filterName,
  modal,
  showModal,
  item,
  selectedItem,
  selectFilter,
  closeModal,
  first,
  disabled
}) {
  const dispatch = useDispatch()
  const { modalClicked, closeAllModal } = useSelector(state => state.messageState)

  useEffect(() => {
    if(modalClicked){
      dispatch(setModalClicked(false))
    } 
    // eslint-disable-next-line
  },[modalClicked])

  useEffect(() => {
    if(closeAllModal){
      closeModal()
    }
    // eslint-disable-next-line
  },[closeAllModal])

  const areaClicked = () => {
    dispatch(setModalClicked(true))
  }

  return (
    <div className="boxFilter" onClick={areaClicked}>
      <div className="filterArea" style={{marginLeft: first ? '0px' : '15px', backgroundColor: disabled ? "#EBEADD" : ""}} onClick={() => !disabled ? showModal() : null}>
        <div className="row" style={{ marginTop: "2px" }}>
          {filterName === 'divisi' && <IcUserSearch />}
          {filterName === 'pembayaran' && <IcDompet />}
          {filterName === 'faskes' && <IcClinic />}
          {(filterName === 'status' || filterName === 'statusBayar') && <IcTagihan />}
          {filterName === 'tanggal' && <IcCalender />}
          {filterName === 'statusPeriksa' && <IcTestTube />}
          <div className="text">{selectedItem}</div>
        </div>
        <div>
          <IcDropdown />
        </div>
      </div>
      {modal && (
        <ul className="modal" style={{marginLeft: first ? '0px' : '15px'}} onClick={areaClicked}>
          {item.map((el, idx) => {
            return (
              <React.Fragment key={idx}>
                {el !== selectedItem && (
                  <li
                    className="modal-option"
                    onClick={() => selectFilter(filterName, el)}
                  >
                    {el}
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </div>
  );
}
