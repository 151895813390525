import React from "react";

const Globe = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_178_3902)">
        <rect
          x="7"
          y="3"
          width="43"
          height="43"
          rx="21.5"
          stroke="#336CFB"
          strokeWidth="2"
        />
      </g>
      <path
        d="M28.5 15.3585C23.452 15.3585 19.3594 19.4511 19.3594 24.4991C19.3594 29.5471 23.452 33.6398 28.5 33.6398C33.548 33.6398 37.6406 29.5471 37.6406 24.4991C37.6406 19.4511 33.548 15.3585 28.5 15.3585Z"
        fill="#336CFB"
        stroke="white"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M28.5001 15.3585C25.9482 15.3585 23.5488 19.4511 23.5488 24.4991C23.5488 29.5471 25.9482 33.6398 28.5001 33.6398C31.052 33.6398 33.4515 29.5471 33.4515 24.4991C33.4515 19.4511 31.052 15.3585 28.5001 15.3585Z"
        fill="#336CFB"
        stroke="white"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M22.4062 18.4055C24.0867 19.5986 26.2022 20.3101 28.5001 20.3101C30.798 20.3101 32.9136 19.5986 34.594 18.4055"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.594 30.5944C32.9136 29.4013 30.798 28.6898 28.5001 28.6898C26.2022 28.6898 24.0867 29.4013 22.4062 30.5944"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 15.3585V33.6398"
        stroke="white"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M37.6406 24.499H19.3594"
        stroke="white"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <defs>
        <filter
          id="filter0_d_178_3902"
          x="0.5"
          y="-2.38419e-07"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.5" />
          <feGaussianBlur stdDeviation="2.75" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_178_3902"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_178_3902"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default Globe;
