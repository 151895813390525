import React, { useEffect, useState } from "react";

import Search from "../../components/Search";
import Filter from "../../components/Filter";

import IcPolygonUp from "../../asset/svg/polygonUp";
import IcPolygonDown from "../../asset/svg/polygonDown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getResumeMedisByDate } from "../../store/actions";
import formatDate from "../../helpers/formatDate";
import FilterCalender from "../../components/FilterCalender";
// import IcDetail from "../../asset/svg/detailMCU";
import AntigenPositif from "../../asset/svg/AntigenPositif";
import AntigenNegatif from "../../asset/svg/AntigenNegatif";
import formatSimpleDate from "../../helpers/formatSimpleDate";
import getLastOneMonth from "../../helpers/getLastOneMonth";

function HasilPemeriksaan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataResumeMedis } = useSelector((state) => state.resumeMedisState);

  const [data, setData] = useState([]); //all data
  const [dataRender, setDataRender] = useState([]); // data per page

  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const itemPerPage = 10;
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [arrayPage, setArrrayPage] = useState([]);

  const [searchName, setSearchName] = useState("");

  const [faskesModal, showFaskesModal] = useState(false);
  const [faskes, setFaskes] = useState([]);
  const [selectedFaskes, setSelectedFaskes] = useState("");

  const [statusModal, showStatusModal] = useState(false);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [divisiModal, showDivisiModal] = useState(false);
  const [divisi, setDivisi] = useState([]);
  const [selectedDivisi, setSelectedDivisi] = useState("");

  const [pembayaranModal, showPembayaranModal] = useState(false);
  const [pembayaran, setPembayaran] = useState([]);
  const [selectedPembayaran, setSelectedPembayaran] = useState("");

  const [tanggalModal, showTanggalModal] = useState(false);
  const [selectedTanggal, setSelectedTanggal] = useState("");
  const [selectedRange, setSelectedRange] = useState({});

  const [event, setEvent] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    const lastMonth = getLastOneMonth();
    setSelectedTanggal(formatSimpleDate(lastMonth.startDate) + " - " + formatSimpleDate(lastMonth.endDate))
    setSelectedRange(getLastOneMonth());
    setStatus(["Semua Status Bayar", "Sudah Dibayar", "Belum Dibayar"]);
    setDivisi(["Semua Divisi"]);
    setPembayaran(["Semua Pembayaran"]);
    setFaskes(["Semua Faskes"]);
    setEvent(["Semua"]);

    setSelectedDivisi("Semua Divisi");
    setSelectedPembayaran("Semua Pembayaran");
    setSelectedFaskes("Semua Faskes");
    setSelectedStatus("Semua Status Bayar");
    setSelectedEvent("Semua");
  }, []);

  useEffect(() => {
    if (dataResumeMedis.length > 0) {
      const allDivisi = ["Semua Divisi"];
      const allPembayaran = ["Semua Pembayaran"];
      const allFaskes = ["Semua Faskes"];
      const allEvent = ["Semua"];
      dataResumeMedis.forEach((el) => {
        if (!allDivisi.includes(el.employee.department)) {
          allDivisi.push(el.employee.department);
        }
        if (!allPembayaran.includes(el.service.paymentMethod)) {
          allPembayaran.push(el.service.paymentMethod);
        }
        if (!allFaskes.includes(el.clinic.name)) {
          allFaskes.push(el.clinic.name);
        }
        if (!allEvent.includes(el.groupEvent)) {
          allEvent.push(el.groupEvent);
        }
      });
      setDivisi(allDivisi);
      setPembayaran(allPembayaran);
      setFaskes(allFaskes);
      setEvent(allEvent);
    }
  }, [dataResumeMedis]);

  //pagination
  useEffect(() => {
    setTotalPage(Math.ceil(data.length / itemPerPage));
    setDataRender(
      data.slice(
        (activePage - 1) * itemPerPage,
        (activePage - 1) * itemPerPage + itemPerPage
      )
    );
    // eslint-disable-next-line
  }, [data, activePage]);

  useEffect(() => {
    const array = [];
    if (activePage > 1) array.push(activePage - 1);
    array.push(activePage);
    if (totalPage > activePage) array.push(activePage + 1);
    if (array.length < 3 && totalPage > activePage + 1)
      array.push(activePage + 2);
    if (array.length < 3 && activePage > 2) array.unshift(activePage - 2);
    setArrrayPage(array);
  }, [totalPage, activePage]);

  useEffect(() => {
    let newData = [];
    if (dataResumeMedis.length > 0) {
      dataResumeMedis.forEach((el) => {
        let flag = true;
        let regexp = new RegExp(searchName, "gi");
        if (searchName !== "" && el.employee.name.match(regexp) === null) {
          flag = false;
        } else if (
          selectedEvent !== "Semua" &&
          el.groupEvent !== selectedEvent
        ) {
          flag = false;
        } else if (
          selectedDivisi !== "Semua Divisi" &&
          el.employee.department !== selectedDivisi
        ) {
          flag = false;
        } else if (
          selectedStatus !== "Semua Status Bayar" &&
          el.service.statusBayar !== selectedStatus
        ) {
          flag = false;
        } else if (
          selectedPembayaran !== "Semua Pembayaran" &&
          el.service.paymentMethod !== selectedPembayaran
        ) {
          flag = false;
        } else if (
          selectedFaskes !== "Semua Faskes" &&
          el.clinic.name !== selectedFaskes
        ) {
          flag = false;
        }
        if (flag) {
          newData.push(el);
        }
      });
      if (sortType === "ASC") {
        if (sortBy === "name" || sortBy === "department"){
          newData.sort((a, b) => a.employee[sortBy].localeCompare(b.employee[sortBy]));
        }
        if(sortBy === "date" || sortBy === "paymentMethod"){
          newData.sort((a, b) => a.service[sortBy].localeCompare(b.service[sortBy]));
        }
        if(sortBy === "groupEvent"){
          newData.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
        }
        if(sortBy === "faskes"){
          newData.sort((a, b) => a.clinic.name.localeCompare(b.clinic.name));
        }
      } else if (sortType === "DESC") {
        if (sortBy === "name" || sortBy === "department"){
          newData.sort((a, b) => b.employee[sortBy].localeCompare(a.employee[sortBy]));
        }
        if(sortBy === "date" || sortBy === "paymentMethod"){
          newData.sort((a, b) => b.service[sortBy].localeCompare(a.service[sortBy]));
        }
        if(sortBy === "groupEvent"){
          newData.sort((a, b) => b[sortBy].localeCompare(a[sortBy]));
        }
        if(sortBy === "faskes"){
          newData.sort((a, b) => b.clinic.name.localeCompare(a.clinic.name));
        }
      }
    }
    setData(newData);
    setActivePage(1);
    // eslint-disable-next-line
  }, [
    dataResumeMedis,
    searchName,
    selectedEvent,
    selectedDivisi,
    selectedPembayaran,
    selectedStatus,
    selectedFaskes,
    sortType,
    sortBy,
  ]);

  useEffect(() => {
    if(selectedRange.startDate){
      let StartDate = selectedRange.startDate;
      let EndDate = selectedRange.endDate;
      dispatch(getResumeMedisByDate(StartDate, EndDate));
    }
    // eslint-disable-next-line
  }, [selectedRange]);

  //filter
  const selectFilter = (filtername, filter) => {
    switch (filtername) {
      case "divisi":
        showDivisiModal(false);
        setSelectedDivisi(filter);
        break;

      case "pembayaran":
        showPembayaranModal(false);
        setSelectedPembayaran(filter);
        break;

      case "status":
        showStatusModal(false);
        setSelectedStatus(filter);
        break;

      case "faskes":
        showFaskesModal(false);
        setSelectedFaskes(filter);
        break;

      case "tanggal":
        showTanggalModal(false);
        setSelectedTanggal(filter);
        break;

      default:
        break;
    }
  };

  const sortHandle = async (field) => {
    if (sortBy !== field) {
      setSortBy(field);
    }
    if (sortBy === field) {
      sortType === "ASC" ? await setSortType("DESC") : await setSortType("ASC");
    } else {
      await setSortType("ASC");
    }
  };

  const closeModal = () => {
    showPembayaranModal(false);
    showStatusModal(false);
    showFaskesModal(false);
    showTanggalModal(false);
    showDivisiModal(false)
  }

  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "sehat":
        return "#2F8B08";
        
      case "tidak sehat":
        return "#D22828";

      case "sehat dengan catatan":
        return "#BF9A16";
  
      default:
        return "#2F8B08";
    }
  };

  return (
    <div className="bgcontent">
      <div className="karyawan">
        <div className="header">Hasil Pemeriksaan</div>
        <div className="row">
          <Search
            searchName={searchName}
            setSearchName={setSearchName}
            placeholder={"Pencarian"}
          />
          <div className="event">
            {event?.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className="eventname"
                  style={{
                    color: selectedEvent === el ? "#109CF1" : "#8891A0",
                    borderWidth: selectedEvent === el ? "1.5px" : "1px",
                    borderColor: selectedEvent === el ? "#109CF1" : "#8891A0",
                  }}
                  onClick={() => setSelectedEvent(el)}
                >
                  {el}
                </div>
              );
            })}
          </div>
          {/* <div className="button" onClick={() => navigate('/event/create')}>Tambah Event</div> */}
        </div>
        <div className="filter">
          <div className="row">
            <Filter
              filterName={"divisi"}
              modal={divisiModal}
              showModal={() => {
                showPembayaranModal(false);
                showStatusModal(false);
                showFaskesModal(false);
                showTanggalModal(false);
                divisiModal ? showDivisiModal(false) : showDivisiModal(true);
              }}
              item={divisi}
              selectedItem={selectedDivisi}
              selectFilter={selectFilter}
              first={true}
              closeModal={closeModal}
            />
            <Filter
              filterName={"status"}
              modal={statusModal}
              showModal={() => {
                showPembayaranModal(false);
                showDivisiModal(false);
                showFaskesModal(false);
                showTanggalModal(false);
                statusModal ? showStatusModal(false) : showStatusModal(true);
              }}
              item={status}
              selectedItem={selectedStatus}
              selectFilter={selectFilter}
              closeModal={closeModal}
            />
            <Filter
              filterName={"pembayaran"}
              modal={pembayaranModal}
              showModal={() => {
                showStatusModal(false);
                showDivisiModal(false);
                showFaskesModal(false);
                showTanggalModal(false);
                pembayaranModal
                  ? showPembayaranModal(false)
                  : showPembayaranModal(true);
              }}
              item={pembayaran}
              selectedItem={selectedPembayaran}
              selectFilter={selectFilter}
              closeModal={closeModal}
            />
            <FilterCalender
              modal={tanggalModal}
              selectedItem={selectedTanggal}
              setSelectedTanggal={setSelectedTanggal}
              setSelectedRange={setSelectedRange}
              showModal={() => {
                showDivisiModal(false);
                showPembayaranModal(false);
                showFaskesModal(false);
                showStatusModal(false);
                tanggalModal ? showTanggalModal(false) : showTanggalModal(true);
              }}
              closeModal={closeModal}
            />
            <Filter
              filterName={"faskes"}
              modal={faskesModal}
              showModal={() => {
                showDivisiModal(false);
                showPembayaranModal(false);
                showTanggalModal(false);
                showStatusModal(false);
                faskesModal ? showFaskesModal(false) : showFaskesModal(true);
              }}
              item={faskes}
              selectedItem={selectedFaskes}
              selectFilter={selectFilter}
              closeModal={closeModal}
            />
            <div
              className="reset"
              onClick={() => {
                setSearchName("");
                setSelectedDivisi("Semua Divisi");
                setSelectedPembayaran("Semua Pembayaran");
                setSelectedFaskes("Semua Faskes");
                setSelectedStatus("Semua Status Bayar");
                const lastMonth = getLastOneMonth();
                setSelectedTanggal(formatSimpleDate(lastMonth.startDate) + " - " + formatSimpleDate(lastMonth.endDate))
                setSelectedRange(getLastOneMonth());
                showDivisiModal(false);
                showPembayaranModal(false);
                showTanggalModal(false);
                showStatusModal(false);
                showFaskesModal(false);
              }}
            >
              RESET FILTER
            </div>
          </div>
        </div>
        <table>
          <thead className="tablehead">
            <tr>
            <td className="columnnama">
              <div className="columntitle" onClick={() => sortHandle("name")}>
                <div style={{fontWeight: "bold"}}>NAMA</div>
                <div className="sort">
                  <IcPolygonUp
                    active={sortBy === "name" && sortType === "ASC"}
                  />
                  <IcPolygonDown
                    active={sortBy === "name" && sortType === "DESC"}
                  />
                </div>
              </div>
            </td>
            <td className="column">
              <div
                className="columntitle"
                onClick={() => sortHandle("department")}
              >
                <div style={{fontWeight: "bold"}}>DIVISI</div>
                <div className="sort">
                  <IcPolygonUp
                    active={sortBy === "department" && sortType === "ASC"}
                  />
                  <IcPolygonDown
                    active={sortBy === "department" && sortType === "DESC"}
                  />
                </div>
              </div>
            </td>
            <td className="column">
              <div
                className="columntitle"
                onClick={() => sortHandle("date")}
              >
                <div style={{fontWeight: "bold"}}>TGL PERIKSA</div>
                <div className="sort">
                  <IcPolygonUp
                    active={sortBy === "date" && sortType === "ASC"}
                  />
                  <IcPolygonDown
                    active={sortBy === "date" && sortType === "DESC"}
                  />
                </div>
              </div>
            </td>
            <td className="column">
              <div
                className="columntitle"
                onClick={() => sortHandle("paymentMethod")}
              >
                <div style={{fontWeight: "bold"}}>PEMBAYARAN</div>
                <div className="sort">
                  <IcPolygonUp
                    active={sortBy === "paymentMethod" && sortType === "ASC"}
                  />
                  <IcPolygonDown
                    active={sortBy === "paymentMethod" && sortType === "DESC"}
                  />
                </div>
              </div>
            </td>
            <td className="column">
              <div
                className="columntitle"
                onClick={() => sortHandle("groupEvent")}
              >
                <div style={{fontWeight: "bold"}}>PEMERIKSAAN</div>
                <div className="sort">
                  <IcPolygonUp
                    active={sortBy === "groupEvent" && sortType === "ASC"}
                  />
                  <IcPolygonDown
                    active={sortBy === "groupEvent" && sortType === "DESC"}
                  />
                </div>
              </div>
            </td>
            <td className="column">
              <div
                className="columntitle"
                onClick={() => sortHandle("faskes")}
              >
                <div style={{fontWeight: "bold"}}>FASKES</div>
                <div className="sort">
                  <IcPolygonUp
                    active={sortBy === "faskes" && sortType === "ASC"}
                  />
                  <IcPolygonDown
                    active={sortBy === "faskes" && sortType === "DESC"}
                  />
                </div>
              </div>
            </td>
            <td className="columnaksi" style={{fontWeight: "bold"}}>HASIL</td>
            </tr>
          </thead>
          <tbody>
            {dataRender.length > 0 &&
              dataRender.map((el) => {
                return (
                  <tr className="tablebody" key={el._id}>
                    <th className="columnbodynama">
                      {el.employee.name}
                    </th>
                    <th className="columnbody">{el.employee.department}</th>
                    <th className="columnbody">
                      {formatDate(el.service.date)}
                    </th>
                    <th className="columnbody">{el.service.paymentMethod}</th>
                    <th className="columnbody">{el.groupEvent}</th>
                    <th className="columnbody">{el.clinic.name}</th>
                    <th className="columnbodyaksi">
                      {el.result?.swab ? (
                        <>
                          {el.result.swab === "Positif" ? (
                            <div className="row" style={{ cursor: "pointer" }} onClick={() => navigate(`/result/${el.employee.employeeIdMobile}`, {state: {resultId: el._id}})}>
                              <div>
                                <AntigenPositif /> 
                              </div>
                              <span style={{marginLeft: "5px"}}>Positif</span>
                            </div>
                          ) : (
                            <div className="row" style={{ cursor: "pointer" }} onClick={() => navigate(`/result/${el.employee.employeeIdMobile}`, {state: {resultId: el._id}})}>
                              <div>
                                <AntigenNegatif /> 
                              </div>
                              <span style={{marginLeft: "5px"}}>Negatif</span>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="row" style={{ cursor: "pointer" }} onClick={() => navigate(`/result/${el.employee._id}`, {state: {resultId: el._id}})}>
                          {/* <div>
                            <IcDetail />
                          </div> */}
                          <span style={{color: el.result?.conclusion ? getColor(el.result.conclusion) : ""}}>{el.result.conclusion || "Lihat Hasil"}</span>
                        </div>
                      )}
                    </th>
                  </tr>
                );
              })}
          </tbody>
          <tfoot className="tablefooter">
            <tr className="row">
              <td>
              <button
                className="pagination"
                onClick={() => setActivePage(activePage - 1)}
                disabled={activePage === 1}
              >
                {"<"}
              </button>
              </td>
              {totalPage > 0 &&
                arrayPage.map((el, idx) => {
                  return (
                    <td key={idx}>
                    <div
                      className="pagination"
                      style={{
                        backgroundColor:
                          activePage === el ? "#558EFF" : "transparent",
                        color: activePage === el ? "#FFFFFF" : "#25282B",
                      }}
                      onClick={() => setActivePage(el)}
                    >
                      {el}
                    </div>
                    </td>
                  );
                })}
              <td>
              <button
                className="pagination"
                onClick={() => setActivePage(activePage + 1)}
                disabled={activePage >= totalPage}
              >
                {">"}
              </button>
              </td>
            </tr>
          </tfoot>
        </table>
        
      </div>
    </div>
  );
}

export default HasilPemeriksaan;
