import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/login.css";

import IcGembok from "../../asset/svg/gembokSukses"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import { createPassword, verifyToken } from "../../store/actions";
import { setSuccessCreatePassword } from "../../store/actions/corpAction";


function CreatePassword() {
  const { token } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { errorMessage, successMessage } = useSelector(state => state.messageState)
  const { isSuccessCreatePassword } = useSelector(state => state.userState)
  
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  useEffect(() => {
    if(token){
      dispatch(verifyToken(token))
    } else {
      navigate('/reset-password')
    }
    // eslint-disable-next-line
  },[token])

  useEffect(() => {
    if(errorMessage){
      navigate('/reset-password')
    }
    // eslint-disable-next-line
  },[errorMessage, successMessage])

  useEffect(() => {
    if(isSuccessCreatePassword){
      dispatch(setSuccessCreatePassword(undefined))
      navigate('/login')
    }
    // eslint-disable-next-line
  },[isSuccessCreatePassword])

  const submitHandle = () => {
    if (password !== confirmPassword) {
      toast("Konfirmasi Password Salah")
    } else if (password === confirmPassword && password.length < 5) {
      toast("Panjang password minimal 5 karakter")
    } else {
      dispatch(createPassword(token, password))
    }
  }

  return (
    <div className="background">
      <ToastContainer />
      <div className="card">
        <div className="content">
          <div className="header">
            <IcGembok />
          </div>
          <div className="header">Berhasil !</div>
          <div className="textContent">Masukkan password baru Anda</div>
          <div className="inputBox">
            <input type="password" placeholder="Password Baru"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div className="inputBox">
            <input type="password" placeholder="Konfirmasi Password Baru"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
          </div>

          <div className="buttonSubmit" onClick={() => submitHandle()}>Simpan</div>
          
        </div>
      </div>
    </div>
  );
}

export default CreatePassword;
