import React from "react";

const Clinic = ({active}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1249 12.875L15.8749 2.875C15.3749 2.5 14.7499 2.5 14.2499 2.875L2.99994 12.875C2.49994 13.375 2.37494 14.125 2.87494 14.625C3.37494 15.125 4.12494 15.25 4.62494 14.75L4.99994 14.25V26.25C4.99994 27 5.49994 27.5 6.24994 27.5H23.7499C24.4999 27.5 24.9999 27 24.9999 26.25V14.25L25.3749 14.625C25.8749 15.125 26.6249 15 27.1249 14.5C27.6249 14.125 27.6249 13.25 27.1249 12.875ZM17.4999 18.75H16.2499V20C16.2499 20.75 15.7499 21.25 14.9999 21.25C14.2499 21.25 13.7499 20.75 13.7499 20V18.75H12.4999C11.7499 18.75 11.2499 18.25 11.2499 17.5C11.2499 16.75 11.7499 16.25 12.4999 16.25H13.7499V15C13.7499 14.25 14.2499 13.75 14.9999 13.75C15.7499 13.75 16.2499 14.25 16.2499 15V16.25H17.4999C18.2499 16.25 18.7499 16.75 18.7499 17.5C18.7499 18.25 18.2499 18.75 17.4999 18.75Z"
        fill={ active ? "#558EFF" : "#8094AE" }
      />
    </svg>
  );
};
export default Clinic;
