import React from "react";

const UserSearch = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_110_3839)">
        <path
          d="M11.5462 12.9242V20.2576H4.21289C4.21289 18.3126 4.98551 16.4474 6.36077 15.0721C7.73604 13.6969 9.6013 12.9242 11.5462 12.9242ZM11.5462 12.0076C8.50747 12.0076 6.04622 9.54632 6.04622 6.50757C6.04622 3.46882 8.50747 1.00757 11.5462 1.00757C14.585 1.00757 17.0462 3.46882 17.0462 6.50757C17.0462 9.54632 14.585 12.0076 11.5462 12.0076ZM20.2051 18.4536L21.5837 19.8322L20.2876 21.1284L18.9089 19.7497C18.1401 20.203 17.2324 20.3604 16.3559 20.1923C15.4795 20.0242 14.6944 19.5422 14.1478 18.8367C13.6013 18.1311 13.3309 17.2505 13.3872 16.3598C13.4435 15.4691 13.8228 14.6296 14.4538 13.9985C15.0849 13.3674 15.9244 12.9882 16.8151 12.9319C17.7058 12.8755 18.5864 13.146 19.292 13.6925C19.9975 14.239 20.4795 15.0241 20.6476 15.9006C20.8157 16.7771 20.6583 17.6848 20.2051 18.4536ZM17.0462 18.4242C17.5325 18.4242 17.9988 18.2311 18.3426 17.8873C18.6864 17.5434 18.8796 17.0771 18.8796 16.5909C18.8796 16.1047 18.6864 15.6384 18.3426 15.2945C17.9988 14.9507 17.5325 14.7576 17.0462 14.7576C16.56 14.7576 16.0937 14.9507 15.7499 15.2945C15.406 15.6384 15.2129 16.1047 15.2129 16.5909C15.2129 17.0771 15.406 17.5434 15.7499 17.8873C16.0937 18.2311 16.56 18.4242 17.0462 18.4242Z"
          fill="#8094AE"
        />
      </g>
      <defs>
        <clipPath id="clip0_110_3839">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.544922 0.0908203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UserSearch;
