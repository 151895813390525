import React from "react";

const IcDirection = ({size}) => {
  return (
    <svg
      width={size || "10"}
      height={size || "16"}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2858 7.99984C9.2858 8.28149 9.1829 8.5631 8.97752 8.77784L2.51157 15.535C2.10025 15.9649 1.43338 15.9649 1.02223 15.535C0.61108 15.1054 0.61108 14.4086 1.02223 13.9787L6.74366 7.99984L1.02243 2.02095C0.611279 1.5911 0.611279 0.894391 1.02243 0.464753C1.43358 0.0346983 2.10045 0.0346983 2.51177 0.464753L8.97772 7.22185C9.18313 7.43668 9.2858 7.7183 9.2858 7.99984Z"
        fill="#6A6A6A"
      />
    </svg>
  );
};
export default IcDirection;
