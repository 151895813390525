const initialState = {
    loading: false,
    errorMessage: '',
    successMessage: '',
    closeAllModal: false,
    modalClicked: false
}

export default function reducer(state = initialState, action){
    switch(action.type){
        case "SET_LOADING":
            return {...state, loading: action.payload}
        case "SET_ERRORMESSAGE":
            return {...state, errorMessage: action.payload}
        case "SET_SUCCESSMESSAGE":
            return {...state, successMessage: action.payload}
        case "SET_CLOSEALLMODAL":
            return {...state, closeAllModal: action.payload}
        case "SET_MODALCLICKED":
            return {...state, modalClicked: action.payload}
        default: 
            return state
    }
}