import React, { useEffect, useState, useRef } from "react";
import "../../styles/home.css";
import Chart from "react-google-charts";

import IcGlobe from "../../asset/svg/globe";
import IcTrendingUp from "../../asset/svg/trendingUp";
import IcTrendingDown from "../../asset/svg/trendingDown";
import IcDirection from "../../asset/svg/ic_direction";
import IcThreeDot from "../../asset/svg/threedot";
import Medqcare from "../../asset/svg/medqcare";
import { useDispatch, useSelector } from "react-redux";
import { getDataDashboard } from "../../store/actions";
import formatSimpleDate from "../../helpers/formatSimpleDate";
import { useNavigate } from "react-router-dom";
import toTitleCase from "../../helpers/toTitleCase";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);

  const { dataDashboard, rangeDashboardData } = useSelector((state) => state.userState);
  const [jenisPenyakit, setJenisPenyakit] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [lastActivity, setLastActivity] = useState([]);
  const [event, setEvent] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [showScroll, setShowScroll] = useState(false);
  const colorChart = [
    "#D28CEB",
    "#73BCFF",
    "#FF8373",
    "#AC18F2",
    "#73BCFF",
    "#F9DB7B",
  ];

  const getPercentage = (value, item) => {
    let total = 0;
    item.forEach((el) => {
      if (typeof el[1] === "number") {
        total += el[1];
      }
    });
    return ((value / total) * 100).toFixed(2);
  };

  useEffect(() => {
    if (rangeDashboardData.startDate && rangeDashboardData.endDate) {
      dispatch(getDataDashboard(rangeDashboardData.startDate, rangeDashboardData.endDate))
    }
    // eslint-disable-next-line
  }, [rangeDashboardData])

  useEffect(() => {
    if (dataDashboard.jenisPenyakit) {
      let allPenyakit = [["item", "percent"]];
      Object.keys(dataDashboard.jenisPenyakit).forEach((el) => {
        if(el !== "Lainnya"){
          allPenyakit.push([el, dataDashboard.jenisPenyakit[el]]);
        }
      });
      allPenyakit.sort((a, b) => b[1] - a[1]);
      if (allPenyakit.length > 5) {
        let totalLainnya = 0;
        if(dataDashboard.jenisPenyakit["Lainnya"]) {
          totalLainnya += dataDashboard.jenisPenyakit["Lainnya"]
        }
        for (let i = 4; i < allPenyakit.length; i++) {
          totalLainnya += allPenyakit[i][1];
        }
        allPenyakit = allPenyakit.splice(0, 4);
        allPenyakit[4] = ["Lainnya", totalLainnya];
      } else {
        if(dataDashboard.jenisPenyakit["Lainnya"]) {
          allPenyakit.push(["Lainnya", dataDashboard.jenisPenyakit["Lainnya"]])
        }
      }
      setJenisPenyakit(allPenyakit);
    }
    if (dataDashboard.paymentMethod) {
      let allPayment = [["item", "percent"]];
      Object.keys(dataDashboard.paymentMethod).forEach((el) => {
        allPayment.push([el, dataDashboard.paymentMethod[el]]);
      });
      allPayment.sort((a, b) => b[1] - a[1]);
      if (allPayment.length > 5) {
        let totalLainnya = 0;
        for (let i = 4; i < allPayment.length; i++) {
          totalLainnya += allPayment[i][1];
        }
        allPayment = allPayment.splice(0, 4);
        allPayment[4] = ["lainnya", totalLainnya];
      }
      setPaymentMethod(allPayment);
    }
    if (dataDashboard.lastActivity) {
      setLastActivity(dataDashboard.lastActivity);
    }
    if (dataDashboard.transaction) {
      setEvent(Object.keys(dataDashboard.transaction));

      let allEvent = [["x"].concat(Object.keys(dataDashboard.transaction))];
      let listEvent = [];
      let dateEvent = [];

      for (let i = 1; i < allEvent[0].length; i++) {
        const event = allEvent[0][i];
        let result = {};
        for (let j = 0; j < dataDashboard.transaction[event].length; j++) {
          const item = dataDashboard.transaction[event][j];
          if (!dateEvent.includes(formatSimpleDate(item.date))) {
            dateEvent.push(formatSimpleDate(item.date));
          }
          if (!result[formatSimpleDate(item.date)]) {
            result[formatSimpleDate(item.date)] = 0;
          }
          result[formatSimpleDate(item.date)] += item.tagihan / 1000;
        }
        listEvent[i - 1] = result;
      }

      dateEvent = dateEvent.sort((a, b) => new Date(a) - new Date(b));

      for (let i = 0; i < dateEvent.length; i++) {
        let arrayResult = [];
        arrayResult.push(dateEvent[i]);
        for (let j = 0; j < listEvent.length; j++) {
          if (!listEvent[j][dateEvent[i]]) {
            arrayResult.push(0);
          } else {
            arrayResult.push(listEvent[j][dateEvent[i]]);
          }
        }
        allEvent.push(arrayResult);
      }

      setChartData(allEvent);
    }
    // eslint-disable-next-line
  }, [dataDashboard]);

  useEffect(() => {
    let box = document.querySelector("#event");
    let width = box.offsetWidth;
    if (width >= 300) {
      setShowScroll(true);
    }
    // eslint-disable-next-line
  }, [event]);

  const scroll = (scrollOffset) => {
    if (scrollOffset > 0) {
      setTimeout(() => {
        ref.current.scrollLeft += 10;
        scroll(scrollOffset - 10);
      }, 20);
    } else if (scrollOffset < 0) {
      setTimeout(() => {
        ref.current.scrollLeft -= 10;
        scroll(scrollOffset + 10);
      }, 20);
    }
  };

  const getPercent = (before, after) => {
    if(before === 0){
      return 100
    } else if (after - before < 0){
      return ((before - after) / before * 100).toFixed(2)
    } else {
      return after / before * 100
    }
  }

  return (
    <div className="bgcontent">
      <div className="home">
        <div className="row jbetween">
          <div>
            <div className="row jbetween sectionone">
              <div className="child row jbetween">
                <div>
                  <label className="childtitle">Total Positif Covid 19</label>
                  <div className="row mt">
                    <div className="count">{dataDashboard.positifCovid?.recent}</div>
                    {
                      dataDashboard.positifCovid?.lastMonth >= dataDashboard.positifCovid?.recent ? (
                        <div className="trendingDown">
                          <IcTrendingDown />
                          <label className="percentDown">{`${getPercent(dataDashboard.positifCovid?.lastMonth, dataDashboard.positifCovid?.recent)}%`}</label>
                        </div>
                      ) : (
                        <div className="trendingUp">
                          <IcTrendingUp />
                          <label className="percentUp">{`${getPercent(dataDashboard.positifCovid?.lastMonth, dataDashboard.positifCovid?.recent)}%`}</label>
                        </div>
                      )
                    }
                  </div>
                </div>
                <IcGlobe />
              </div>
              <div className="child row jbetween">
                <div>
                  <label className="childtitle">Karyawan Sakit</label>
                  <div className="row mt">
                    <div className="count">
                      {dataDashboard.sickPeople}
                    </div>
                    {
                      dataDashboard.lastMonthSickPeople >= dataDashboard.sickPeople ? (
                        <div className="trendingDown">
                          <IcTrendingDown />
                          <label className="percentDown">{`${getPercent(dataDashboard.lastMonthSickPeople, dataDashboard.sickPeople)}%`}</label>
                        </div>
                      ) : (
                        <div className="trendingUp">
                          <IcTrendingUp />
                          <label className="percentUp">{`${getPercent(dataDashboard.lastMonthSickPeople, dataDashboard.sickPeople)}%`}</label>
                        </div>
                      )
                    }
                  </div>
                </div>
                <IcGlobe />
              </div>
              <div className="child row jbetween">
                <div>
                  <label className="childtitle">Karyawan Dirujuk</label>
                  <div className="row mt">
                    <div className="count">0</div>
                    <div className="trendingDown">
                      <IcTrendingDown />
                      <label className="percentDown">{"0%"}</label>
                    </div>
                  </div>
                </div>
                <IcGlobe />
              </div>
            </div>
            <div className="graph">
              <div
                className="row jbetween"
                style={{
                  paddingTop: "15px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  width: "690px",
                }}
              >
                <div style={{ fontSize: "14px", color: "#829BBB" }}>
                  Biaya Pemeriksaan {event.length > 0 ? "(Rp x 1.000)" : ""}
                </div>
                <div
                  className="modalPeserta-filterArea"
                  style={{ width: "50%", marginTop: "0px", justifyContent: "flex-end" }}
                >
                  {showScroll && (
                    <div
                      onClick={() => scroll(-70)}
                      className="buttonFilterLeftSmall"
                    >
                      <IcDirection size={10} />
                    </div>
                  )}
                  <div
                    className="modalPeserta-filter"
                    style={{ transform: showScroll ? "translateX(-40px)" : "" }}
                  >
                    <div className="filter-body" ref={ref} id="event">
                      {event.length > 0 &&
                        event.map((el, idx) => {
                          return (
                            <div
                              className="row"
                              key={idx}
                              style={{
                                maxHeight: "20px",
                                minWidth: "fit-content",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: colorChart[idx],
                                  paddingLeft: "15px",
                                }}
                              >
                                {el}
                              </div>
                              <div
                                style={{
                                  minWidth: "30px",
                                  backgroundColor: colorChart[idx],
                                  marginLeft: "10px",
                                  borderRadius: "4px",
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {showScroll && (
                    <div
                      onClick={() => scroll(70)}
                      className="buttonFilterRightSmall"
                    >
                      <IcDirection size={10} />
                    </div>
                  )}
                </div>
              </div>
              {chartData.length > 1 ? (
                <div style={{ paddingLeft: "20px" }}>
                  <Chart
                    width={"720px"}
                    height={"300px"}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={chartData}
                    options={{
                      legend: "none",
                      chartArea: { width: "82%", height: "70%" },
                      colors: colorChart,
                      fontSize: "5px",
                      // vAxis: {
                      //   title: "Biaya (Rp x 1.000)",
                      // }
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div style={{ padding: "100px" }}>
                    <Medqcare />
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        color: "#c6cbcf",
                        marginTop: "20px",
                      }}
                    >
                      Tidak ada data tersedia
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="activitytitle">
              <div className="lefttext">Aktifitas terakhir</div>
              <div className="selengkapnya" onClick={() => navigate("/event")}>
                <div className="righttext mr5">Selengkapnya</div>
                <IcThreeDot />
              </div>
            </div>
            <div className="activity">
              <table>
                <thead className="tablehead">
                  <tr>
                    <th className="column">
                      <div className="columntitle">
                        <div>Nama Event</div>
                      </div>
                    </th>
                    <th className="column">
                      <div className="columntitle">
                        <div>Tanggal Event</div>
                        {/* <div className="sort">
                          <IcPolygonUp />
                          <IcPolygonDown />
                        </div> */}
                      </div>
                    </th>
                    <th className="column">
                      <div className="columntitle">
                        <div>Faskes</div>
                        {/* <div className="sort">
                          <IcPolygonUp />
                          <IcPolygonDown />
                        </div> */}
                      </div>
                    </th>
                    <th className="column">
                      <div className="columntitle">
                        <div>Total Peserta</div>
                      </div>
                    </th>
                    <th className="column">
                      <div className="columntitle">
                        <div>Tagihan</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lastActivity.map((el, idx) => {
                    return (
                      <tr className="tablebody" key={idx}>
                        {/* <th className="columnbodycheck">
                          <input type={"checkbox"} />
                        </th>
                        <th className="columnbodyno">
                          {idx + 1 < 10 ? "0" + (idx + 1).toString() : idx + 1}
                        </th> */}
                        <th className="columnbody">{el.activity}</th>
                        <th className="columnbody">
                          {formatSimpleDate(el.date)}
                        </th>
                        <th className="columnbody">{el.faskesName}</th>
                        <th className="columnbody">{el.totalParticipant}</th>
                        <th className="columnbody">{`Rp ${el.totalTagihan.toLocaleString(
                          "id-ID"
                        )}`}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="sectiontwo">
            <div className="chart">
              <div className="grouptitle">
                <div className="title">Penyakit Teratas</div>
                {/* <div className="row divisi">
                  <div className="mr5">Semua Divisi</div>
                  <div style={{ marginTop: "-2px" }}>
                    <ICDropdown />
                  </div>
                </div> */}
              </div>
              {jenisPenyakit.length > 1 ? (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Chart
                    width={"150px"}
                    height={"150px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={jenisPenyakit}
                    options={{
                      legend: "none",
                      pieHole: 0.4,
                      chartArea: { width: "100%", height: "100%" },
                      colors: ["#FF8373", "#AC18F2", "#73BCFF", "#F9DB7B"],
                    }}
                    rootProps={{ "data-testid": "3" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div style={{ padding: "100px" }}>
                    <Medqcare />
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#c6cbcf",
                        marginTop: "20px",
                      }}
                    >
                      Tidak ada data tersedia
                    </div>
                  </div>
                </div>
              )}

              <div>
                <div
                  className="row jbetween"
                  style={{ width: "220px", marginTop: "15px" }}
                >
                  {jenisPenyakit[1] && (
                    <div>
                      <div className="row">
                        <div className="buttonOrange" />
                        <div className="option">{toTitleCase(jenisPenyakit[1]?.[0])}</div>
                      </div>
                      <div
                        style={{ marginLeft: "25px", marginTop: "5px" }}
                        className="row"
                      >
                        <div style={{ fontSize: "18px" }}>
                          {jenisPenyakit[1]?.[1]}
                        </div>
                        <div
                          style={{
                            marginLeft: "5px",
                            marginTop: "5px",
                            fontSize: "13px",
                            color: "#6B6B6C",
                          }}
                        >
                          {getPercentage(jenisPenyakit[1][1], jenisPenyakit)}%
                        </div>
                      </div>
                    </div>
                  )}
                  {jenisPenyakit[2] && (
                    <div>
                      <div className="row">
                        <div className="buttonPurple" />
                        <div className="option">{toTitleCase(jenisPenyakit[2]?.[0])}</div>
                      </div>
                      <div
                        style={{ marginLeft: "25px", marginTop: "5px" }}
                        className="row"
                      >
                        <div style={{ fontSize: "18px" }}>
                          {jenisPenyakit[2][1]}
                        </div>
                        <div
                          style={{
                            marginLeft: "5px",
                            marginTop: "5px",
                            fontSize: "13px",
                            color: "#6B6B6C",
                          }}
                        >
                          {getPercentage(jenisPenyakit[2][1], jenisPenyakit)}%
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="row jbetween"
                  style={{ width: "220px", marginTop: "15px" }}
                >
                  {jenisPenyakit[3] && (
                    <div>
                      <div className="row">
                        <div className="buttonblue" />
                        <div className="option">{toTitleCase(jenisPenyakit[3][0])}</div>
                      </div>
                      <div
                        style={{ marginLeft: "25px", marginTop: "5px" }}
                        className="row"
                      >
                        <div style={{ fontSize: "18px" }}>
                          {jenisPenyakit[3][1]}
                        </div>
                        <div
                          style={{
                            marginLeft: "5px",
                            marginTop: "5px",
                            fontSize: "13px",
                            color: "#6B6B6C",
                          }}
                        >
                          {getPercentage(jenisPenyakit[3][1], jenisPenyakit)}%
                        </div>
                      </div>
                    </div>
                  )}
                  {jenisPenyakit[4] && (
                    <div>
                      <div className="row">
                        <div className="buttonYellow" />
                        <div className="option">{toTitleCase(jenisPenyakit[4][0])}</div>
                      </div>
                      <div
                        style={{ marginLeft: "25px", marginTop: "5px" }}
                        className="row"
                      >
                        <div style={{ fontSize: "18px" }}>
                          {jenisPenyakit[4][1]}
                        </div>
                        <div
                          style={{
                            marginLeft: "5px",
                            marginTop: "5px",
                            fontSize: "13px",
                            color: "#6B6B6C",
                          }}
                        >
                          {getPercentage(jenisPenyakit[4][1], jenisPenyakit)}%
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="chart">
              <div className="grouptitle">
                <div className="title">Jenis Pembayaran</div>
                {/* <div className="row divisi">
                  <div className="mr5">Semua Divisi</div>
                  <div style={{ marginTop: "-2px" }}>
                    <ICDropdown />
                  </div>
                </div> */}
              </div>
              {paymentMethod.length > 1 ? (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Chart
                    width={"150px"}
                    height={"150px"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={paymentMethod}
                    options={{
                      legend: "none",
                      pieHole: 0.4,
                      chartArea: { width: "100%", height: "100%" },
                      colors: ["#706CF0", "#4BCE88", "#AEF06C", "#F9DB7B"],
                    }}
                    rootProps={{ "data-testid": "3" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div style={{ padding: "100px" }}>
                    <Medqcare />
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#c6cbcf",
                        marginTop: "20px",
                      }}
                    >
                      Tidak ada data tersedia
                    </div>
                  </div>
                </div>
              )}

              <div>
                {paymentMethod[1] && (
                  <div className="type">
                    <div className="row">
                      <div className="buttonDarkBlue" />
                      <div className="option">{paymentMethod[1][0].charAt(0).toUpperCase() + paymentMethod[1][0].slice(1)}</div>
                    </div>
                    <div className="value">
                      {getPercentage(paymentMethod[1][1], paymentMethod)}%
                    </div>
                  </div>
                )}
                {paymentMethod[2] && (
                  <div className="type">
                    <div className="row">
                      <div className="buttonDarkGreen" />
                      <div className="option">{paymentMethod[2][0].charAt(0).toUpperCase() + paymentMethod[2][0].slice(1)}</div>
                    </div>
                    <div className="value">
                      {getPercentage(paymentMethod[2][1], paymentMethod)}%
                    </div>
                  </div>
                )}
                {paymentMethod[3] && (
                  <div className="type">
                    <div className="row">
                      <div className="buttonLightGreen" />
                      <div className="option">{paymentMethod[3][0].charAt(0).toUpperCase() + paymentMethod[3][0].slice(1)}</div>
                    </div>
                    <div className="value">
                      {getPercentage(paymentMethod[3][1], paymentMethod)}%
                    </div>
                  </div>
                )}
                {paymentMethod[4] && (
                  <div className="type">
                    <div className="row">
                      <div className="buttonYellow" />
                      <div className="option">{paymentMethod[4][0].charAt(0).toUpperCase() + paymentMethod[4][0].slice(1)}</div>
                    </div>
                    <div className="value">
                      {getPercentage(paymentMethod[4][1], paymentMethod)}%
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
