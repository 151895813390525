import React from "react";

const threedot = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#FF8373" />
      <path
        d="M10.3698 11.0187C10.7278 11.0187 11.018 10.7285 11.018 10.3706C11.018 10.0126 10.7278 9.72241 10.3698 9.72241C10.0119 9.72241 9.72168 10.0126 9.72168 10.3706C9.72168 10.7285 10.0119 11.0187 10.3698 11.0187Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9069 11.0187C15.2649 11.0187 15.5551 10.7285 15.5551 10.3706C15.5551 10.0126 15.2649 9.72241 14.9069 9.72241C14.549 9.72241 14.2588 10.0126 14.2588 10.3706C14.2588 10.7285 14.549 11.0187 14.9069 11.0187Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83272 11.0186C6.19068 11.0186 6.48087 10.7285 6.48087 10.3705C6.48087 10.0125 6.19068 9.72235 5.83272 9.72235C5.47476 9.72235 5.18457 10.0125 5.18457 10.3705C5.18457 10.7285 5.47476 11.0186 5.83272 11.0186Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default threedot;
