const initialState = {
    isLogin: false,
    rangeDashboardData: {
        startDate: "",
        endDate: ""
    },
    userData: {},
    dataDashboard: {},
    isSuccessCreatePassword: undefined
}

export default function reducer(state = initialState, action){
    switch(action.type){
        case "SET_ISLOGIN":
            return {...state, isLogin: action.payload}
        case "SET_DATADASHBOARD":
            return {...state, dataDashboard: action.payload}
        case "SET_RANGEDASHBOARDDATA":
            return {...state, rangeDashboardData: action.payload}
        case "SET_USERDATA":
            return {...state, userData: action.payload}
        case "SET_ISSUCCESSCREATEPASSWORD":
            return {...state, isSuccessCreatePassword: action.payload}

        default: 
            return state
    }
}