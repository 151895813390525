import React from "react";

const LogOut = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6306 9.41461C10.9217 9.41461 11.2009 9.29898 11.4067 9.09314C11.6125 8.88731 11.7282 8.60814 11.7282 8.31705C11.7282 8.02596 11.6125 7.74679 11.4067 7.54095C11.2009 7.33512 10.9217 7.21948 10.6306 7.21948H8.43546C8.14436 7.21948 7.86519 7.33512 7.65936 7.54095C7.45353 7.74679 7.33789 8.02596 7.33789 8.31705V23.683C7.33789 23.9741 7.45353 24.2532 7.65936 24.4591C7.86519 24.6649 8.14436 24.7805 8.43546 24.7805H10.6306C10.9217 24.7805 11.2009 24.6649 11.4067 24.4591C11.6125 24.2532 11.7282 23.9741 11.7282 23.683C11.7282 23.3919 11.6125 23.1127 11.4067 22.9069C11.2009 22.701 10.9217 22.5854 10.6306 22.5854H9.53302V9.41461H10.6306ZM25.799 15.3634L22.7038 10.9732C22.536 10.7365 22.2812 10.576 21.9954 10.5266C21.7095 10.4773 21.4157 10.5431 21.1782 10.7097C21.0595 10.7929 20.9584 10.8988 20.8809 11.0212C20.8033 11.1437 20.7508 11.2803 20.7263 11.4231C20.7018 11.566 20.7058 11.7123 20.7382 11.8536C20.7705 11.9949 20.8306 12.1284 20.9148 12.2463L22.8026 14.9024H13.9233C13.6322 14.9024 13.353 15.0181 13.1472 15.2239C12.9414 15.4297 12.8257 15.7089 12.8257 16C12.8257 16.2911 12.9414 16.5703 13.1472 16.7761C13.353 16.9819 13.6322 17.0976 13.9233 17.0976H22.7038L20.7282 19.7317C20.6417 19.847 20.5788 19.9783 20.543 20.1179C20.5072 20.2575 20.4993 20.4028 20.5197 20.5455C20.5401 20.6882 20.5884 20.8255 20.6618 20.9495C20.7353 21.0735 20.8324 21.1818 20.9477 21.2683C21.1377 21.4108 21.3688 21.4878 21.6062 21.4878C21.7766 21.4878 21.9447 21.4482 22.0971 21.372C22.2495 21.2958 22.3821 21.1851 22.4843 21.0488L25.777 16.6586C25.9176 16.4729 25.9955 16.2474 25.9994 16.0146C26.0033 15.7818 25.9331 15.5537 25.799 15.3634Z"
        fill="#F45959"
      />
    </svg>
  );
};
export default LogOut;
