import React from "react";

const User = ({ active }) => {
  return (
    <svg
      width="98"
      height="98"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <path
        d="M23 21.75C19.83 21.75 17.25 19.17 17.25 16C17.25 12.83 19.83 10.25 23 10.25C26.17 10.25 28.75 12.83 28.75 16C28.75 19.17 26.17 21.75 23 21.75ZM23 11.75C20.66 11.75 18.75 13.66 18.75 16C18.75 18.34 20.66 20.25 23 20.25C25.34 20.25 27.25 18.34 27.25 16C27.25 13.66 25.34 11.75 23 11.75Z"
        fill="#558EFF"
      />
      <path
        d="M31.5901 31.75C31.1801 31.75 30.8401 31.41 30.8401 31C30.8401 27.55 27.3202 24.75 23.0002 24.75C18.6802 24.75 15.1602 27.55 15.1602 31C15.1602 31.41 14.8202 31.75 14.4102 31.75C14.0002 31.75 13.6602 31.41 13.6602 31C13.6602 26.73 17.8502 23.25 23.0002 23.25C28.1502 23.25 32.3401 26.73 32.3401 31C32.3401 31.41 32.0001 31.75 31.5901 31.75Z"
        fill="#558EFF"
      />
    </svg>
  );
};
export default User;
