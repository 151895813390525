import React from "react";

const Unduh = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6.48221H11.5714V1.33936H6.42857V6.48221H3L9 12.4822L15 6.48221ZM3 14.1965V15.9108H15V14.1965H3Z"
        fill="#918B8B"
      />
    </svg>
  );
};
export default Unduh;
