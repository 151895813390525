import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/login.css";

import ScaleLoader from "react-spinners/ScaleLoader";
import IcGembok from "../../asset/svg/gembok"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getResetPassword, setErrorMessage, setSuccessMessage } from "../../store/actions";


function LupaPassword() {
  const dispatch = useDispatch()
  const { loading, successMessage, errorMessage } = useSelector(state => state.messageState)
  const [emailTerkirim, setEmailTerkirim] = useState(false)
  const [email, setEmail] = useState("")

  const submitHandle = () => {
    if (email.match(/^.+@(?:[\w-]+\.)+\w+$/) === null) {
      toast("Format Email Salah")
    } else {
      dispatch(getResetPassword(email))
    }
  }

  useEffect(() => {
    if (errorMessage) {
      toast(errorMessage)
      dispatch(setErrorMessage(""))
    }
    if (successMessage) {
      toast(successMessage)
      setEmailTerkirim(true)
      dispatch(setSuccessMessage(""))
    }
    // eslint-disable-next-line
  }, [errorMessage, successMessage])

  return (
    <div className="background">
      <ToastContainer />
      <div className="card">
        <div className="content">
          <div className="header">
            <IcGembok />
          </div>
          <div className="header">Lupa Password ?</div>
          {
            emailTerkirim ? (
              <div className="textContent">Link untuk reset password berhasil dikirimkan. Silahkan periksa email anda untuk mengkonfirmasi reset password</div>
            ) : (
              <div>
                <div className="textContent">Jangan khawatir! Masukkan alamat email Anda. Kami akan mengirimkan link reset password untuk Anda</div>
                <div className="inputBox">
                  <input type="text" placeholder="Email"
                    style={{textTransform: email.length ? "lowercase" : ""}}
                    onChange={(e) => setEmail((e.target.value).toLowerCase())}
                    value={email}
                  />
                </div>
                <div className="buttonSubmit" onClick={() => submitHandle()}>
                  {
                    loading ? (
                      <ScaleLoader
                        color="white"
                        height="13px"
                        width="2px"
                      />
                    ) : (
                      <div>Dapatkan link</div>
                    )
                  }
                </div>
              </div>
            )
          }



          <div className="register">
            <a style={{ cursor: "pointer" }} href={"/login"}>Kembali ke login</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LupaPassword;
