import React from "react";
import "../styles/app.css";

export default function RoundedFilter({ title, active, clickHandle }) {
  return (
    <div
      className={active ? "roundedFilter-active" : "roundedFilter"}
      onClick={() => clickHandle()}
    >
      {title}
    </div>
  );
}
