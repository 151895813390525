import React from "react";

const Users = ({ active }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 2.5C7.975 2.5 5.3125 5.1625 5.3125 8.4375C5.3125 11.65 7.825 14.25 11.1 14.3625C11.2 14.35 11.3 14.35 11.375 14.3625C11.4 14.3625 11.4125 14.3625 11.4375 14.3625C11.45 14.3625 11.45 14.3625 11.4625 14.3625C14.6625 14.25 17.175 11.65 17.1875 8.4375C17.1875 5.1625 14.525 2.5 11.25 2.5Z"
        fill={ active ? "#558EFF" : "#8094AE" }
      />
      <path
        d="M17.6002 17.6876C14.1127 15.3626 8.4252 15.3626 4.9127 17.6876C3.3252 18.7501 2.4502 20.1876 2.4502 21.7251C2.4502 23.2626 3.3252 24.6876 4.9002 25.7376C6.6502 26.9126 8.9502 27.5001 11.2502 27.5001C13.5502 27.5001 15.8502 26.9126 17.6002 25.7376C19.1752 24.6751 20.0502 23.2501 20.0502 21.7001C20.0377 20.1626 19.1752 18.7376 17.6002 17.6876Z"
        fill={ active ? "#558EFF" : "#8094AE" }
      />
      <path
        d="M24.9877 9.17488C25.1877 11.5999 23.4627 13.7249 21.0752 14.0124C21.0627 14.0124 21.0627 14.0124 21.0502 14.0124H21.0127C20.9377 14.0124 20.8627 14.0124 20.8002 14.0374C19.5877 14.0999 18.4752 13.7124 17.6377 12.9999C18.9252 11.8499 19.6627 10.1249 19.5127 8.24988C19.4252 7.23738 19.0752 6.31238 18.5502 5.52488C19.0252 5.28738 19.5752 5.13738 20.1377 5.08738C22.5877 4.87488 24.7752 6.69988 24.9877 9.17488Z"
        fill={ active ? "#558EFF" : "#8094AE" }
      />
      <path
        d="M27.4873 20.7375C27.3873 21.95 26.6123 23 25.3123 23.7125C24.0623 24.4 22.4873 24.725 20.9248 24.6875C21.8248 23.875 22.3498 22.8625 22.4498 21.7875C22.5748 20.2375 21.8373 18.75 20.3623 17.5625C19.5248 16.9 18.5498 16.375 17.4873 15.9875C20.2498 15.1875 23.7248 15.725 25.8623 17.45C27.0123 18.375 27.5998 19.5375 27.4873 20.7375Z"
        fill={ active ? "#558EFF" : "#8094AE" }
      />
    </svg>
  );
};
export default Users;
