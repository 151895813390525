import React, { useEffect, useState } from "react";
import "../../styles/addkaryawan.css";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees, updateEmployee } from "../../store/actions";

import ModalSelect from "../../components/modalSelect";
import { ToastContainer, toast } from "react-toastify"
import toTitleCase from "../../helpers/toTitleCase";

function EditEmployee() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { divisions, positions, insurances } = useSelector((state) => state.employeeState);
  const { successMessage } = useSelector(state => state.messageState)

  const [modalDivisi, setModalDivisi] = useState(false)
  const [modalJabatan, setModalJabatan] = useState(false)
  const [modalGender, setModalGender] = useState(false)
  const [modalPembayaran, setModalPembayaran] = useState(false)

  const [payload, setPayload] = useState({
    name: "",
    joinedDate: "",
    gender: "",
    dob: "",
    NIP: "",
    NIK: "",
    email: "",
    department: "",
    position: "",
    phoneNumber: "",
    insuranceStatus: "",
    address: "",
  });

  useEffect(() => {
    if(state?.dataEmployee){
      setPayload({
        _id: state.dataEmployee._id,
        name: state.dataEmployee.name,
        joinedDate: state.dataEmployee.joinedDate.split('/').reverse().join('-'),
        gender: state.dataEmployee.gender,
        dob: state.dataEmployee.dob.split('/').reverse().join('-'),
        NIP: state.dataEmployee.NIP,
        NIK: state.dataEmployee.NIK,
        email: state.dataEmployee.email,
        department: state.dataEmployee.department,
        position: state.dataEmployee.position,
        phoneNumber: state.dataEmployee.phoneNumber[0] === "+" ? `0${state.dataEmployee.phoneNumber.slice(3)}` : state.dataEmployee.phoneNumber,
        insuranceStatus: state.dataEmployee.insuranceStatus,
        address: state.dataEmployee.address
      })
    } else {
      navigate("/karyawan")
    }
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    dispatch(getEmployees());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(successMessage){
      navigate("/karyawan")
    }
    // eslint-disable-next-line
  },[successMessage])

  const changeInputHandle = (e) => {
    setPayload({
      ...payload,
      [e.target.id]: e.target.value,
    });
  };

  const editEmployee = () => {
    if(payload.name === "" ||
    payload.joinedDate === "" ||
    payload.gender === "" ||
    payload.dob === "" ||
    payload.email === "" ||
    payload.NIP === "" ||
    payload.NIK === "" ||
    payload.department === "" ||
    payload.position === "" ||
    payload.phoneNumber === "" ||
    payload.insuranceStatus === "" ||
    payload.address === "" ){
      toast("Silahkan isi semua field")
    } else if (payload.email.match(/^.+@(?:[\w-]+\.)+\w+$/) === null){
      toast("Invalid Email address")
    }else {
      let newData = payload
      payload.dob = payload.dob.split('-').reverse().join('/')
      payload.joinedDate = payload.joinedDate.split('-').reverse().join('/')
      dispatch(updateEmployee(newData));
    }
  };

  const closeModal = () => {
    setModalDivisi(false)
    setModalJabatan(false)
    setModalGender(false)
    setModalPembayaran(false)
  }

  return (
    <div className="bgcontent">
      <ToastContainer />
      <div className="header">Edit Data Karyawan</div>
      <div className="addkaryawan">
        <div className="row">
          <div className="forminput">
            <form>
            <fieldset className="boxArea">
              <legend className="fieldsetText">
                Nama Lengkap{" "}
                <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
              </legend>
              <input
                type="text"
                placeholder="Tulis nama lengkap"
                id="name"
                value={payload.name}
                onChange={(e) => changeInputHandle(e)}
              />
            </fieldset>
            <div className="forminputHorizontal">
              <fieldset className="horizontalBoxArea">
                <legend className="fieldsetText">
                  Tgl Lahir{" "}
                  <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
                </legend>
                <input
                  type={"date"}
                  className={payload.dob ? 'date-input--has-value' : ''}
                  placeholder="Pilih Tanggal Lahir"
                  id="dob"
                  value={payload.dob}
                  onChange={(e) => changeInputHandle(e)}
                />
              </fieldset>
              <fieldset className="horizontalBoxArea">
                <legend className="fieldsetText">
                  Tgl Bergabung{" "}
                  <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
                </legend>
                <input
                  type="date"
                  className={payload.joinedDate ? 'date-input--has-value' : ''}
                  placeholder="Tgl Bergabung"
                  id="joinedDate"
                  value={payload.joinedDate}
                  onChange={(e) => changeInputHandle(e)}
                />
              </fieldset>
            </div>
            <fieldset className="boxArea">
              <legend className="fieldsetText">
                NIK{" "}
                <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
              </legend>
              <input
                className="inputhp"
                type="number"
                placeholder="NIK"
                id="NIK"
                value={payload.NIK}
                onChange={(e) => changeInputHandle(e)}
              />
            </fieldset>
            <fieldset className="boxArea">
              <legend className="fieldsetText">
                Nomor karyawan{" "}
                <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
              </legend>
              <input
                className="inputhp"
                type="text"
                placeholder="Nomor karyawan"
                id="NIP"
                value={payload.NIP}
                onChange={(e) => changeInputHandle(e)}
              />
            </fieldset>
            <fieldset className="boxArea">
              <legend className="fieldsetText">
                Email karyawan{" "}
                <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
              </legend>
              <input
                type="email"
                placeholder="Email karyawan"
                id="email"
                value={payload.email}
                onChange={(e) => changeInputHandle(e)}
              />
            </fieldset>
            <div className="forminputHorizontal">
              <ModalSelect
                item={divisions}
                modal={modalDivisi}
                closeModal={closeModal}
                setModal={() => {
                  setModalGender(false)
                  setModalJabatan(false)
                  setModalPembayaran(false)
                  modalDivisi ? setModalDivisi(false) : setModalDivisi(true)
                }}
                legend={"Divisi"}
                defaultValue={"Pilih Divisi Karyawan"}
                value={payload.department}
                selectValue={(item) =>
                  setPayload({ ...payload, department: item })
                }
                addAbility={true}
              />
              <ModalSelect
                item={positions}
                modal={modalJabatan}
                closeModal={closeModal}
                setModal={() => {
                  setModalDivisi(false)
                  setModalGender(false)
                  setModalPembayaran(false)
                  modalJabatan ? setModalJabatan(false) : setModalJabatan(true)
                }}
                legend={"Jabatan"}
                defaultValue={"Pilih Jabatan Karyawan"}
                value={payload.position}
                selectValue={(item) =>
                  setPayload({ ...payload, position: item })
                }
                addAbility={true}
              />
            </div>
            <div className="forminputHorizontal">
              <ModalSelect
                item={["Laki-Laki", "Perempuan"]}
                modal={modalGender}
                closeModal={closeModal}
                setModal={() => {
                  setModalDivisi(false)
                  setModalJabatan(false)
                  setModalPembayaran(false)
                  modalGender ? setModalGender(false) : setModalGender(true)
                }}
                legend={"Jenis Kelamin"}
                defaultValue={"Pilih Jenis Kelamin"}
                value={payload.gender}
                selectValue={(item) => setPayload({ ...payload, gender: item })}
              />
              <fieldset className="horizontalBoxArea">
                <legend className="fieldsetText">
                  No Hp{" "}
                  <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
                </legend>
                <input
                  className="inputhp"
                  type="number"
                  placeholder="Nomor Hp"
                  id="phoneNumber"
                  value={payload.phoneNumber}
                  onChange={(e) => changeInputHandle(e)}
                />
              </fieldset>
            </div>
            <div style={{ maxHeight: "100px" }}>
              <ModalSelect
                item={insurances}
                modal={modalPembayaran}
                closeModal={closeModal}
                setModal={() => {
                  setModalDivisi(false)
                  setModalGender(false)
                  setModalJabatan(false)
                  modalPembayaran ? setModalPembayaran(false) : setModalPembayaran(true)
                }}
                legend={"Pembayaran"}
                defaultValue={"Pilih Tipe Pembayaran"}
                value={payload.insuranceStatus}
                selectValue={(item) =>
                  setPayload({ ...payload, insuranceStatus: item })
                }
                addAbility={true}
              />
            </div>
            <fieldset className="boxArea">
              <legend className="fieldsetText">
                Alamat{" "}
                <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
              </legend>
              <textarea
                type="text"
                className="textArea"
                placeholder="Tulis Alamat"
                id="address"
                value={toTitleCase(payload.address)}
                onChange={(e) => changeInputHandle(e)}
              />
            </fieldset>
            
            <div className="buttongroup row">
              <div className="redbutton" onClick={() => navigate("/karyawan")}>
                Batalkan
              </div>
              <div className="button" onClick={() => editEmployee()}>
                Simpan
              </div>
            </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEmployee;
