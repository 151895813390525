import React from "react";

const Camera = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1671_3368)">
        <path d="M12.9255 15.8321C14.7353 15.8321 16.2025 14.365 16.2025 12.5551C16.2025 10.7452 14.7353 9.27808 12.9255 9.27808C11.1156 9.27808 9.64844 10.7452 9.64844 12.5551C9.64844 14.365 11.1156 15.8321 12.9255 15.8321Z" fill="white" />
        <path d="M9.85599 2.31396L7.98194 4.36211H4.73564C3.60404 4.36211 2.6875 5.27865 2.6875 6.41025V18.6991C2.6875 19.8307 3.60404 20.7472 4.73564 20.7472H21.1208C22.2524 20.7472 23.1689 19.8307 23.1689 18.6991V6.41025C23.1689 5.27865 22.2524 4.36211 21.1208 4.36211H17.8745L16.0004 2.31396H9.85599ZM12.9282 17.675C10.1018 17.675 7.80785 15.3811 7.80785 12.5547C7.80785 9.72823 10.1018 7.43432 12.9282 7.43432C15.7546 7.43432 18.0486 9.72823 18.0486 12.5547C18.0486 15.3811 15.7546 17.675 12.9282 17.675Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1671_3368">
          <rect width="24.5777" height="24.5777" fill="white" transform="translate(0.640625 0.265869)" />
        </clipPath>
      </defs>
    </svg>

  );
};
export default Camera;
