const initialState = {
    employees: [],
    verifiedEmployee: [],
    divisions: [],
    positions: [],
    insurances: []
}

export default function reducer(state = initialState, action){
    switch(action.type){
        case "SET_EMPLOYEES":
            return {...state, employees: action.payload}
        case "SET_VERIFIED_EMPLOYEES":
            return {...state, verifiedEmployee: action.payload}
        case "SET_DIVISIONS":
            return {...state, divisions: action.payload}
        case "SET_POSITIONS":
            return {...state, positions: action.payload}
        case "SET_INSURANCE":
            return {...state, insurances: action.payload}
        default: 
            return state
    }
}