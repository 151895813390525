import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import "../../styles/detailResult.css";

import IcPanah from "../../asset/svg/panah";
import { useDispatch, useSelector } from "react-redux";
import { getResumeMedisPatient, setErrorMessage, setResumeMedisPatient } from "../../store/actions";
import Medqcare from "../../asset/svg/medqcare";
import formatDay from "../../helpers/formatday";
import IcPdf from "../../asset/svg/Ic_Pdf";
import IcUnduh from "../../asset/svg/unduhSmall";
import AntigenPositif from "../../asset/svg/AntigenPositif";
import AntigenNegatif from "../../asset/svg/AntigenNegatif";

import PDFObject from "pdfobject";

function DetailPemeriksaan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation()

  const { employeeId } = useParams();
  const { resumeMedisPatient } = useSelector((state) => state.resumeMedisState);
  const { errorMessage } = useSelector((state) => state.messageState);
  const [resultId, setResultId] = useState("")
  const [expanded, setExpanded] = useState(false);
  const [isActive, setIsActive] = useState("");

  useEffect(() => {
    if(state && state.resultId){
      setResultId(state.resultId)
    }
    dispatch(setResumeMedisPatient({}))
    dispatch(getResumeMedisPatient(employeeId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(resultId && resumeMedisPatient?.resumeMedis?.length > 0){
      resumeMedisPatient.resumeMedis.forEach(el => {
        if(el._id === resultId){
          setIsActive(el)
          setExpanded(true)
          return
        }
      })
    }
    // eslint-disable-next-line
  },[resumeMedisPatient])

  useEffect(() => {
    if (errorMessage === "Wrong Employee Id") {
      dispatch(setErrorMessage(""));
      navigate("/result");
    }
    // eslint-disable-next-line
  }, [errorMessage]);

  const handleDownload = async (url) => {
    window.open(url);
  };

  useEffect(() => {
    if (isActive.file) {
      const result = PDFObject.embed(
        isActive.groupEvent === "Umum" ? isActive.medicalCertificate.fileUrl : isActive.file.fileUrl,
        document.getElementById("showPDF"),
        {
          pdfOpenParams: {
            toolbar: "0",
            //  view: 'Fit',
            //  statusbar: '0',
            //  navpanes: '0',
          }
        }
      );
      if (typeof result === "boolean") {
        if (document.getElementById("showPDF").childNodes[0]) {
          const embedDocument = document.getElementById("showPDF");
          embedDocument.removeChild(embedDocument.childNodes[0]);
        }
        const node = document.createElement("div");
        const textnode = document.createTextNode("Not Valid Url");
        node.appendChild(textnode);
        node.classList.add("pdfstyle-warning");
        document.getElementById("showPDF").appendChild(node);
      }
    }
  }, [isActive]);

  return (
    <div className="bgcontent">
      <div className="detailResult">
        <div className="headerLink">
          <Link to={"/result"} className="link">
            Hasil Pemeriksaan
          </Link>
          <IcPanah />
          <div className="currentLink">{resumeMedisPatient.name}</div>
        </div>
        <div className="contentArea">
          <div
            className="contentLeft"
            style={{ minWidth: expanded ? "40%" : "" }}
          >
            <div className="employeeName">{resumeMedisPatient.name}</div>
            <div className="employeeDivisi">
              Divisi {resumeMedisPatient.department}
            </div>
            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "#E5E5E5",
                marginBottom: "25px",
              }}
            />
            <div className="pemeriksaan">RIWAYAT PEMERIKSAAN</div>
            {resumeMedisPatient.resumeMedis &&
            resumeMedisPatient.resumeMedis.length > 0 ? (
              <>
                {resumeMedisPatient.resumeMedis.map((el) => {
                  return (
                    <div key={el._id}>
                      <div
                        className={
                          isActive._id === el._id ? "cardOpen" : "cardClose"
                        }
                      >
                        <div
                          style={{ padding: "15px", cursor: "pointer" }}
                          onClick={() => {
                            if (el._id === isActive._id) {
                              setIsActive("");
                              setExpanded(false);
                            } else {
                              setIsActive(el);
                              setExpanded(true);
                            }
                          }}
                        >
                          <div className="dateChecked">{`${formatDay(
                            el.service.date
                          )}`}</div>
                          <div className="eventName">{el.groupEvent}</div>
                          {isActive._id === el._id && el.result && (
                            <div className="result">
                              <div className="iconAntigen">
                                {el.result.swab === "Positif" ? (
                                  <div style={{ marginRight: "8px" }}>
                                    <AntigenPositif size={16} />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {el.result.swab === "Negatif" ? (
                                  <div style={{ marginRight: "8px" }}>
                                    <AntigenNegatif size={16} />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {el.result && el.result.swab ? el.result.swab : el.result.conclusion}
                              </div>
                            </div>
                          )}
                        </div>
                        {isActive._id === el._id && (
                          <div className="download">
                            <IcPdf />
                            <div
                              style={{ marginLeft: "10px", marginTop: "2px" }}
                            >
                              Hasil {el.groupEvent}
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                marginTop: "2px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDownload(el.file.fileUrl)}
                            >
                              <IcUnduh />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <div style={{ padding: "200px" }}>
                  <Medqcare />
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      color: "#c6cbcf",
                      marginTop: "20px",
                    }}
                  >
                    Tidak ada data tersedia
                  </div>
                </div>
              </div>
            )}
          </div>
          {expanded && (
            <div className="preview">
              <div className="titlePreview">Preview Hasil</div>
              <div className="datePreview">
                {formatDay(isActive.service.date)}
              </div>
              <div
                id="showPDF"
                style={{ width: "490px", height: "450px" }}
                className="pdfobject-container"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailPemeriksaan;
