import React from "react";

const Kesehatan = ({ active }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3125 4.72537C19.2625 5.03787 19.2375 5.35037 19.2375 5.66287C19.2375 8.47537 21.5125 10.7491 24.3125 10.7491C24.625 10.7491 24.925 10.7129 25.2375 10.6629V20.7491C25.2375 24.9879 22.7375 27.5004 18.4875 27.5004H9.25125C5 27.5004 2.5 24.9879 2.5 20.7491V11.5004C2.5 7.25038 5 4.72537 9.25125 4.72537H19.3125ZM19.5637 12.3254C19.225 12.2879 18.8887 12.4379 18.6875 12.7129L15.6637 16.6254L12.2 13.9004C11.9875 13.7379 11.7375 13.6741 11.4875 13.7004C11.2387 13.7379 11.0137 13.8741 10.8625 14.0741L7.16375 18.8879L7.0875 19.0004C6.875 19.3991 6.975 19.9116 7.35 20.1879C7.525 20.3004 7.7125 20.3754 7.925 20.3754C8.21375 20.3879 8.4875 20.2366 8.6625 20.0004L11.8 15.9616L15.3625 18.6379L15.475 18.7116C15.875 18.9241 16.375 18.8254 16.6625 18.4491L20.275 13.7879L20.225 13.8129C20.425 13.5379 20.4625 13.1879 20.325 12.8754C20.1887 12.5629 19.8875 12.3504 19.5637 12.3254ZM24.4876 2.5C26.1501 2.5 27.5001 3.85 27.5001 5.5125C27.5001 7.175 26.1501 8.525 24.4876 8.525C22.8251 8.525 21.4751 7.175 21.4751 5.5125C21.4751 3.85 22.8251 2.5 24.4876 2.5Z"
        fill={ active ? "#558EFF" : "#8094AE" }
      />
    </svg>
  );
};
export default Kesehatan;
