import React from "react";

const IcPdf = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_647_6333)">
        <path
          d="M32.7367 2.3V29.8625H3.26172V8.2625L11.0242 0.5H30.993C31.9492 0.5 32.7367 1.34375 32.7367 2.3Z"
          fill="#EFF2F3"
        />
        <path
          d="M9.39531 8.2625H3.32031L11.0266 0.5L10.9703 6.575C11.0266 7.53125 10.2953 8.2625 9.39531 8.2625Z"
          fill="#DADEDE"
        />
        <path
          d="M20.8695 16.2499C19.2383 14.6749 18.057 12.8749 18.057 12.8749C18.507 12.1999 19.5758 8.31869 17.9445 7.19369C16.3133 6.06869 15.4695 8.14994 15.4695 8.14994C14.7383 10.7374 16.482 13.0999 16.482 13.0999L14.5133 17.4312C14.2883 17.4312 8.04453 19.8499 10.182 22.8312C12.3758 25.8124 15.4133 18.6124 15.4133 18.6124C16.5945 18.2187 20.1945 17.7124 20.1945 17.7124C21.6008 19.6249 23.2883 20.2437 23.2883 20.2437C25.8758 20.9187 26.157 18.7812 26.157 18.7812C26.2133 15.9687 20.8695 16.2499 20.8695 16.2499ZM11.2508 21.8187C11.1945 21.8187 11.1945 21.7624 11.1945 21.7624C10.857 20.9749 13.4445 19.4562 13.4445 19.4562C13.4445 19.4562 12.0383 22.1562 11.2508 21.8187ZM17.1008 8.20619C17.832 8.88119 17.2133 11.1874 17.2133 11.1874C17.2133 11.1874 16.3695 8.88119 17.1008 8.20619ZM16.4258 16.9249L17.4383 14.4499L19.0133 16.3624L16.4258 16.9249ZM24.7508 18.7249C24.3008 19.4562 22.4445 17.8812 22.2758 17.7124C22.557 17.7124 24.9758 17.8812 24.7508 18.7249ZM32.7383 28.0624V34.6999C32.7383 35.6562 31.9508 36.4999 30.9383 36.4999H5.00703C4.05078 36.4999 3.20703 35.7124 3.20703 34.6999V28.0624H32.7383Z"
          fill="#F2786B"
        />
        <path
          d="M15.6945 30.9876C15.6945 31.4376 15.5258 31.7751 15.2445 32.0563C14.9633 32.2813 14.5133 32.3938 13.9508 32.3938H13.5008V34.0251H12.7695V29.6938H14.0633C14.6258 29.6938 15.0195 29.8063 15.3008 30.0313C15.582 30.2563 15.6945 30.5376 15.6945 30.9876ZM13.557 31.8313H13.9508C14.2883 31.8313 14.5695 31.7751 14.7383 31.6626C14.907 31.5501 15.0195 31.3251 15.0195 31.0438C15.0195 30.8188 14.9633 30.5938 14.7945 30.4813C14.6258 30.3688 14.4008 30.3126 14.0633 30.3126H13.557V31.8313ZM20.1383 31.8313C20.1383 32.5626 19.9133 33.1251 19.5195 33.4626C19.1258 33.8563 18.5633 34.0251 17.7758 34.0251H16.5383V29.6938H17.8883C18.5633 29.6938 19.1258 29.8626 19.5195 30.2563C19.9133 30.5938 20.1383 31.1563 20.1383 31.8313ZM19.3508 31.8313C19.3508 30.7626 18.8445 30.2563 17.8883 30.2563H17.2695V33.4063H17.7758C18.8445 33.4626 19.3508 32.9001 19.3508 31.8313ZM21.7695 34.0251H21.0383V29.6938H23.5133V30.3126H21.7695V31.6626H23.4008V32.2813H21.7695V34.0251Z"
          fill="#EFF2F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_647_6333">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IcPdf;
