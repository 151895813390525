import React from 'react';

const Dashboard = ({active}) => {
  return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M5.22222 16.2222H12.5556C13.2278 16.2222 13.7778 15.6722 13.7778 15V5.22222C13.7778 4.55 13.2278 4 12.5556 4H5.22222C4.55 4 4 4.55 4 5.22222V15C4 15.6722 4.55 16.2222 5.22222 16.2222ZM5.22222 26H12.5556C13.2278 26 13.7778 25.45 13.7778 24.7778V19.8889C13.7778 19.2167 13.2278 18.6667 12.5556 18.6667H5.22222C4.55 18.6667 4 19.2167 4 19.8889V24.7778C4 25.45 4.55 26 5.22222 26ZM17.4444 26H24.7778C25.45 26 26 25.45 26 24.7778V15C26 14.3278 25.45 13.7778 24.7778 13.7778H17.4444C16.7722 13.7778 16.2222 14.3278 16.2222 15V24.7778C16.2222 25.45 16.7722 26 17.4444 26ZM16.2222 5.22222V10.1111C16.2222 10.7833 16.7722 11.3333 17.4444 11.3333H24.7778C25.45 11.3333 26 10.7833 26 10.1111V5.22222C26 4.55 25.45 4 24.7778 4H17.4444C16.7722 4 16.2222 4.55 16.2222 5.22222Z"
            fill={ active ? "#558EFF" : "#8094AE"}
        />
        </svg>
  );
}
export default Dashboard;