import * as React from "react"

const GembokSukses = (props) => (
  <svg width="174" height="174" viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M86.3129 66.3571V48.0943C86.3129 39.5501 79.1659 32.5102 70.4917 32.5102C61.8175 32.5102 54.6705 39.5501 54.6705 48.0943V66.3571H40.7324V48.0943C40.7324 31.9721 54.1239 18.7811 70.4917 18.7811C86.8595 18.7811 100.251 31.9721 100.251 48.0943V66.3571H86.3129Z" fill="#8C9BA6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M66.5848 32.9983C59.7711 34.7314 54.6695 40.8759 54.6695 48.0945V66.3574H46.8574V48.0945C46.8574 39.5504 54.0044 32.5105 62.6786 32.5105C64.0244 32.5102 65.3334 32.68 66.5848 32.9983ZM70.4907 18.7811C69.1671 18.7811 67.8636 18.8686 66.5848 19.0358C81.1241 20.9349 92.4382 33.2759 92.4382 48.0945V66.3574H100.25V48.0945C100.25 31.9721 86.8585 18.7811 70.4907 18.7811Z" fill="#7C8B96" />
    <path fillRule="evenodd" clipRule="evenodd" d="M34.9561 62.8281H106.027C112.393 62.8281 117.602 67.9589 117.602 74.2301V130.494C117.602 136.766 112.393 141.896 106.027 141.896H34.9561C28.5895 141.896 23.3809 136.766 23.3809 130.494V74.2299C23.3806 67.9589 28.5895 62.8281 34.9561 62.8281Z" fill="#BED8FB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M106.557 62.8408C112.679 63.1168 117.601 68.1345 117.601 74.23V130.494C117.601 136.765 112.392 141.896 106.026 141.896H34.9553C29.8997 141.896 25.5749 138.661 24.0078 134.189C24.1841 134.196 24.3612 134.201 24.5393 134.201H95.6097C101.976 134.201 107.185 129.07 107.185 122.799V66.535C107.185 65.2436 106.963 64.0007 106.557 62.8408Z" fill="#9BC9FF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M70.4909 83.5461C76.0336 83.5461 80.5272 87.9721 80.5272 93.4318C80.5272 96.8739 78.7409 99.9045 76.032 101.675L80.3246 119.601C80.4192 119.995 80.3337 120.373 80.0783 120.691C79.8229 121.009 79.469 121.178 79.0576 121.178H61.9247C61.5133 121.178 61.1594 121.009 60.904 120.691C60.6488 120.373 60.5631 119.995 60.6577 119.601L64.9502 101.675C62.2414 99.9045 60.4551 96.8739 60.4551 93.4318C60.4546 87.9721 64.9482 83.5461 70.4909 83.5461Z" fill="#F4F8F8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M70.4914 83.5461C71.1262 83.5461 71.7473 83.6049 72.3493 83.7159C67.6943 84.5741 64.1709 88.5974 64.1709 93.4318C64.1709 96.8739 65.9572 99.9045 68.666 101.675L64.3735 119.601C64.2789 119.995 64.3644 120.373 64.6198 120.691C64.8752 121.009 65.2291 121.178 65.6405 121.178H61.9247C61.5133 121.178 61.1594 121.009 60.904 120.691C60.6488 120.373 60.5631 119.995 60.6577 119.601L64.9502 101.675C62.2414 99.9045 60.4551 96.8739 60.4551 93.4318C60.4551 87.9721 64.9487 83.5461 70.4914 83.5461Z" fill="#E5ECF1" />
    <g clipPath="url(#clip0_1671_4440)">
      <path d="M121.281 146C137.644 146 150.908 132.934 150.908 116.817C150.908 100.7 137.644 87.6343 121.281 87.6343C104.919 87.6343 91.6543 100.7 91.6543 116.817C91.6543 132.934 104.919 146 121.281 146Z" fill="#50B748" />
      <path d="M127.518 145.311C128.086 145.202 128.65 145.077 129.211 144.936C130.161 144.675 131.098 144.367 132.017 144.014C132.938 143.663 133.841 143.267 134.722 142.827C135.603 142.385 136.461 141.9 137.292 141.374C138.123 140.848 138.926 140.282 139.699 139.678C140.473 139.072 141.217 138.429 141.926 137.749C142.634 137.072 143.307 136.36 143.942 135.617C144.579 134.873 145.178 134.097 145.736 133.294C146.293 132.492 146.81 131.663 147.284 130.81C147.757 129.956 148.187 129.08 148.571 128.183C148.957 127.287 149.296 126.372 149.589 125.443C149.839 124.629 150.053 123.805 150.23 122.973L132.629 105.636C129.491 102.544 125.392 101 121.288 101C117.183 101 113.075 102.544 109.937 105.636C103.659 111.819 103.659 121.81 109.937 127.993L127.518 145.311Z" fill="#10A711" />
      <path d="M121.284 101.003C125.388 101.003 129.492 102.547 132.63 105.639C138.908 111.822 138.908 121.813 132.63 127.996C126.353 134.179 116.21 134.179 109.933 127.996C103.655 121.813 103.655 111.822 109.933 105.639C113.071 102.547 117.179 101.003 121.284 101.003ZM128.594 111.951C128.36 111.972 128.133 112.061 127.948 112.203L118.931 118.867L114.75 114.749C113.843 113.818 112.06 115.575 113.004 116.468L117.942 121.332C118.357 121.74 119.083 121.792 119.553 121.446L129.428 114.15C130.26 113.554 129.748 111.966 128.72 111.951C128.678 111.949 128.636 111.949 128.594 111.951L128.594 111.951Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1671_4440">
        <rect width="59.2539" height="58.3656" fill="white" transform="translate(91.6523 87.6343)" />
      </clipPath>
    </defs>
  </svg>

)

export default GembokSukses
