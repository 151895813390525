import React from "react";

const Eye = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.236328 6C0.823828 2.8 3.6282 0.375 6.9982 0.375C10.3682 0.375 13.172 2.8 13.7601 6C13.1726 9.2 10.3682 11.625 6.9982 11.625C3.6282 11.625 0.824453 9.2 0.236328 6ZM6.9982 9.125C7.827 9.125 8.62186 8.79576 9.20791 8.20971C9.79396 7.62366 10.1232 6.8288 10.1232 6C10.1232 5.1712 9.79396 4.37634 9.20791 3.79029C8.62186 3.20424 7.827 2.875 6.9982 2.875C6.1694 2.875 5.37455 3.20424 4.78849 3.79029C4.20244 4.37634 3.8732 5.1712 3.8732 6C3.8732 6.8288 4.20244 7.62366 4.78849 8.20971C5.37455 8.79576 6.1694 9.125 6.9982 9.125ZM6.9982 7.875C6.50092 7.875 6.02401 7.67746 5.67238 7.32583C5.32075 6.97419 5.1232 6.49728 5.1232 6C5.1232 5.50272 5.32075 5.02581 5.67238 4.67417C6.02401 4.32254 6.50092 4.125 6.9982 4.125C7.49548 4.125 7.9724 4.32254 8.32403 4.67417C8.67566 5.02581 8.8732 5.50272 8.8732 6C8.8732 6.49728 8.67566 6.97419 8.32403 7.32583C7.9724 7.67746 7.49548 7.875 6.9982 7.875Z"
        fill="#918B8B"
      />
    </svg>
  );
};
export default Eye;
