const initialState = {
    dataEvents: [],
    dataReservasi: [],
    detailEvents: {},
    groupEvents: []
}

export default function reducer(state = initialState, action){
    switch(action.type){
        case "SET_DATAEVENTS":
            return {...state, dataEvents: action.payload}
        case "SET_DATARESERVASI":
            return {...state, dataReservasi: action.payload}
        case "SET_DETAILEVENTS":
            return {...state, detailEvents: action.payload}
        case "SET_DATAGROUPEVENTS":
            return {...state, groupEvents: action.payload}
        default: 
            return state
    }
}