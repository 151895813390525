import React from "react";
import IcSearch from "../asset/svg/search";
import "../styles/app.css"

export default function RoundedSearch({setSearchName, searchName, placeholder}) {
  return (
    <div className="roundedSearch-container">
      <div style={{ padding: "10px", marginLeft: "10px"}}>
        <IcSearch />
      </div>
      <input
        className="roundedSearch"
        placeholder={placeholder}
        onChange={(e) => setSearchName(e.target.value)}
        value={searchName}
      />
    </div>
  );
}

