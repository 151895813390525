import React from "react";
import "../styles/app.css";


export default function modalConfirmation({action, modal, title}){

    const actionHandle = () => {
        modal(false)
        action()
    }

    return (
        <div className="modalConfirmation">
            <div className="modalConfirmation-content">
                <h1 className="modalConfirmation-title">{title}</h1>
                <div className="buttongroup">
                    <button className="redbutton" onClick={() => modal(false)}>Tidak</button>
                    <button className="button" onClick={() => actionHandle()}>Iya</button>
                </div>
            </div>
        </div>
    )
}