import React from "react";

const Checkup = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5312 6.25H4.625C2.11719 6.25 0.078125 8.25 0.078125 10.7031V35.3906C0.078125 37.8906 2.16406 40 4.625 40H35.5312C37.9531 40 39.9219 37.9297 39.9219 35.3906V10.7031C39.9219 8.20312 37.9922 6.25 35.5312 6.25ZM36.7969 35.3906C36.7969 36.2109 36.2266 36.875 35.5312 36.875H4.625C3.88281 36.875 3.20312 36.1641 3.20312 35.3906V10.7031C3.20312 9.96875 3.84375 9.375 4.625 9.375H35.5312C36.2734 9.375 36.7969 9.92188 36.7969 10.7031V35.3906Z"
        fill="black"
      />
      <path
        d="M35.5312 6.25H4.625C2.11719 6.25 0.078125 8.25 0.078125 10.7031V35.3906C0.078125 37.8906 2.16406 40 4.625 40H35.5312C37.9531 40 39.9219 37.9297 39.9219 35.3906V10.7031C39.9219 8.20312 37.9922 6.25 35.5312 6.25ZM36.7969 35.3906C36.7969 36.2109 36.2266 36.875 35.5312 36.875H4.625C3.88281 36.875 3.20312 36.1641 3.20312 35.3906V10.7031C3.20312 9.96875 3.84375 9.375 4.625 9.375H35.5312C36.2734 9.375 36.7969 9.92188 36.7969 10.7031V35.3906Z"
        fill="url(#paint0_linear_128_15964)"
      />
      <path
        d="M32.2887 25.2735L28.1168 25.211L25.6168 15.7657C25.4371 15.0782 24.8355 14.586 24.1168 14.5392C23.4059 14.5001 22.7496 14.9142 22.484 15.5782L18.1012 26.672L15.6246 20.8282C15.3746 20.2345 14.7965 19.8439 14.148 19.8204C13.4996 19.8048 12.9059 20.1642 12.6246 20.7501L10.5543 25.086L7.3668 25.1876C6.50742 25.211 5.82774 25.9376 5.85117 26.797C5.87461 27.6563 6.60117 28.336 7.46055 28.3126L11.5465 28.1876H11.5621C12.1715 28.1642 12.7105 27.8048 12.9762 27.2657L14.023 25.0704L16.6324 31.2188C16.898 31.8438 17.4918 32.2501 18.148 32.2501H18.1715C18.8355 32.2423 19.4293 31.8204 19.6871 31.1798L23.7418 20.9142L25.3824 27.0938C25.5621 27.7813 26.1871 28.2892 26.898 28.3204L32.2418 28.4064H32.2652C33.1168 28.4064 33.8121 27.7267 33.8277 26.8673C33.8433 26.0001 33.1558 25.2892 32.2887 25.2735Z"
        fill="black"
      />
      <path
        d="M32.2887 25.2735L28.1168 25.211L25.6168 15.7657C25.4371 15.0782 24.8355 14.586 24.1168 14.5392C23.4059 14.5001 22.7496 14.9142 22.484 15.5782L18.1012 26.672L15.6246 20.8282C15.3746 20.2345 14.7965 19.8439 14.148 19.8204C13.4996 19.8048 12.9059 20.1642 12.6246 20.7501L10.5543 25.086L7.3668 25.1876C6.50742 25.211 5.82774 25.9376 5.85117 26.797C5.87461 27.6563 6.60117 28.336 7.46055 28.3126L11.5465 28.1876H11.5621C12.1715 28.1642 12.7105 27.8048 12.9762 27.2657L14.023 25.0704L16.6324 31.2188C16.898 31.8438 17.4918 32.2501 18.148 32.2501H18.1715C18.8355 32.2423 19.4293 31.8204 19.6871 31.1798L23.7418 20.9142L25.3824 27.0938C25.5621 27.7813 26.1871 28.2892 26.898 28.3204L32.2418 28.4064H32.2652C33.1168 28.4064 33.8121 27.7267 33.8277 26.8673C33.8433 26.0001 33.1558 25.2892 32.2887 25.2735Z"
        fill="url(#paint1_linear_128_15964)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_128_15964"
          x1="20"
          y1="6.25"
          x2="20"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBC659" />
          <stop offset="1" stopColor="#23CEB9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_128_15964"
          x1="19.8393"
          y1="14.5366"
          x2="19.8393"
          y2="32.2501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DBC659" />
          <stop offset="1" stopColor="#23CEB9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Checkup;
