import React, { useEffect, useState } from "react";

import IcClose from "../../asset/svg/ic_close";
import { css } from "@emotion/react";
import { ScaleLoader } from "react-spinners";

import IcPdf from "../../asset/svg/Ic_Pdf";
import IcEye from "../../asset/svg/eye"
import IcUnduh from "../../asset/svg/unduhSmall";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage, uploadBuktiTransfer } from "../../store/actions";

import ModalPDFViewer from "../../components/ModalPDFViewer";
import formatDate from "../../helpers/formatDate";

import BarLoader from "react-spinners/BarLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: lime;
`;


export default function ModalDetail({ setModal, changeModal }) {
  const dispatch = useDispatch()
  const [modalData, setModalData] = useState({})
  const [dataRender, setDataRender] = useState([])
  const [modalPdf, setModalPdf] = useState(false)
  const [modalPdfFile, setModalPdfFile] = useState("")
  const [modalPdfType, setModalPdfType] = useState("")
  const [modalPdfTitle, setModalPdfTitle] = useState("")
  const [typeEvent, setTypeEvent] = useState("")

  const { detailEvents } = useSelector(state => state.eventState)
  const { loading } = useSelector(state => state.messageState)

  useEffect(() => {
    if (Object.keys(detailEvents)) {
      setModalData(detailEvents)
    }
  }, [detailEvents])

  useEffect(() => {
    if (Object.keys(modalData)) {
      setTypeEvent(checkType())
      setDataRender(modalData.participant)
    }
    // eslint-disable-next-line
  }, [modalData])

  useEffect(() => {
    if (!modalPdf) {
      setModalPdfFile('')
      setModalPdfType('')
      setModalPdfTitle('')
    }
  }, [modalPdf])

  function handleUpload(e) {
    const file = e.target.files[0];
    let newObject = new File(
      [file.slice(0, file.size, "application/pdf")],
      `${file.name.slice(0, -4)}${new Date().toISOString()}.pdf`,
      { type: "pdf" }
    );

    const formData = new FormData();
    formData.append("document", newObject);
    formData.append("eventId", modalData.id);

    dispatch(uploadBuktiTransfer(formData, modalData.reservasiEventWeb));
  }

  const checkType = () => {
    if (modalData.status === "Selesai" && modalData.countResume) {
      if (modalData.countResume["Positif"] === 0 && modalData.countResume["Negatif"] === 0) {
        return "MCU"
      } else {
        return "SWAB"
      }
    } else {
      return ""
    }
  }

  const handleDownload = async (url) => {
    window.open(url);
  };

  const openPdf = (file) => {
    if (file !== null && file !== undefined && file.fileUrl) {
      setModalPdfFile(file.fileUrl)
      setModalPdf(true)
      setModalPdfType('pdf')
      setModalPdfTitle('Hasil Pemeriksaan')
    } else {
      dispatch(setErrorMessage("Dokumen tidak valid"))
    }
  }

  return (
    <div id="myModal" className="modalPeserta">
      <div className="modalPeserta-content">
        <div className="modalPeserta-header">
          <div style={{ width: "100%", textAlign: "center" }}>
            DETAIL PEMERIKSAAN
          </div>
          <div
            className="close"
            style={{ cursor: "pointer" }}
            onClick={() => setModal(false)}
          >
            <IcClose />
          </div>
        </div>
        {
          loading && (
            <BarLoader
              color={"#36D7B7"}
              css={override}
              height="1px"
              width="100%"
            />
          )
        }
        <div className="row jbetween">
          <div style={{ width: "70%" }}>
            <div className="row jbetween" style={{ width: "80%", height: "25%", paddingTop: "20px", paddingBottom: "10px", paddingLeft: "50px", paddingRight: "100px" }}>
              <div style={{ width: "50%" }}>
                <div className="modalPeserta-title">Nama Klinik</div>
                <div className="modalPeserta-value">{modalData?.clinic?.name}</div>
                <div className="modalPeserta-title">Nama Event</div>
                <div className="modalPeserta-value">{modalData?.services?.name}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="modalPeserta-title">Tanggal Event</div>
                <div className="modalPeserta-value">{modalData?.date && formatDate(modalData.date)}</div>
                <div className="modalPeserta-title">Waktu Event</div>
                <div className="modalPeserta-value">{modalData?.time && modalData.time + " WIB"}</div>
              </div>
            </div>
            {modalData?.status === "Selesai" && (
              <>
                <div className="modalPeserta-line" />
                <div className="row">
                  <div className="modalPeserta-boxCount">
                    <div className="modalPeserta-title">Hadir</div>
                    <div className="modalPeserta-count">{modalData?.totalResult}</div>
                  </div>
                  <div className="modalPeserta-boxCount">
                    <div className="modalPeserta-titleRed">Tidak Hadir</div>
                    <div className="modalPeserta-countRed">{modalData?.totalParticipant - modalData.totalResult}</div>
                  </div>
                  <div className="modalPeserta-boxCount" >
                    <div className="modalPeserta-title">Total Peserta</div>
                    <div className="modalPeserta-count">{modalData?.totalParticipant}</div>
                  </div>
                </div>
              </>
            )}
            {typeEvent === "MCU" && (
              <>
                <div className="modalPeserta-line" />
                <div className="row">
                  <div className="modalPeserta-boxCount">
                    <div className="modalPeserta-title">Sehat</div>
                    <div className="modalPeserta-count">{modalData?.countResume && modalData.countResume["Sehat"]}</div>
                  </div>
                  <div className="modalPeserta-boxCount">
                    <div className="modalPeserta-titleRed">Tidak Sehat</div>
                    <div className="modalPeserta-countRed">{modalData?.countResume && modalData.countResume["Tidak Sehat"]}</div>
                  </div>
                  <div className="modalPeserta-boxCount" >
                    <div className="modalPeserta-title">Sehat Dengan Catatan</div>
                    <div className="modalPeserta-count">{modalData?.countResume && modalData.countResume["Sehat Dengan Catatan"]}</div>
                  </div>
                </div>
              </>
            )}
            {typeEvent === "SWAB" && (
              <>
                <div className="modalPeserta-line" />
                <div className="row">
                  <div className="modalPeserta-boxCount">
                    <div className="modalPeserta-title">Positif</div>
                    <div className="modalPeserta-count">{modalData?.countResume["Positif"]}</div>
                  </div>
                  <div className="modalPeserta-boxCount">
                    <div className="modalPeserta-titleRed">Negatif</div>
                    <div className="modalPeserta-countRed">{modalData?.countResume["Negatif"]}</div>
                  </div>
                  <div className="modalPeserta-boxCount"></div>
                </div>
              </>
            )}
            {modalData?.status === "Selesai" && (
              <div style={{ marginTop: "20px" }}>
                <button className="button-custom" onClick={changeModal}>Lihat Detail Resume</button>
              </div>
            )}
            {(modalData?.status === "Menunggu Konfirmasi" || modalData?.status === "Sedang Berlangsung") && (
              <div className="modalPeserta-table" style={{ width: "94%", marginLeft: "4%", marginBottom: "5%", minHeight: "300px" }}>
                <table style={{ width: "99%" }}>
                  <thead className="tablehead">
                    <tr>
                      <th className="columnnama">
                        <div
                          className="columntitle"
                        >
                          <div>NAMA</div>
                        </div>
                      </th>
                      <th className="columnnama">
                        <div
                          className="columntitle"
                        >
                          <div>DIVISI</div>
                        </div>
                      </th>
                      <th className="columnnama">
                        <div
                          className="columntitle"
                        >
                          <div>JABATAN</div>
                        </div>
                      </th>
                      <th className="columnnama">
                        <div
                          className="columntitle"
                        >
                          <div>NO HP</div>
                        </div>
                      </th>
                      <th className="columnnama">
                        <div
                          className="columntitle"
                        >
                          <div>STATUS</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataRender && dataRender.map((el) => {
                      return (
                        <tr className="tablebody" key={el._id}>
                          <th className="columnbodynama">
                            {el.name.length > 15
                              ? el.name.slice(0, 15) + " ..."
                              : el.name}
                          </th>
                          <th className="columnbodynama">{el.department}</th>
                          <th className="columnbodynama">{el.position}</th>
                          <th className="columnbodynama">{el.phoneNumber[0] === "+" ? `0${el.phoneNumber.slice(3)}` : el.phoneNumber}</th>
                          <th className="columnbodynama" style={{ color: el.statusPeriksa ? "green" : "", cursor: el.statusPeriksa ? "pointer" : "", }}
                            onClick={() => el.statusPeriksa ? openPdf(el.file) : ""}>{el.statusPeriksa ? "Selesai" : "Belum Periksa"}</th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div style={{ width: "30%", borderLeft: "solid", borderLeftWidth: "1px", borderLeftColor: "#E5E5E5" }}>
            <div style={{ padding: "30px" }}>
              <div className="row jbetween" style={{ paddingBottom: "15px" }}>
                <div>
                  <div style={{ fontSize: "11px", color: "#707C8B" }}>METODE PEMBAYARAN</div>
                  <div style={{ fontSize: "15px", color: "#707C8B", marginTop: "10px" }}>{modalData?.paymentMethod === "UMUM" ? "Transfer Bank" : modalData?.paymentMethod}</div>
                </div>
              </div>
              <div style={{ height: "1px", width: "100%", backgroundColor: "#1f2020" }}></div>
              <div className="row jbetween" style={{ paddingTop: "15px" }}>
                <div style={{ fontSize: "14px", color: "#1f2020", paddingBottom: "15px", fontWeight: "bold", maxWidth: "50%" }}>{modalData?.name}</div>
                <div style={{ fontSize: "14px", color: "#707C8B", paddingBottom: "15px" }}>{`Rp ${(modalData?.tagihan ? modalData?.tagihan.servicePrice : modalData?.services?.price)?.toLocaleString("id-ID")} x ${modalData?.totalParticipant}`}</div>
              </div>
              <div style={{ height: "1px", width: "100%", backgroundColor: "#1f2020" }}></div>
              <div className="row jbetween" style={{ paddingTop: "25px" }}>
                <div style={{ fontSize: "14px", color: "#707C8B", paddingBottom: "10px" }}>Subtotal</div>
                <div style={{ fontSize: "14px", color: "#707C8B", paddingBottom: "10px" }}>Rp {((modalData?.tagihan ? modalData?.tagihan.servicePrice : modalData?.services?.price) * modalData?.totalParticipant).toLocaleString("id-ID")}</div>
              </div>
              <div style={{ height: "1px", width: "100%", backgroundColor: "#E5E5E5" }}></div>
              <div className="row jbetween" style={{ paddingTop: "25px" }}>
                <div style={{ fontSize: "14px", color: "#707C8B", paddingBottom: "10px" }}>Biaya Admin</div>
                <div style={{ fontSize: "14px", color: "#707C8B", paddingBottom: "10px" }}>Rp {(modalData?.tagihan?.adminPrice || "0")?.toLocaleString("id-ID")}</div>
              </div>
              <div style={{ height: "1px", width: "100%", backgroundColor: "#E5E5E5" }}></div>
              <div className="row jbetween" style={{ paddingTop: "25px" }}>
                <div style={{ fontSize: "14px", color: "#707C8B", paddingBottom: "10px" }}>Diskon</div>
                <div style={{ fontSize: "14px", color: "#707C8B", paddingBottom: "10px" }}>{modalData?.tagihan?.discount > 0 ? "-" : ""} Rp {(modalData?.tagihan?.discount || "0")?.toLocaleString("id-ID")}</div>
              </div>
              <div style={{ height: "1px", width: "100%", backgroundColor: "#E5E5E5" }}></div>

              <div className="row jbetween" style={{ paddingTop: "25px" }}>
                <div style={{ fontSize: "12px", color: modalData?.statusBayar === "Sudah Dibayar" ? "green" : "#FF6760", paddingTop: "10px", fontStyle: "italic" }}>{modalData?.statusBayar === "Sudah Dibayar" ? "Pembayaran Selesai" : "Menunggu Pembayaran"}</div>
                <div style={{ textAlign: "end" }}>
                  <div style={{ fontSize: "10px", color: "#707C8B", paddingBottom: "10px" }}>TOTAL TAGIHAN</div>
                  <div style={{ fontSize: "14px", color: "#707C8B", paddingBottom: "10px", fontWeight: "bold" }}>Rp {(modalData?.tagihan ? modalData?.tagihan.totalPrice : modalData?.services?.price * modalData?.totalParticipant)?.toLocaleString("id-ID")}</div>
                </div>
              </div>
              <div className="mt20" >
                {
                  modalData?.buktiTransfer && modalData.buktiTransfer.fileUrl &&
                  <div className="row jbetween" style={{marginBottom: "15px"}}>
                    <div className="row">
                      <IcPdf />
                      <div style={{ marginLeft: "15px", fontSize: "14px", color: "#707C8B", marginTop: "2px" }}>Bukti Transfer</div>
                    </div>
                    <div className="row jbetween" style={{ width: "40px", marginTop: "2px" }}>
                      <div style={{ cursor: "pointer" }} onClick={() => {
                        setModalPdfFile(modalData.buktiTransfer.fileUrl)
                        setModalPdf(true)
                        setModalPdfType('image')
                        setModalPdfTitle('Bukti Transfer')
                      }}>
                        <IcEye />
                      </div>
                      <div onClick={() => handleDownload(modalData.buktiTransfer.fileUrl)} style={{ cursor: "pointer" }}>
                        <IcUnduh />
                      </div>
                    </div>
                  </div>
                }
                <div>
                  <label
                    className="buttonUpload"
                    htmlFor="document"
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    {loading ? (
                      <ScaleLoader
                        color="white"
                        css={override}
                        height="10px"
                        width="2px"
                      />
                    ) : (
                      "Upload Bukti transfer"
                    )}
                  </label>
                  <input
                    id="document"
                    type="file"
                    onChange={(e) => handleUpload(e)}
                    accept="image/png, image/gif, image/jpeg"
                    className="hidden"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {modalPdf && (
          <ModalPDFViewer title={modalPdfTitle} modal={setModalPdf} url={modalPdfFile} type={modalPdfType} />
        )}
      </div>
    </div>
  );
}
