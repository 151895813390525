import React from "react";

const Dompet = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1364 18.0908H17.6365C15.4309 18.0908 13.6365 16.2965 13.6365 14.0909C13.6365 11.8854 15.4309 10.091 17.6365 10.091H21.1364C21.4128 10.091 21.6364 9.86738 21.6364 9.591V8.09103C21.6364 7.04255 20.8232 6.18986 19.7956 6.10717L16.9241 1.09163C16.658 0.627762 16.2283 0.29622 15.7141 0.158551C15.2024 0.0218184 14.6673 0.0936297 14.2093 0.360203L4.36556 6.09105H2.6367C1.5337 6.09105 0.636719 6.98799 0.636719 8.09103V20.0908C0.636719 21.1938 1.53366 22.0908 2.6367 22.0908H19.6364C20.7394 22.0908 21.6364 21.1939 21.6364 20.0908V18.5909C21.6364 18.3145 21.4128 18.0908 21.1364 18.0908ZM17.5442 4.18814L18.6337 6.09105H14.2757L17.5442 4.18814ZM6.35303 6.09105L14.7127 1.22442C14.9388 1.0921 15.2029 1.05694 15.4554 1.12435C15.7107 1.19269 15.9236 1.35773 16.056 1.58868L16.057 1.59051L8.32695 6.09105H6.35303Z"
        fill="#8094AE"
      />
      <path
        d="M21.1366 11.0911H17.6367C15.9824 11.0911 14.6367 12.4367 14.6367 14.091C14.6367 15.7453 15.9824 17.0909 17.6367 17.0909H21.1366C21.9638 17.0909 22.6366 16.4181 22.6366 15.591V12.591C22.6366 11.7639 21.9638 11.0911 21.1366 11.0911ZM17.6367 15.091C17.0854 15.091 16.6367 14.6422 16.6367 14.091C16.6367 13.5398 17.0854 13.091 17.6367 13.091C18.1879 13.091 18.6366 13.5398 18.6366 14.091C18.6367 14.6422 18.188 15.091 17.6367 15.091Z"
        fill="#8094AE"
      />
    </svg>
  );
};
export default Dompet;
