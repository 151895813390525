import React, { useState, useEffect } from "react";
import "../../styles/karyawan.css";
import "../../styles/table.css";

import Search from "../../components/Search";
import Filter from "../../components/Filter";
import ModalAdd from "../../components/ModalAdd";

import IcPolygonUp from "../../asset/svg/polygonUp";
import IcPolygonDown from "../../asset/svg/polygonDown";
import IcEdit from "../../asset/svg/edit";
import IcTrash from "../../asset/svg/trash";
import VerifiedLogo from "../../asset/svg/verifiedLogo"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees } from "../../store/actions";
import {
  deleteEmployee,
} from "../../store/actions/employeeAction";
import ModalConfirmation from "../../components/ModalConfirmation";

function EmployeeList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { employees, divisions, insurances } = useSelector((state) => state.employeeState);

  const [data, setData] = useState([]); //all data
  const [dataSearch, setDataSearch] = useState([]); //all search by name data
  const [dataRender, setDataRender] = useState([]); // data per page

  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const itemPerPage = 10;
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [arrayPage, setArrrayPage] = useState([]);

  const [searchName, setSearchName] = useState("");

  const [divisiModal, showDivisiModal] = useState(false);
  const [divisi, setDivisi] = useState([]);
  const [selectedDivisi, setSelectedDivisi] = useState("");

  const [pembayaranModal, showPembayaranModal] = useState(false);
  const [pembayaran, setPembayaran] = useState([]);
  const [selectedPembayaran, setSelectedPembayaran] = useState("");

  const [modalAddEmployee, setModalAddEmployee] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    dispatch(getEmployees());
    setDivisi(["Semua Divisi"]);
    setPembayaran(["Semua Pembayaran"]);
    // eslint-disable-next-line
  }, []); // set all data here

  useEffect(() => {
    setData(employees);
    const allDivisi = ["Semua Divisi"].concat(divisions)
    const allPembayaran = ["Semua Pembayaran"].concat(insurances)
    setDivisi(allDivisi);
    setPembayaran(allPembayaran);
    // eslint-disable-next-line
  }, [employees, divisions, insurances]);

  useEffect(() => {
    setSelectedDivisi(divisi[0]);
    setSelectedPembayaran(pembayaran[0]);
    // eslint-disable-next-line
  }, [divisi, pembayaran]);

  //sort handle
  useEffect(() => {
    if (searchName === "") {
      if (sortType === "ASC") {
        setData([...data].sort((a, b) => a[sortBy].localeCompare(b[sortBy])));
      } else if (sortType === "DESC") {
        setData([...data].sort((a, b) => b[sortBy].localeCompare(a[sortBy])));
      }
    } else {
      if (sortType === "ASC") {
        setDataSearch(
          [...dataSearch].sort((a, b) => a[sortBy].localeCompare(b[sortBy]))
        );
      } else if (sortType === "DESC") {
        setDataSearch(
          [...dataSearch].sort((a, b) => b[sortBy].localeCompare(a[sortBy]))
        );
      }
    }
    // eslint-disable-next-line
  }, [sortType, sortBy]);

  const sortHandle = async (field) => {
    if (sortBy !== field) {
      setSortBy(field);
    }
    if (sortBy === field) {
      sortType === "ASC" ? await setSortType("DESC") : await setSortType("ASC");
    } else {
      await setSortType("ASC");
    }
  };

  //pagination
  useEffect(() => {
    if (searchName === "") {
      setTotalPage(Math.ceil(data.length / itemPerPage));
      setDataRender(
        data.slice(
          (activePage - 1) * itemPerPage,
          (activePage - 1) * itemPerPage + itemPerPage
        )
      );
    } else {
      setTotalPage(Math.ceil(dataSearch.length / itemPerPage));
      setDataRender(
        dataSearch.slice(
          (activePage - 1) * itemPerPage,
          (activePage - 1) * itemPerPage + itemPerPage
        )
      );
    }
    // eslint-disable-next-line
  }, [data, dataSearch, activePage]);

  useEffect(() => {
    const array = [];
    if (activePage > 1) array.push(activePage - 1);
    array.push(activePage);
    if (totalPage > activePage) array.push(activePage + 1);
    if (array.length < 3 && totalPage > activePage + 1)
      array.push(activePage + 2);
    if (array.length < 3 && activePage > 2) array.unshift(activePage - 2);
    setArrrayPage(array);
    // eslint-disable-next-line
  }, [totalPage, activePage]);

  //search by name
  useEffect(() => {
    if (searchName === "") {
      setDataSearch(data);
    } else if (data.length) {
      let regexp = new RegExp(searchName, "gi");
      const newData = data.filter((el) => {
        return el.name.match(regexp) !== null;
      });
      setDataSearch(newData);
      setActivePage(1);
    }
    // eslint-disable-next-line
  }, [searchName]);

  //filter
  const selectFilter = (filtername, filter) => {
    switch (filtername) {
      case "divisi":
        showDivisiModal(false);
        setSelectedDivisi(filter);
        break;

      case "pembayaran":
        showPembayaranModal(false);
        setSelectedPembayaran(filter);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (employees.length > 0){
      if (
        selectedDivisi !== "Semua Divisi" ||
        selectedPembayaran !== "Semua Pembayaran"
      ) {
        setSearchName("");
        let newData = searchName !== "" ? [...dataSearch] : [...employees];
        if (selectedDivisi !== "Semua Divisi") {
          newData = employees.filter((el) => {
            return el.department?.toLowerCase() === selectedDivisi?.toLowerCase();
          });
        } else {
          newData = [...employees];
        }
        if (selectedPembayaran !== "Semua Pembayaran") {
          newData = newData.filter((el) => {
            return el.insuranceStatus?.toLowerCase() === selectedPembayaran?.toLowerCase();
          });
        }
        setActivePage(1);
        searchName === "" ? setData(newData) : setDataSearch(newData);
      } else {
        setSearchName("");
        setActivePage(1);
        setData(employees);
      }
    }
    // eslint-disable-next-line
  }, [selectedDivisi, selectedPembayaran]);

  const deleteHandle = (id) => {
    dispatch(deleteEmployee(id));
  };

  const closeModal = () => {
    showDivisiModal(false)
    showPembayaranModal(false)
    setModalAddEmployee(false)
  }

  return (
    <div className="bgcontent">
      <div className="header">Daftar Karyawan</div>
      <div className="karyawan">
        <div className="filter">
          <div className="row">
            <Search
              searchName={searchName}
              setSearchName={setSearchName}
              placeholder={"Pencarian"}
            />
            <Filter
              filterName={"divisi"}
              modal={divisiModal}
              showModal={() => {
                showPembayaranModal(false);
                setModalAddEmployee(false)
                divisiModal ? showDivisiModal(false) : showDivisiModal(true);
              }}
              item={divisi}
              selectedItem={selectedDivisi}
              selectFilter={selectFilter}
              closeModal={closeModal}
            />
            <Filter
              filterName={"pembayaran"}
              modal={pembayaranModal}
              showModal={() => {
                showDivisiModal(false);
                setModalAddEmployee(false)
                pembayaranModal
                  ? showPembayaranModal(false)
                  : showPembayaranModal(true);
              }}
              item={pembayaran}
              selectedItem={selectedPembayaran}
              selectFilter={selectFilter}
              closeModal={closeModal}
            />

            <div
              className="reset"
              onClick={() => {
                setSearchName("");
                setSelectedDivisi("Semua Divisi");
                setSelectedPembayaran("Semua Pembayaran");
              }}
            >
              RESET FILTER
            </div>
          </div>
          <ModalAdd
            modal={modalAddEmployee}
            selectedItem={"+ Tambah Karyawan"}
            tambahManual={() => navigate("/karyawan/add")}
            showModal={() => {
              showDivisiModal(false);
              showPembayaranModal(false);
              modalAddEmployee
                ? setModalAddEmployee(false)
                : setModalAddEmployee(true);
            }}
            closeModal={closeModal}
          />
        </div>
        <table>
          <thead className="tablehead">
            <tr>
              <th className="columnnama">
                <div className="columntitle" onClick={() => sortHandle("name")}>
                  <div style={{ paddingLeft: "35px" }}>NAMA</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "name" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "name" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div className="columntitle" onClick={() => sortHandle("NIP")}>
                  <div>NIP</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "NIP" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "NIP" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div
                  className="columntitle"
                  onClick={() => sortHandle("department")}
                >
                  <div>DIVISI</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "department" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "department" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div
                  className="columntitle"
                  onClick={() => sortHandle("position")}
                >
                  <div>JABATAN</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "position" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "position" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div
                  className="columntitle"
                  onClick={() => sortHandle("insuranceStatus")}
                >
                  <div>PEMBAYARAN</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "insuranceStatus" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "insuranceStatus" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div
                  className="columntitle"
                  onClick={() => sortHandle("phoneNumber")}
                >
                  <div>NO HP</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "phoneNumber" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "phoneNumber" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="columnaksi">AKSI</th>
            </tr>
          </thead>
          <tbody>
            {dataRender?.map((el) => {
              return (
                <tr className="tablebody" key={el._id}>
                  <th className="columnbodynama">
                    <div className="row">
                      <div style={{ width: "35px" }}>
                        {
                          el.isDownload && <div><VerifiedLogo /></div>
                        }
                      </div>
                      <div>{el.name}</div>
                    </div>
                  </th>
                  <th className="columnbody">{el.NIP}</th>
                  <th className="columnbody">{el.department}</th>
                  <th className="columnbody">{el.position}</th>
                  <th className="columnbody">{el.insuranceStatus[0].toUpperCase() + el.insuranceStatus.slice(1).toLowerCase()}</th>
                  <th className="columnbody">{el.phoneNumber[0] === "+" ? `0${el.phoneNumber.slice(3)}` : el.phoneNumber}</th>
                  <th className="columnbodyaksi">
                    <div className="action">
                      <div
                        className="iconAction"
                        onClick={() =>
                          navigate("/karyawan/edit", {
                            replace: true,
                            state: { dataEmployee: el },
                          })
                        }
                      >
                        <IcEdit />
                      </div>
                      <div
                        className="iconAction"
                        onClick={() => {
                          setDeleteId(el._id);
                          setModalDelete(true);
                        }}
                      >
                        <IcTrash />
                      </div>
                    </div>
                  </th>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="tablefooter">
            <tr className="row">
                <td>
                  <button
                    className="pagination"
                    onClick={() => setActivePage(activePage - 1)}
                    disabled={activePage === 1}
                  >
                    {"<"}
                  </button>
                </td>
                {totalPage > 0 &&
                  arrayPage.map((el, idx) => {
                    return (
                      <td
                        key={idx}
                        className="pagination"
                        style={{
                          backgroundColor:
                            activePage === el ? "#558EFF" : "transparent",
                          color: activePage === el ? "#FFFFFF" : "#25282B",
                        }}
                        onClick={() => setActivePage(el)}
                      >
                        {el}
                      </td>
                    );
                  })}
                <td>
                  <button
                    className="pagination"
                    onClick={() => setActivePage(activePage + 1)}
                    disabled={activePage >= totalPage}
                  >
                    {">"}
                  </button>
                </td>
              <td style={{ marginLeft: "70%", fontSize: "14px", color: "#526484", textAlign: "end" }}>Total Karyawan : {" " + data.length + " Orang"}</td>
            </tr>
          </tfoot>
        </table>
        {modalDelete && (
          <ModalConfirmation
            action={() => deleteHandle(deleteId)}
            modal={setModalDelete}
            title={"Yakin ingin menghapus data karyawan ini ?"}
          />
        )}
      </div>
    </div>
  );
}

export default EmployeeList;
