import React from "react";

const TrendingUp = ({ active }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3337 4.5L9.00033 10.8333L5.66699 7.5L0.666992 12.5"
        stroke="#D64949"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.333 4.5H15.333V8.5"
        stroke="#D64949"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default TrendingUp;
