import React, { useEffect, useState } from "react";
import "../styles/app.css";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";

import IcDropdown from "../asset/svg/dropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setModalClicked } from "../store/actions";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: lime;
`;

export default function SearchInput({
  addFeature,
  data,
  setSelectedData,
  value = "",
  title,
  placeholder,
  loading = false,
  modal,
  setModal
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [textInput, setTextInput] = useState(value);
  const [dataRender, setDataRender] = useState(data);
  const { modalClicked, closeAllModal } = useSelector(state => state.messageState)

  useEffect(() => {
    setDataRender(data)
    setTextInput("");
  },[data])

  const selectHandle = (item) => {
    setTextInput(item.name);
    setSelectedData(item);
    setModal();
  };

  const searchHandle = (text) => {
    setTextInput(text);
    let regexp = new RegExp(text, "gi");
    const newData = data.filter((el) => {
      return el.name.match(regexp) !== null;
    });
    setDataRender(newData);
  };

  useEffect(() => {
    if(modalClicked){
      dispatch(setModalClicked(false))
    } 
    // eslint-disable-next-line
  },[modalClicked])

  useEffect(() => {
    if(closeAllModal){
      setModal(false)
    }
    // eslint-disable-next-line
  },[closeAllModal])

  const areaClicked = () => {
    dispatch(setModalClicked(true))
  }

  return (
    <div className="searchInput">
      <fieldset className="inputArea">
        <legend className="fieldsetText">
          {title || "Pilih Faskes"}
          <div style={{ color: "red", paddingLeft: "5px" }}>{"*"}</div>
        </legend>
        <div className="row" onClick={areaClicked}>
          <input
            className="textInput"
            type={"text"}
            placeholder={placeholder || "Pilih faskes yang tersedia"}
            onFocus={() => setModal()}
            onChange={(e) => searchHandle(e.target.value)}
            value={textInput}
          />

          {loading && data.length === 0 ? (
            <div style={{ width: "50px" }}>
              <ScaleLoader
                color="black"
                css={override}
                height="13px"
                width="2px"
              />
            </div>
          ) : (
            <div
              onClick={() => setModal()}
              style={{
                cursor: "pointer",
                transform: modal ? "rotate(-90deg)" : null,
              }}
            >
              <IcDropdown />
            </div>
          )}
        </div>
      </fieldset>
      {modal && (
        <ul className="modal" onClick={areaClicked}>
          {dataRender.map((el, idx) => {
            return (
              <React.Fragment key={idx}>
                <li className="modal-option" onClick={() => selectHandle(el)}>
                  {el.name}
                </li>
              </React.Fragment>
            );
          })}
          {
            addFeature && data.length === 0 && 
            <li className="modal-option" onClick={() => navigate("/klinik/add")}>
              {`+ Tambah faskes`}
            </li>
          }
          {
            !addFeature && data.length === 0 && 
            <li className="modal-option">
              {"Tidak ada layanan tersedia, hubungi pihak klinik untuk membuat layanan"}
            </li>
          }
        </ul>
      )}
    </div>
  );
}
