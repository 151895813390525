import React from "react";

const IcClose = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.60527 0.000196319L0 3.60547L18.3943 21.9998L21.9996 18.3945L3.60527 0.000196319Z"
        fill="#B6B6B6"
      />
      <path
        d="M18.3943 -0.00072551L0 18.3936L3.60527 21.9988L21.9996 3.60455L18.3943 -0.00072551Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};
export default IcClose;
