import React, { useEffect, useState } from "react";
import "../../styles/addklinik.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";

import SearchInput from "../../components/SearchInput";

import {
  createClinic,
  getAllClinicWeb,
  getServices,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: lime;
`;

function AddKlinik() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, errorMessage, successMessage } = useSelector((state) => state.messageState);
  const { dataKlinikWeb, dataServices } = useSelector((state) => state.clinicState);

  const [payload, setPayload] = useState({});
  const [dataKlinik, setDataKlinik] = useState([]);
  const [modalKlinik, setModalKlinik] = useState(false)

  useEffect(() => {
    dispatch(getAllClinicWeb());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataKlinikWeb.length > 0) {
      setDataKlinik(dataKlinikWeb);
    }
    // eslint-disable-next-line
  }, [dataKlinikWeb]);

  useEffect(() => {
    if (dataServices.length > 0) {
      setPayload({
        ...payload,
        services: dataServices,
      });
    }
    // eslint-disable-next-line
  }, [dataServices]);

  const addHandle = () => {
    dispatch(createClinic(payload));
  };

  useEffect(() => {
    if (successMessage) {
      navigate("/klinik");
    }
    // eslint-disable-next-line
  }, [errorMessage, successMessage]);

  return (
    <div className="bgcontent">
      <div className="header">Tambah Klinik</div>
      <div className="addklinik">
        <div className="row">
          <div className="forminput">
            <div style={{ marginBottom: 20, height: 60 }}>
              <SearchInput
                modal={modalKlinik}
                setModal={(payload) => {
                  payload !== undefined ? setModalKlinik(payload) :
                  modalKlinik ? setModalKlinik(false) : setModalKlinik(true)
                }}
                data={dataKlinik}
                loading={loading}
                value={payload.name}
                setSelectedData={(item) => {
                  setPayload({
                    clinicIdWeb: item.id,
                    name: item.name,
                    phoneNumber: item.phoneNumber,
                    address: item.address,
                    doctors: item.Doctors,
                    location: item.location,
                  });
                  dispatch(getServices(item.id));
                }}
              />
            </div>
            {payload.name && (
              <>
                <fieldset className="boxArea">
                  <legend className="fieldsetText">Fasilitas / Layanan</legend>

                  {loading ? (
                    <div style={{ padding: "10px" }}>
                      <ScaleLoader
                        color="black"
                        loading={loading}
                        css={override}
                        height="15px"
                        width="2px"
                      />
                    </div>
                  ) : (
                    <>
                      {dataServices.length ? (
                        <ul style={{ maxHeight: "200px", overflow: "auto" }}>
                          {dataServices.map((service) => {
                            return (
                              <li
                                key={service._id}
                                style={{
                                  lineHeight: "30px",
                                  marginLeft: "-15px",
                                }}
                              >
                                {service.name}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <div style={{ lineHeight: "40px" }}>
                          Tidak ada layanan tersedia
                        </div>
                      )}
                    </>
                  )}
                </fieldset>
                <fieldset className="boxArea">
                  <legend className="fieldsetText">Nomor Telpon</legend>
                  <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                    {payload.phoneNumber}
                  </div>
                </fieldset>
                <fieldset className="boxArea">
                  <legend className="fieldsetText">Alamat Faskes</legend>
                  <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                    {payload.address}
                  </div>
                </fieldset>
                <div className="buttongroup row">
                  <div className="redbutton" onClick={() => navigate(-2)}>
                    Batalkan
                  </div>
                  {/* <div className="button" onClick={() => addHandle()}>
                    Simpan
                  </div> */}
                  <div className="button" onClick={() => addHandle()}>
                    {loading ? (
                      <ScaleLoader
                        color="white"
                        css={override}
                        height="13px"
                        width="2px"
                      />
                    ) : (
                      <div>Simpan</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddKlinik;
