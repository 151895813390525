const initialState = {
    dataKlinik: [],
    dataKlinikById: {},
    klinikByName: {},
    dataKlinikWeb: [],
    dataServices: []
}

export default function reducer(state = initialState, action){
    switch(action.type){
        case "SET_DATAKLINIK":
            return {...state, dataKlinik: action.payload}
        case "SET_DATAKLINIK_BYID":
            return {...state, dataKlinikById: action.payload}
        case "SET_DATAKLINIK_BYNAME":
            return {...state, klinikByName: action.payload}
        case "SET_DATAKLINIKWEB":
            return {...state, dataKlinikWeb: action.payload}
        case "SET_DATASERVICES":
            return {...state, dataServices: action.payload}
        default: 
            return state
    }
}