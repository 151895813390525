import React, { useEffect, useRef, useState } from "react";

import RoundedSearch from "../../components/RoundedSearch";
import RoundedFilter from "../../components/RoundedFilter";

import IcPolygonUp from "../../asset/svg/polygonUp";
import IcPolygonDown from "../../asset/svg/polygonDown";
import IcTrash from "../../asset/svg/trash";
import IcClose from "../../asset/svg/ic_close";
import IcDirection from "../../asset/svg/ic_direction";

import { useDispatch, useSelector } from "react-redux";
import { getEmployeesVerified } from "../../store/actions";

export default function ModalPeserta({
  setModalPeserta,
  setSelectedEmployee,
  selectedEmployee = [],
  eventName,
  klinikName,
  eventDate,
  eventTime
}) {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { verifiedEmployee } = useSelector(
    (state) => state.employeeState
  );
  const [filterDepartment, setFilterDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("Semua");
  const [dataEmployees, setDataEmployees] = useState([]);
  const [checkedEmployee, setCheckedEmployee] = useState(selectedEmployee);
  const [searchName, setSearchName] = useState("");
  const [showScroll, setShowScroll] = useState(false);

  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const [sortByChecked, setSortByChecked] = useState("");
  const [sortTypeChecked, setSortTypeChecked] = useState("");

  useEffect(() => {
    if (!verifiedEmployee.length) {
      dispatch(getEmployeesVerified());
    } else {
      setDataEmployees(verifiedEmployee);
      let department = [];
      verifiedEmployee.forEach((el) => {
        if (!department.includes(el.department)) {
          department.push(el.department);
        }
      });
      setFilterDepartment(department);
    }
    // eslint-disable-next-line
  }, [verifiedEmployee]);

  useEffect(() => {
    if(verifiedEmployee && verifiedEmployee.length){
      let newData = []
      verifiedEmployee.forEach(el => {
        let flag = true
        let regexp = new RegExp(searchName, "gi");
        if(searchName !== "" && el.name.match(regexp) === null){
          flag = false
        }
        else if (selectedDepartment !== "Semua" && (el.department !== selectedDepartment)){
          flag = false
        }
        if(flag){
          newData.push(el)
        }
      })
      if (sortType === "ASC") {
        newData.sort((a, b) => a[sortBy].localeCompare(b[sortBy]))
      } else if (sortType === "DESC") {
        newData.sort((a, b) => b[sortBy].localeCompare(a[sortBy]))
      }
      setDataEmployees(newData)
    }
    // eslint-disable-next-line
  }, [searchName, selectedDepartment, sortType, sortBy]);

  useEffect(() => {
    if(checkedEmployee && checkedEmployee.length){
      let newData = [...checkedEmployee]
      if (sortTypeChecked === "ASC") {
        newData.sort((a, b) => a[sortByChecked].localeCompare(b[sortByChecked]))
      } else if (sortTypeChecked === "DESC") {
        newData.sort((a, b) => b[sortByChecked].localeCompare(a[sortByChecked]))
      }
      setCheckedEmployee(newData)
    }
    // eslint-disable-next-line
  }, [sortTypeChecked, sortByChecked]);

  useEffect(() => {
    if (filterDepartment.length > 0) {
      let box = document.querySelector(".modalPeserta-filter");
      let width = box.offsetWidth;
      if (width >= 510) {
        setShowScroll(true);
      }
    }
  }, [filterDepartment]);

  const selectHandle = (e, item) => {
    if (e.target.checked) {
      setCheckedEmployee(checkedEmployee.concat([item]));
    } else {
      const newData = checkedEmployee.filter((el) => el._id !== item._id);
      setCheckedEmployee(newData);
    }
  };

  const selectAllHandle = (e) => {
    if (e.target.checked) {
      let newData = [];
      dataEmployees.forEach((el) => {
        const isHasIncluded = checkedEmployee.findIndex(
          (x) => x._id === el._id
        );
        if (isHasIncluded === -1) {
          newData.push(el);
        }
      });
      setCheckedEmployee(checkedEmployee.concat(newData));
    } else {
      const newData = [];
      checkedEmployee.forEach((el) => {
        const isDeleted = dataEmployees.findIndex((x) => x._id === el._id);
        if (isDeleted === -1) {
          newData.push(el);
        }
      });
      setCheckedEmployee(newData);
    }
  };

  const checkedHandle = (item) => {
    const isChecked = checkedEmployee.findIndex((x) => x._id === item._id);
    if (isChecked > -1) {
      return true;
    } else {
      return false;
    }
  };

  const checkAllChecked = () => {
    if (dataEmployees.length === 0) {
      return false;
    }
    let isAllChecked = true;

    dataEmployees.forEach((el) => {
      const isIncluded = checkedEmployee.findIndex((x) => x._id === el._id);
      if (isIncluded === -1) {
        isAllChecked = false;
      }
    });
    return isAllChecked;
  };

  const deleteHandle = (id) => {
    const newData = checkedEmployee.filter((el) => el._id !== id);
    setCheckedEmployee(newData);
  };

  const saveHandle = () => {
    const newData = checkedEmployee.map((el) => {
      delete el.createdAt;
      delete el.updatedAt;
      delete el.__v;
      return el;
    });
    setSelectedEmployee(newData);
    setModalPeserta(false);
  };

  const scroll = (scrollOffset) => {
    if (scrollOffset > 0) {
      setTimeout(() => {
        ref.current.scrollLeft += 10;
        scroll(scrollOffset - 10);
      }, 20);
    } else if (scrollOffset < 0) {
      setTimeout(() => {
        ref.current.scrollLeft -= 10;
        scroll(scrollOffset + 10);
      }, 20);
    }
  };

  const sortHandle = async (field) => {
    if (sortBy !== field) {
      setSortBy(field);
    }
    if (sortBy === field) {
      sortType === "ASC" ? setSortType("DESC") : setSortType("ASC");
    } else {
      setSortType("ASC");
    }
  };

  const sortCheckedHandle = async (field) => {
    if (sortByChecked !== field) {
      setSortByChecked(field);
    }
    if (sortByChecked === field) {
      sortTypeChecked === "ASC" ? setSortTypeChecked("DESC") : setSortTypeChecked("ASC");
    } else {
      setSortTypeChecked("ASC");
    }
  };

  return (
    <div id="myModal" className="modalPeserta">
      <div className="modalPeserta-content">
        <div className="modalPeserta-header">
          <div style={{ width: "95%", textAlign: "center" }}>Pilih Peserta</div>
          <div
            className="close"
            style={{ cursor: "pointer" }}
            onClick={() => setModalPeserta(false)}
          >
            <IcClose />
          </div>
        </div>
        <div className="modalPeserta-body">
          <div className="row jbetween">
            <div>
              <RoundedSearch
                placeholder={"Pencarian"}
                searchName={searchName}
                setSearchName={setSearchName}
              />

              {filterDepartment.length > 0 && (
                <div className="modalPeserta-filterArea">
                  {showScroll && (
                    <div
                      onClick={() => scroll(-70)}
                      className="buttonFilterLeft"
                    >
                      <IcDirection />
                    </div>
                  )}
                  <div className="modalPeserta-filter" style={{transform: showScroll ? "translateX(-40px)" : ""}}>
                    <div className="filter-body" ref={ref}>
                      <RoundedFilter
                        title={"Semua"}
                        active={selectedDepartment === "Semua" ? true : false}
                        clickHandle={() => setSelectedDepartment("Semua")}
                      />

                      {filterDepartment.map((el, idx) => {
                        return (
                          <RoundedFilter
                            key={idx}
                            title={el}
                            active={selectedDepartment === el ? true : false}
                            clickHandle={() => setSelectedDepartment(el)}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {showScroll && (
                    <div
                      onClick={() => scroll(70)}
                      className="buttonFilterRight"
                    >
                      <IcDirection />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="modalPeserta-card">
              <div style={{ fontSize: "18px", color: "#4A4A4A" }}>
                {eventName}
              </div>
              <div
                style={{
                  fontSize: "13px",
                  color: "#4A4A4A",
                  marginTop: "15px",
                }}
              >
                {klinikName}
              </div>
              <div
                style={{ fontSize: "11px", color: "#4A4A4A", marginTop: "8px" }}
              >
                {eventTime ? `${eventDate} Pukul ${eventTime}` : ""}
              </div>
            </div>
          </div>
          <div className="row jbetween mt20">
            <div className="modalPeserta-table">
              <table style={{ width: "98%" }}>
                <thead className="tablehead" style={{ minWidth: "400px" }}>
                  <tr>
                    <td className="columnno">
                      <input
                        type={"checkbox"}
                        onChange={(e) => selectAllHandle(e)}
                        checked={checkAllChecked()}
                      />
                    </td>
                    <td className="columnnama">
                      <div
                        className="columntitle"
                        onClick={() => sortHandle("name")}
                      >
                        <div>NAMA</div>
                        <div className="sort">
                          <IcPolygonUp
                          active={sortBy === "name" && sortType === "ASC"}
                          />
                          <IcPolygonDown
                          active={sortBy === "name" && sortType === "DESC"}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="columnnama">
                      <div
                        className="columntitle"
                        onClick={() => sortHandle("department")}
                      >
                        <div>DIVISI</div>
                        <div className="sort">
                          <IcPolygonUp
                          active={sortBy === "department" && sortType === "ASC"}
                          />
                          <IcPolygonDown
                          active={sortBy === "department" && sortType === "DESC"}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="columnnama">
                      <div
                        className="columntitle"
                        onClick={() => sortHandle("position")}
                      >
                        <div>JABATAN</div>
                        <div className="sort">
                          <IcPolygonUp
                          active={sortBy === "position" && sortType === "ASC"}
                          />
                          <IcPolygonDown
                          active={sortBy === "position" && sortType === "DESC"}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {dataEmployees.length > 0 &&
                    dataEmployees.map((el) => {
                      return (
                        <tr className="tablebody" key={el._id}>
                          <th className="columnbodyno">
                            <input
                              type={"checkbox"}
                              onChange={(e) => selectHandle(e, el)}
                              checked={checkedHandle(el)}
                            />
                          </th>
                          <th className="columnbodynama">
                            {el.name.length > 15
                              ? el.name.slice(0, 15) + " ..."
                              : el.name}
                          </th>
                          <th className="columnbodynama">{el.department}</th>
                          <th className="columnbodynama">{el.position}</th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="modalPeserta-table">
              <table style={{ width: "98%" }}>
                <thead className="tablehead" style={{ minWidth: "400px" }}>
                  <tr>
                    <th className="columnnama">
                      <div
                        className="columntitle"
                        onClick={() => sortCheckedHandle("name")}
                      >
                        <div>NAMA</div>
                        <div className="sort">
                          <IcPolygonUp
                          active={sortByChecked === "name" && sortTypeChecked === "ASC"}
                          />
                          <IcPolygonDown
                          active={sortByChecked === "name" && sortTypeChecked === "DESC"}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="columnnama">
                      <div
                        className="columntitle"
                        onClick={() => sortCheckedHandle("department")}
                      >
                        <div>DIVISI</div>
                        <div className="sort">
                          <IcPolygonUp
                          active={sortByChecked === "department" && sortTypeChecked === "ASC"}
                          />
                          <IcPolygonDown
                          active={sortByChecked === "department" && sortTypeChecked === "DESC"}
                          />
                        </div>
                      </div>
                    </th>
                    <th className="columnnama">
                      <div
                        className="columntitle"
                        onClick={() => sortCheckedHandle("position")}
                      >
                        <div>JABATAN</div>
                        <div className="sort">
                          <IcPolygonUp
                          active={sortByChecked === "position" && sortTypeChecked === "ASC"}
                          />
                          <IcPolygonDown
                          active={sortByChecked === "position" && sortTypeChecked === "DESC"}
                          />
                        </div>
                      </div>
                    </th>
                    <th
                      className="columnno"
                      style={{ height: "20px", fontSize: "11px" }}
                    >
                      HAPUS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {checkedEmployee.length > 0 &&
                    checkedEmployee.map((el) => {
                      return (
                        <tr className="tablebody" key={el._id}>
                          <th className="columnbodynama">
                            {el.name.length > 15
                              ? el.name.slice(0, 15) + " ..."
                              : el.name}
                          </th>
                          <th className="columnbodynama">{el.department}</th>
                          <th className="columnbodynama">{el.position}</th>
                          <th
                            className="columnbodyno"
                            style={{ width: "6%", cursor: "pointer" }}
                            onClick={() => deleteHandle(el._id)}
                          >
                            <IcTrash />
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="event" style={{ width: "100%" }}>
            <div className="row jbetween">
              <div className="totalPeserta">
                {/* <div className="row">
                  <div style={{ marginRight: "5px", color: "red" }}>*</div>
                  Karyawan yang ditampilkan hanya yang sudah mendownload
                  medqcare
                </div> */}
              </div>
              <div className="totalPeserta">
                <div>Total Peserta :</div>
                <div style={{ marginLeft: "20px" }}>
                  {checkedEmployee.length} orang
                </div>
              </div>
            </div>
            <div className="buttongroup row">
              <div
                className="redbutton"
                onClick={() => {
                  setModalPeserta(false);
                }}
              >
                Batalkan
              </div>
              <div className="button" onClick={() => saveHandle()}>
                Simpan
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
