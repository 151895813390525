import React from "react";

const threedot = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1315 1.83325V3.66659H15.2148V16.4999C15.2148 18.5258 13.574 20.1666 11.5482 20.1666C9.52234 20.1666 7.88151 18.5258 7.88151 16.4999V3.66659H6.96484V1.83325H16.1315ZM12.4648 13.7499C11.9588 13.7499 11.5482 14.1606 11.5482 14.6666C11.5482 15.1726 11.9588 15.5833 12.4648 15.5833C12.9708 15.5833 13.3815 15.1726 13.3815 14.6666C13.3815 14.1606 12.9708 13.7499 12.4648 13.7499ZM10.6315 10.9999C10.1255 10.9999 9.71484 11.4106 9.71484 11.9166C9.71484 12.4226 10.1255 12.8333 10.6315 12.8333C11.1375 12.8333 11.5482 12.4226 11.5482 11.9166C11.5482 11.4106 11.1375 10.9999 10.6315 10.9999ZM13.3815 3.66659H9.71484V7.33325H13.3815V3.66659Z"
        fill="#8094AE"
      />
    </svg>
  );
};
export default threedot;
