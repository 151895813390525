import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../styles/login.css";

import { setIsLogin } from '../../store/actions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";


function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.clear()
    dispatch(setIsLogin(false))
    toast('Logout berhasil')
    navigate('/login')
    // eslint-disable-next-line
  },[])

  return (
    <ToastContainer />
  );
}

export default Logout;
