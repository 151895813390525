import React from "react";

const AntigenPositif = ({ size }) => {
  return (
    <svg
      width={size || "20"}
      height={size || "20"}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2188 2.4375C6.81689 2.4375 2.4375 6.81689 2.4375 12.2188C2.4375 17.6206 6.81689 22 12.2188 22C17.6206 22 22 17.6206 22 12.2188C22 6.81689 17.6206 2.4375 12.2188 2.4375ZM17.4354 12.8708H12.8708V17.4354C12.8708 17.6084 12.8021 17.7742 12.6798 17.8965C12.5576 18.0188 12.3917 18.0875 12.2188 18.0875C12.0458 18.0875 11.8799 18.0188 11.7577 17.8965C11.6354 17.7742 11.5667 17.6084 11.5667 17.4354V12.8708H7.00208C6.82914 12.8708 6.66328 12.8021 6.54099 12.6798C6.4187 12.5576 6.35 12.3917 6.35 12.2188C6.35 12.0458 6.4187 11.8799 6.54099 11.7577C6.66328 11.6354 6.82914 11.5667 7.00208 11.5667H11.5667V7.00208C11.5667 6.82914 11.6354 6.66328 11.7577 6.54099C11.8799 6.4187 12.0458 6.35 12.2188 6.35C12.3917 6.35 12.5576 6.4187 12.6798 6.54099C12.8021 6.66328 12.8708 6.82914 12.8708 7.00208V11.5667H17.4354C17.6084 11.5667 17.7742 11.6354 17.8965 11.7577C18.0188 11.8799 18.0875 12.0458 18.0875 12.2188C18.0875 12.3917 18.0188 12.5576 17.8965 12.6798C17.7742 12.8021 17.6084 12.8708 17.4354 12.8708Z"
        fill="#D22828"
      />
    </svg>
  );
};
export default AntigenPositif;
